<template>
  <body>
    <div id="app" @wheel="checkScroll" oncontextmenu="return false">
      <header style="width: 100%; background-color: #fff; position: fixed; top: 0; left: 0; z-index: 10; background-color: #fff;"
        :style="{height: headerHeight + 'px'}" v-if="!isMobile">  
        <div style="position: absolute; width: 100%;">
          <imsr-top></imsr-top>
          <imsr-menu></imsr-menu>
          <div style="position: absolute; width: 100%; justify-content: center; display: flex;">
            <hr class="imsr-divider" style="width: 100%;"/>
          </div>
        </div>
        
      </header>
      <main ref="mainContainer" class="imsr-main-container"
        :style="{'padding-bottom': !isMobile && contentPaddingBottom + 'px', paddingTop: isMobile && 0}">
        <router-view :key="$route.fullPath"/>
      </main>
      <transition name="footerup" v-if="!isMobile">
        <footer class="imsr-footer" style="min-height: 120px; width: 100%;" v-if="isShowFooter">
          <imsr-footer></imsr-footer>
        </footer>
      </transition>
      <el-dialog
        :visible.sync="signInDialogVisible"
        :close-on-click-modal="false"
        @close="closeSignForm"
        @opened="openedSignForm"
        width="768px"
        center>
        <span slot="title">
          <span style="font-weight: bold; font-size: 24px;" v-if="!isSignInFailed">Welcome</span>
          <span style="font-weight: bold; font-size: 24px;" class="error-color" v-else>{{this.wrongMsg}}</span>
        </span>
        <el-form ref="signInform" :model="signInform" :rules="signInRules" label-position="left" label-width="150px">
          <el-form-item label="EMAIL" prop="username" :class="{'is-error': isSignInFailed}">
            <el-input v-model="signInform.username" placeholder="email@email.com" @keypress.native="resetSignForm"></el-input>
          </el-form-item>
          <el-form-item label="PASSWORD" prop="password" :class="{'is-error': isSignInFailed}">
            <el-input type="password" v-model="signInform.password" autocomplete="off" @keypress.native="resetSignForm" @keypress.native.enter="clickSignIn"></el-input>
          </el-form-item>
        </el-form>
        <el-button style="width: 100%" class="imsr-button" @click="clickSignIn">SIGN IN</el-button>
        <p style="text-align: right; margin-top: 20px;">
          <span class="imsr-text-button" @click="clickPasswordReset">Forgot your password? Click to reset</span>
        </p>
        <div style="text-align:center">
            If you need any support, please contact <a href="mailto:ims_admin@shinwon.com">ims_admin@shinwon.com</a>.
        </div>
      </el-dialog>

      <el-dialog
        :visible.sync="resetPasswordDialogVisible"
        :close-on-click-modal="false"
        @opened="openedResetForm"
        width="768px"
        center>
        <span slot="title">
          <span style="font-weight: bold; font-size: 24px;">FORGOT YOUR INFOMATION?</span>
        </span>
        <div class="reset-form-desc" style="margin-bottom: 33px">
          <div v-if="!isResetPasswordFailed">Account verification is required to reset your password.<br>Please submit your Sub Email which you used when signing up.</div>
          <div v-else class="error-color">Invalid email. Please enter email address format.</div>
          <div style="font-weight: 700;">HELP : <span class="primary-color">ims_admin@sw.co.kr</span></div>
        </div>
        <el-form ref="resetForm" :model="resetForm" :rules="resetFormRules" label-position="left" label-width="150px" @submit.native.prevent>
          <el-form-item label="SUB EMAIL" prop="subEmail" :class="{'is-error': isResetPasswordFailed}">
            <el-input v-model="resetForm.subEmail" placeholder="email@email.com" autocomplete="off" @keypress.native="resetSignForm" @keypress.native.enter="clickResetPassword"></el-input>
          </el-form-item>
        </el-form>
        <el-button style="width: 100%" class="imsr-button" @click="clickResetPassword">SUBMIT</el-button>
      </el-dialog>

      <!-- SUBMIT 성공 시 다이얼로그 -->
      <el-dialog
        :visible.sync="submitDialogVisible"
        :show-close="false"
        width="768px"
        center>
        <span slot="title">
          <div>
            <div style="margin: 85px 0 10px 0;"><img src="@/assets/checkBox.svg"></div>
            <span style="font-weight: bold; font-size: 24px;">Submitted Successfully.</span>
          </div>
        </span>
        <div class="reset-form-desc">
          <div>We will contact you with a new password after account verification asap.</div>
          <div style="font-weight: 700;">HELP : <span class="primary-color">ims_admin@sw.co.kr</span></div>
        </div>
        <el-button style="width: 100%; margin-bottom: 38px;" class="imsr-button" @click="submitDialogVisible = false">OK</el-button>
      </el-dialog>

      <!-- 게스트 계정 로그인 시 기능제한 다이얼로그 -->
      <el-dialog
        :visible.sync="guestInDialogVisible"
        :show-close="false"
        width="408px"
        center>
        <span slot="title">
          <div style="text-align: left; padding-top: 10px;">
            <span style="font-weight: bold; font-size: 24px;">Your account is a guest now.</span>
          </div>
        </span>
        <div style="text-align: left; color: #232323; margin-bottom: 30px;">Guest account cannot use some features of the service.<br>Please contact SW for further questions.</div>
        <div style="text-align: right; color: #232323; font-size: 14px; font-weight: bold; padding-right: 23px; cursor: pointer;" @click="setGuestInDialog(false)">OK</div>
      </el-dialog>

      <!-- 새로운 지역에서 로그인 시 다이얼로그 -->
      <el-dialog
        :visible.sync="locationDialogVisible"
        :close-on-click-modal="false"
        width="768px"
        center>
        <span slot="title">
          <span style="font-weight: bold; font-size: 24px;">NOT IN ALLOWED LOCATION</span>
        </span>
        <div class="reset-form-desc" style="margin-bottom: 33px">
          <div>
            Location verification is required to access.<br>
            Please request us to access in new location as below button.<br>
            We will email you to verify it as soon as possible.<br>
            Please be patient. Thank you
          </div>
          <div style="font-weight: 700;">HELP : <span class="primary-color">ims_admin@sw.co.kr</span></div>
        </div>
        <el-button style="width: 100%" class="imsr-button" @click="clickAccessLocation">REQUEST TO ACCESS IN NEW LOCATION</el-button>
      </el-dialog>

      <full-image-viewer></full-image-viewer>
      <guide-pop class="guide-pop"></guide-pop>
      <studio-guide-pop class="guide-pop"></studio-guide-pop>
      <images-crop-pop class="guide-pop"></images-crop-pop>
    </div>
  </body>
</template>

<script>
import ImagesCropPop from './pages/inspiration/ImagesCropPop';
import StudioGuidePop from './pages/commStyle/StudioGuidePop';
import GuidePop from './components/footer/GuidePop';
import ImsrTop from './components/top/ImsrTop';
import ImsrMenu from './components/top/ImsrMenu';
import ImsrFooter from './components/footer/ImsrFooter';
import FullImageViewer from './components/common/FullImageViewer'
import tokenUtil from './util/tokenUtil';
import authService from './services/authService';
import http from './services/config/httpConfig';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import VueMobileDetection from "vue-mobile-detection";
import Vue from 'vue'

Vue.use(VueMobileDetection)

const saveToken = (data) => {
    tokenUtil.saveToken(data);
    authService.setAuthorization(data.access_token);
};

const retryRequest = ({ data }, { config }) => {
    saveToken(data);
    config.headers.Authorization = [data.token_type, data.access_token].join(' ');
    return http.request(config);
};

const getTokenByRefreshToken = () => {
    let refresh = tokenUtil.getRefreshToken();
    return authService.refreshToken(refresh);
};

export default {
  name: 'app',
  components: {
    ImsrTop,
    ImsrMenu,
    ImsrFooter,
    FullImageViewer,
    GuidePop,
    StudioGuidePop,
    ImagesCropPop,
  },
  computed: {
    ...mapGetters('common', [
      'isShowFooter',
      'contentPaddingBottom',
      'isFooter'
    ]),
    ...mapGetters('menu', [
      'selectedMenu'
    ]),
    ...mapGetters('auth', [
      'logInStatus',
      'signInDialog',
      'guestInDialog',
      'isTryLogin',
    ]),
    headerHeight () {
      if (this.$route.path === '/main') {
        return 97;
      }
      return 140;
    },
    signInDialogVisible: {
      get () {
        return this.signInDialog;
      },
      set (param) {
        this.setSignInDialog(param);
      }
    },
    guestInDialogVisible: {
      get () {
        return this.guestInDialog;
      },
      set (param) {
        this.setGuestInDialog(param);
      }
    },
  },
  watch: {
    logInStatus (changeStatus) {
      if (changeStatus === 'success') {
        if (this.isMobile && this.$route.path.indexOf('/3D') === -1) {
          this.$router.push('/downloadappMobile')
        } else if (!this.$store.state.auth.path) {
          const menuList = this.$store.getters['common/menuList'];
          const firstMenu = menuList[0];
          this.$router.push(`${firstMenu.type.toLowerCase()}/${firstMenu.id}/${firstMenu.subMenu[0].id}`);
        } else {
          this.$router.push(this.$store.state.auth.path);
        }
        if (this.isTryLogin) {
          this.setGuideDialogVisible(true);
          this.setIsTryLogin(false);
        }
      } else if (changeStatus === 'notLoggedIn') {
          if (!this.isMobile && this.$route.path.indexOf('/3D') === -1) {
            this.$router.push('/main');
          } else {
            this.$router.push('/downloadappMobile')
          }
      } else if (changeStatus === 'accessTokenExpired') {
          this.$store.dispatch('auth/refreshToken');
      }
    }
  },
  methods: {
    ...mapMutations('auth', [
      'setGuestInDialog',
      'setSignInDialog',
      'setIsTryLogin',
    ]),
    ...mapActions('auth', [
      'signIn'
    ]),
    ...mapMutations('common', [
      'setGuideDialogVisible',
      'setStudioGuideDialogVisible',
      'setCropDialogVisible',
      'checkScroll'
    ]),
    ...mapActions('common', [
      'requestResetPassword',
      'getLadingData'
    ]),
    closeSignForm () {
      this.isSignInFailed = false;
      this.signInform = {
        username: '',
        password: ''
      };
      this.$refs.signInform.clearValidate();
    },
    resetSignForm () {
      this.isSignInFailed = false;
    },
    openedSignForm () {
      this.$refs.signInform.clearValidate();
    },
    clickSignIn () {
      this.$refs.signInform.validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          this.signIn(this.signInform)
            .then(() => {
              this.signInDialogVisible = false;
              loading.close();
            //  this.$router.push(`${firstMenu.type}/${firstMenu.id}/${firstMenu.subMenu[0].id}`);
            })
            .catch((err) => {
              if (err.response.status === '401') {
                this.wrongMsg = err.response.data
              } else if (err.response.status === 406) {
                this.wrongMsg = err.response.data
                this.$confirm('Your account has been inactive because you have not logged in for a long time. If you want to reuse it, please apply for activation.', {
                  confirmButtonText: 'YES',
                  cancelButtonText: 'NO',
                  confirmButtonClass: 'imsr-text-button',
                  cancelButtonClass: 'imsr-text-button',
                  showClose: false,
                }).then(() => {
                  authService.checkDormant(this.signInform.username).then((res) => {
                    if (res.status === 200) {
                      this.$alert(`Your activaton request has been completed. Please wait for the administrator's approval. Thank you.`, {
                        confirmButtonText: 'OK',
                        confirmButtonClass: 'imsr-text-button',
                        showClose: false,
                        callback: () => {
                          this.signInDialogVisible = false;
                        }
                      })
                    }
                  })
                }).catch(() => {})
              } else if (err.response.status === 416) {
                this.signInDialogVisible = false;
                this.locationDialogVisible = true
                this.loginname = this.signInform.username
                // this.wrongMsg = err.response.data
                // this.$confirm('This is not a registered area of use. Do you want to request access from the administrator?', {
                //   confirmButtonText: 'YES',
                //   cancelButtonText: 'NO',
                //   confirmButtonClass: 'imsr-text-button',
                //   cancelButtonClass: 'imsr-text-button',
                //   showClose: false,
                // }).then(() => {
                //   authService.checkRegion(this.signInform.username).then((res) => {
                //     if (res.status === 200) {
                //       this.$alert(`Your request has been received. The manager will contact you by email as soon as possible. Thank you.`, {
                //         confirmButtonText: 'OK',
                //         confirmButtonClass: 'imsr-text-button',
                //         showClose: false,
                //         callback: () => {
                //           this.signInDialogVisible = false;
                //         }
                //       })
                //     }
                //   })
                // }).catch(() => {})
              } else if (err.response.status === 405) {
                this.wrongMsg = err.response.data
                this.$alert('Access is allowed only from regions with authorized IP addresses.', {
                  confirmButtonText: 'OK',
                  confirmButtonClass: 'imsr-text-button',
                  showClose: false,
                })
              } else {
                this.wrongMsg = "WRONG EMAIL OR PASSWORD"
              }
              this.isSignInFailed = true;
              loading.close();

            });
        } else {
          return false;
        }
      });
      
    },
    clickPasswordReset () {
      this.signInDialogVisible = false;

      this.resetForm.subEmail = '';
      this.isResetPasswordFailed = false;
      this.resetPasswordDialogVisible = true;
    },
    resetPasswordForm () {
      this.isResetPasswordFailed = false
    },
    clickResetPassword () {
      this.$refs.resetForm.validate((valid) => {
        if (valid) {
          const re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
          if(re.test(this.resetForm.subEmail)) {
            const loading = this.$loading({
              lock: true,
              text: 'Loading',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            this.requestResetPassword(this.resetForm)
              .then(() => {
                this.resetPasswordDialogVisible = false;
                loading.close();
                this.submitDialogVisible = true
              })
              .catch(() => {
                loading.close();
              });
          } else {
            this.isResetPasswordFailed = true;
          }
        }
        return false;
      });
    },
    openedResetForm () {
      this.$refs.resetForm.clearValidate();
    },
    clickAccessLocation () {
      authService.checkRegion(this.loginname).then((res) => {
        if (res.status === 200) {
          this.$alert(`Your request has been received. The manager will contact you by email as soon as possible. Thank you.`, {
            confirmButtonText: 'OK',
            confirmButtonClass: 'imsr-text-button',
            showClose: false,
            callback: () => {
              this.locationDialogVisible = false
            }
          })
        }
      })
    }
  },
  created () {
    http.interceptors.response.use(null, (error) => {
      const res = error.response;
      if (res.status === 401) {
        const description = res.data.error_description;
        if (description.indexOf('Access token expired') > -1) {
          return getTokenByRefreshToken()
            .then(res => retryRequest(res, error));
        } else if (description.indexOf('Invalid refresh token') > -1) {
          this.$store.commit('auth/logOut');
        }
      } else if (res.status === 403) {
        this.$router.push('/main');
      }
      return Promise.reject(error);
    });

    window.addEventListener('scroll', this.checkScroll);
  },
  data () {
    return {
      wrongMsg: '',
      isMobile: this.$isMobile(),
      submitDialogVisible: false,
      resetPasswordDialogVisible: false,
      locationDialogVisible: false,
      isResetPasswordFailed: false,
      resetForm: {
        subEmail: ''
      },
      resetFormRules: {
        // subEmail: [
        //   { required: true, message: ' ', trigger: 'blur' },
        //   { required: true, message: ' ', trigger: 'change' }
        // ]
      },
      signInform: {
        username: '',
        password: ''
      },
      isSignInFailed: false,
      signInRules: {
        username : [
          { required: true, message: ' ', trigger: 'blur' },
          { required: true, message: ' ', trigger: 'change' }
        ],
        password : [
          { required: true, message: ' ', trigger: 'blur' },
          { required: true, message: ' ', trigger: 'change' }
        ]
      },
      loginname: ''
    }
  },
  mounted () {
    this.isMobile = this.$isMobile()
    // https://codepen.io/flatpixels/pen/qQParK (Horizontal Slide, 마우스 클릭시 이벤트 발생으로 처리)
    // https://codepen.io/erikjung/pen/PjNxyb ( Circular dissolve out 마우스 클릭시 이벤트 발생으로 처리)
    // https://codepen.io/bcarvalho/pen/WXmwBq (Vertical Slide, 마우스 클릭시 이벤트 발생으로 처리)
    // https://codepen.io/supah/pen/zZaPeE (Divided vertical Slide 마우스 클릭시 이벤트 발생으로 처리)
    // https://codepen.io/arrive/pen/EOGyzK (Normal dissolve in out 마우스 클릭시 이벤트 발생으로 처리)
    // https://codepen.io/diemoritat/pen/LKROYZ ( Book page flip 마우스 클릭시 이벤트 발생으로 처리)
    //window.dispatchEvent(new Event('scroll'));
  }
}
</script>

<style>
html, body {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-family: 'Lato', 'Avenir', Helvetica, Arial, sans-serif;
  height: 100%;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #232323;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.imsr-main-container {
  position: relative; 
  display: flex; 
  justify-content: center; 
  padding-top: 147px;
  flex: 1;
}

.imsr-main-container > div {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.imsr-content-top-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.imsr-content-container {
  display: flex;
  justify-content: center;
  min-width: 1620px;
  flex: 1;
}

.imsr-container {
  width: 1620px;
  position: relative;
}

.imsr-divider {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #232323;
  margin: 0;
  width: 100%;
}

.imsr-page-title {
  padding: 30px 0 20px 0;
  font-size: 12px;
  display: flex;
  line-height: 20px;
}

.imsr-page-title > span{
  font-weight: 900;
  font-size: 16px;
  letter-spacing: 4px;
  text-align: center;
  flex: 1;
  display: block;
}

.imsr-page-title .left-container {
  text-align: left;
  flex: 1;
  line-height: 20px;
}

.imsr-page-title .right-container {
  text-align: right;
  flex: 1;
}

.imsr-page-title .imsr-text-button.active {
  font-weight: 900;
}

.imsr-text {
  color: #232323 !important;
  background-color: transparent !important;
  border: none !important;
}

.imsr-text-button {
  cursor: pointer;
  color: #232323 !important;
  background-color: transparent !important;
  border: none !important;
}

.imsr-text-button:hover {
  opacity: 0.8;
}

.imsr-text-button:focus {
  color: #98c8cc !important;
}

.imsr-text-button.active {
  color: #98c8cc !important;
  opacity: 1;
  cursor: default;
}

.imsr-border-button {
  cursor: pointer;
  color: #98c8cc !important;
  background-color: transparent !important;
  border: 1px solid #98c8cc !important;
}

.imsr-border-button:hover {
  border-color: #232323 !important;
}

.imsr-border-button:focus {
  background-color: #98c8cc !important;
  color: #fff !important;
}

.imsr-border-button.active {
  background-color: #98c8cc !important;
  color: #fff !important;
  opacity: 1;
  cursor: default;
}

.imsr-danger-button {
  background-color: #fff !important;
  border-width: 2px;
  border-color: #FD1515 !important;
  color: #FD1515 !important;
}

.imsr-danger-button:hover {
  color: #FD1515 !important;
  border-color: #232323 !important;
}

.imsr-danger-button:focus {
  color: #ffffff !important;
  background-color: #FD1515 !important;
}


.imsr-breadcrumb {
  padding: 20px 0;
  text-align: left;
  font-size: 12px;
}

.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before, .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:before {
  content: none !important;
}

.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:after, .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:after {
  content: '*';
  color: #98c8cc !important;
  margin-left: 4px;
}

.el-message-box {
  width: 400px !important;
  padding: 30px 0px !important
}

.el-message-box__header {
  padding: 0px 30px 5px 30px !important;
  font-weight: bold;
  color: #232323 !important;
}

.el-message-box__content {
  padding: 15px 30px !important;
  color: #232323 !important;
}

.el-carousel__arrow {
  border-radius: 0 !important;
}

.el-carousel__arrow--left {
  left: 0 !important;
}

.el-carousel__arrow--right {
  right: 0 !important;
}

.el-card {
  border-radius: 0 !important;
}

.clearfix:after {
   content: " "; /* Older browser do not support empty content */
   visibility: hidden;
   display: block;
   height: 0;
   clear: both;
}

.primary-color {
  color: #98c8cc;
}

.error-color {
  color: #FD1515;
}

.reset-form-desc {
  text-align: center;
  margin-bottom: 25px;
}

.reset-form-desc > div {
  line-height: 24px;
}

.el-dialog.is-fullscreen > .el-dialog__header {
  padding: 0;
}

.el-dialog.is-fullscreen > .el-dialog__header > .el-dialog__headerbtn {
  right: 40px;
  font-size: 30px;
  border: 1px solid #000;
  line-height: 30px;
  background-color: #fff;
}

.el-dialog.is-fullscreen > .el-dialog__header > .el-dialog__headerbtn:hover {
  border-color: #98c8cc;
}

.el-dialog.is-fullscreen > .el-dialog__body {
  height: 100%;
  padding: 0;
}

.el-checkbox__input.is-disabled + span.el-checkbox__label {
  color: #232323;
}

.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
  border-color: #232323 !important;
}

.el-checkbox__input .el-checkbox__inner {
  border-color: #232323;
}

.large-checkbox .el-checkbox__inner {
  width: 17px !important;
  height: 17px !important;
}

.el-textarea.is-disabled .el-textarea__inner {
  color: #232323 !important;
}

.el-textarea .el-textarea__inner {
  font-family: 'Lato', 'Avenir', Helvetica, Arial, sans-serif;
}


.mint-swipe-indicators > div.is-active {
  background: #232323 !important;
  opacity: 1;
}

.imsr-footer {
  position: fixed;
  width: 100%;
  background-color: #fff;
  bottom: 0;
  z-index: 10;
}

.footerup-enter-active, .footerup-leave-active {
  transition: bottom 1s;
}

.footerup-leave-active {
  transition: bottom 0.3s ease;
}

.footerup-enter, .footerup-leave-to {
  bottom: -130px;
}

.imsr-extent-fixed-header {
  position: fixed; 
  width: 100%; 
  background-color: #fff; 
  top: 150px; 
  z-index: 1000;
}

</style>
