<template>
  <div>
    <div class="imsr-content-top-container">
      <div class="imsr-content-container">
        <div class="imsr-container">
          <div class="imsr-page-title">
            <span v-if="selectedMenu.subMenu.name !== 'SUSTAINABILITY'">READ TREND INSIGHTS</span>
            <span v-else>SUSTAINABILITY</span>
          </div>
          <hr class="imsr-divider"/>
        </div>
      </div>
    </div>
    <div class="imsr-content-container" v-loading="loading" style="height: 100;">
      <trend-item-list
        ref="trendItemList"
        :loading="loading"
        :trendList="trendList"
        :listParam="page"
        @clickThumb="clickContent"
        @infiniteHandler="infiniteHandler">
      </trend-item-list>
    </div>
  </div>
</template>

<script>
import TrendItemList from '@/components/commStyle/TrendItemList'
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  components: {
    TrendItemList
  },
  computed: {
    ...mapGetters('common', [
      'pageBack'
    ]),
    ...mapGetters('menu', [
      'selectedMenu'
    ]),
    ...mapGetters('trend', [
      'trendList',
      'page'
    ]),
    prefix () {
      return `${this.$route.path}/trend`;
    }
  },
  methods: {
    ...mapMutations('common', [
      'clearPageBack',
      'setPageBack'
    ]),
    ...mapMutations('menu', [
      'setExtendFixedHeight'
    ]),
    ...mapActions('trend', [
      'getList'
    ]),
    ...mapMutations('trend', [
      'initTrend',
      'setList'
    ]),
    infiniteHandler ($state) {
      this.getList({
          menuId: this.$route.params.subMenuId,
          page: this.page.page + 1
        })
        .then(() => {
          $state.loaded();
          if (this.trendList.length === this.page.total) {
            $state.complete();
          }
          this.loading = false;
        })
        .catch(() => {
          $state.error();
          this.loading = false;
        });
    },
    clickContent (content) {
      this.setPageBack({
        to: this.selectedMenu.type
      });

      if (content.contentType === 'LIST') {
        this.$router.push(`${this.prefix}/${content.id}/items`);
      } else {
        this.$router.push(`${this.prefix}/${content.id}`);
      }
      
    }
  },
  data () {
    return {
      loading: true
    }
  },
  mounted () {
    this.setExtendFixedHeight(70);
  },
  created () {
    if (!(this.pageBack.isBack && this.pageBack.to === this.selectedMenu.type)) {
      this.initTrend();
  //    this.getStyleList(1);
    } else {
      this.loading = false;
    }
  }
}
</script>

<style scoped>

</style>