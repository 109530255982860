<template>
  <div style="display: flex;">
    <div class="imsr-content-top-container">
      <div class="imsr-container" style="margin-bottom: 30px;">
        <div class="imsr-page-title">
          <span>CART</span>
        </div>
        <hr class="imsr-divider"/>
        <div style="display: flex;">
          <div style="font-size: 12px; padding: 10px 0; text-align: left; flex: 1;">
            <span>
              <el-checkbox :value="isAllChecked" @change="clickSelectAll">
                <span class="imsr-text-button" style="font-size: 12px; padding: 0;">
                  SELECT ALL
                </span>
              </el-checkbox>
            </span> 
            <span style="margin: 0 10px;">|</span>
            <span class="imsr-text-button" @click="clickRemoveCartItem">DELETE</span>
          </div>
        </div>
      </div>
    </div>
    <div class="imsr-content-container" stlye="flex: 1;">
      <div class="imsr-container" style="display: flex" v-loading="loading">
        <div style="flex: 1; margin-right: 30px; height: 100%;">
          <el-row :gutter="40" style="margin-bottom: 50px;" v-for="(rows, index) in itemRows" :key="index">
            <el-col :span="8" class="imsr-style-wrap" v-for="cart in rows" :key="cart.id">
              <div class="imsr-item-card">
                <div style="position: absolute; top: 10px; left: 10px; z-index: 5" class="large-checkbox">
                  <el-checkbox v-model="cart.checked" @change="changeCartChecked(cart, $event)"></el-checkbox>
                </div>
                <el-card :body-style="{ padding: '0px' }" shadow="never" style="border: 0;">
                  <div class="item-image-thumb"
                    :style="{'background-image': `url(${$resizeServer}792-${cart.itemRepImg.fileKey})`}"
                    @click="clickThumb(cart)">
                  </div>
                  <div class="item-style-item-desc">
                    <div class="item-sample">
                      <span>{{ cart.category_name || '-' }}</span>
                    </div>
                    <div v-if="cart.cartItem.category === 'GRAPHIC'">
                      <div :style="cart.cartItem.itemNo.split(',').length === 1 && 'padding-bottom: 19px;'" v-for="item in cart.cartItem.itemNo.split(',')" :key="item">
                        <span style="padding: 0px 8px;" class="item-title">{{ item }}</span>
                      </div>
                    </div>
                    <div v-else style="padding-bottom: 3px;">
                      <span class="item-title" v-if="cart.cartItem.category === 'MY INSPIRATION REQUEST' || cart.cartItem.category === 'MY_INSPIRATION_REQUEST'">{{ cart.cartItem.title }}</span>
                      <span class="item-title" v-else>{{ cart.cartItem.title }}</span>
                      <div class="primary-color" style="min-height: 17px;">{{ cart.cartItem.itemNo }}</div>
                    </div>
                  </div>
                  <div v-if="edit.id !== cart.id">
                    <el-form :model="cart" label-position="left" label-width="0px">
                      <el-form-item>
                        <el-checkbox-group v-model="cart.requestList">
                          <el-row style="min-height: 135px;">
                            <el-col :span="12" style="text-align: left;" v-for="request in requestAllCodes[cart.cartItem.itemType]" :key="request.code">
                              <el-checkbox :label="request.code" disabled>{{request.desc}}</el-checkbox>
                            </el-col>
                          </el-row>
                        </el-checkbox-group>
                        <el-input
                          resize="none"
                          type="textarea"
                          :rows="3"
                          disabled
                          :placeholder="cart.cartItem.category !== 'GRAPHIC' ? 
                            'Leave your comment if you have' : 
                            'Additional request (2D CAD, 3D FORM, ETC...)'"
                          v-model="cart.comment">
                        </el-input>
                      </el-form-item>
                    </el-form>
                    <el-row :gutter="12">
                      <el-col :span="12">
                        <el-button class="imsr-white-button" style="width: 100%;" @click="clickEdit(cart)">EDIT</el-button>
                      </el-col>
                      <el-col :span="12">
                        <el-button type="primary" style="width: 100%;"
                          @click="clickSaveForLater(cart)">SAVE FOR LATER</el-button>
                      </el-col>
                    </el-row>
                  </div>

                  <div v-else v-loading="itemLoading">
                    <el-form :model="edit" label-position="left" label-width="0px">
                      <el-form-item>
                        <el-checkbox-group v-model="edit.requestList">
                          <el-row>
                            <el-col :span="12" style="text-align: left;" v-for="request in requestAllCodes[edit.cartItem.itemType]" :key="request.code">
                              <el-checkbox :label="request.code" @change="changeRequestCode">{{request.desc}}</el-checkbox>
                            </el-col>
                          </el-row>
                        </el-checkbox-group>
                        <el-input
                          resize="none"
                          type="textarea"
                          :rows="3"
                          :disabled="edit.category_name !== 'GRAPHIC' ? 
                            edit.requestList.indexOf('ETC') === -1 : 
                            edit.requestList.indexOf('Additional_Request') === -1"
                          :placeholder="edit.category_name !== 'GRAPHIC' ? 
                            'Leave your comment if you have' : 
                            'Additional request (2D CAD, 3D FORM, ETC...)'"
                          v-model="edit.comment">
                        </el-input>
                      </el-form-item>
                    </el-form>
                    <el-row :gutter="12">
                      <el-col :span="12">
                        <el-button class="imsr-button" style="width: 100%;" 
                          @click="clickModify">DONE</el-button>
                      </el-col>
                      <el-col :span="12">
                        <el-button class="imsr-white-burron" style="width: 100%;"
                          @click="clickEditCancel">CANCEL</el-button>
                      </el-col>
                    </el-row>
                  </div>

                </el-card>
              </div>
            </el-col>
          </el-row>
          <imsr-no-content v-if="!loading && listParam.total === 0"></imsr-no-content>
          <infinite-loading ref="infiniteLoading" spinner='default' @infinite="infiniteHandler">
            <div slot="spinner">
              <el-row :gutter="40" style="margin-bottom: 48px;">
                <el-col :span="8" class="imsr-style-wrap" v-for="idx in emptyCount" :key="idx">
                  <empty-item-card></empty-item-card>
                </el-col>
              </el-row>
            </div>
            <div slot="no-more"></div>
            <div slot="no-result"></div>
            
          </infinite-loading>
        </div>
        <div style="width: 370px; text-align: left; margin-top: 0px;">
          <div style="font-size: 16px;"><span style="font-weight: 900;">{{checkedCartItem.length}}</span> items selected</div>
          <div style="font-size: 16px; color: #999; padding: 15px 0;">
            Leave your comment if you have and press [Send to Recap].  We will check your order asap. Thank you.
          </div>
          <el-button class="imsr-button" style="width: 100%;"
            :disabled="checkedCartItem.length === 0"
            @click="clickSendToRecap">
            SEND TO RECAP
          </el-button>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import EmptyItemCard from '@/components/common/EmptyItemCard'
import ImsrNoContent from '@/components/common/ImsrNoContent'
import { chunk, cloneDeep } from 'lodash'

export default {
  components: {
    EmptyItemCard,
    ImsrNoContent
  },
  computed: {
    ...mapGetters('common', [
      'pageBack'
    ]),
    ...mapGetters('cart', [
      'cartList',
      'requestAllCodes',
      'checkedCartItem',
      'listParam'
    ]),
    // 전체 선택 여부.
    isAllChecked () {
      return this.checkedCartItem.length === this.cartList.length && this.cartList.length > 0;
    },
    // 페이지 로딩 시 하단에 그려질 빈 아이템 수
    emptyCount () {
      if (this.listParam.page === 0) {
        return this.countPerRow;
      } else if (this.listParam.total > this.cartList.length) {
        return (this.listParam.total - this.cartList.length) % this.countPerRow;
      } 
      return 0;
    },
    // 한 row에 그려질 개수로 나눠진 배열
    itemRows () {
      return chunk(this.cartList, this.countPerRow);
    },
  },
  methods: {
    ...mapActions('cart', [
      'getCartList',
      'removeFromCart',
      'saveForLater',
      'modifyCartRequest'
    ]),
    ...mapMutations('common', [
      'setPageBack',
    ]),
    ...mapMutations('menu', [
      'setExtendFixedHeight'
    ]),
    ...mapMutations('cart', [
      'setCartChecked',
      'initList'
    ]),
    // 아이템 클릭 시 아이템 상세 조회
    clickThumb ({ cartItem }) {
      this.setPageBack({
        to: 'cart'
      });
      this.$router.push(`${this.$route.path}/${cartItem.itemType.toLowerCase()}/${cartItem.id}`);
    },
    // 전체 선택 클릭 시
    clickSelectAll (checked) {
      this.setCartChecked({ checked });
    },
    // 아이템 선택 시
    changeCartChecked (item, checked) {
      this.setCartChecked({ cartId: item.id, checked });
    },
    // 아이템 request 수정 버튼 클릭 시
    clickEdit (item) {
      this.edit = cloneDeep(item);
    },
    // 아이템 request 수정 취소 버튼 클릭 시
    clickEditCancel () {
      this.edit = {};
    },
    // 아이템 request 수정 처리
    clickModify () {
      this.itemLoading = true;
      this.modifyCartRequest(this.edit)
        .then(() => {
          this.clickEditCancel();
          this.itemLoading = false;
        })
    },
    // 선택된 아이템 recap cjfl.
    clickSendToRecap () {
      this.$confirm('SEND TO RECAP?', '', {
          confirmButtonText: 'OK',
          cancelButtonText: 'CANCEL',
          confirmButtonClass: 'imsr-text-button',
          cancelButtonClass: 'imsr-text-button',
          showClose: false
        }).then(() => {
          this.$router.push(`${this.$route.path}/recap`);
        }).catch(() => {     
        });
    },
    clickRemoveCartItem () {
      this.$confirm('Delete selected items?', '', {
          confirmButtonText: 'OK',
          cancelButtonText: 'CANCEL',
          confirmButtonClass: 'imsr-text-button',
          cancelButtonClass: 'imsr-text-button',
          showClose: false
        }).then(() => {
          this.loading = true;
          return this.removeFromCart()
        }).then(() => {
          this.loading = false;
        })
        .catch((err) => {
          if (err !== 'cancel') {
            this.loading = false;
            this.$message({
              message: '카트 아이템 삭제 실패.',
              type: 'error'
            });
          }
        });
    },
    clickSaveForLater (item) {
      this.loading = true;
      this.saveForLater({
          cartIds: [item.id],
          forLater: true
        })
        .then(() => {
          this.loading = false;
          this.$toasted.show('Saved for later Successfully.', {
            position: 'bottom-center',
            duration : 3000
          });
        })
        .catch(() => {
          this.loading = false;
          this.$message({
            message: 'Saved for later 실패.',
            type: 'error'
          });
        });
    },
    changeRequestCode () {
      if (this.edit.requestList.indexOf('ETC') === -1) {
        this.edit.comment = '';
      }
    },
    // 카트 아이템 조회 
    infiniteHandler ($state) {
      this.getCartList(this.listParam.page + 1)
        .then(() => {
          // 데이터(카트 아이템) 조회 완료.
          $state.loaded(); 

          // 카트 아이템이 모두 조회 되면 complete 처리.
          if (this.cartList.length === this.listParam.total) {
            $state.complete(); 
          }
          this.loading = false;
        })
        .catch(() => {
          $state.error();
          this.loading = false;
        });
    }
  },
  data () {
    return {
      checked: false,
      loading: false,
      edit: {},
      itemLoading: false,
      countPerRow: 3
    }
  },
  mounted () {
    this.setExtendFixedHeight(110);
  },
  created () {
    // 아이템 조회 후 back버튼을 통해 온게 아니라면 다시 조회.
    if (!(this.pageBack.isBack && this.pageBack.to === 'cart')) {
      this.loading = true;
      this.initList({
        forLater: false
      });
    }
    
  }
}
</script>

<style scoped>
.imsr-item-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.imsr-style-wrap {
  display: flex;
  justify-content: center;
}

.imsr-style-wrap .item-image-thumb {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 370px;
  height: 370px;
  cursor: pointer;
}

.item-style-item-desc {
  display: flex;
  justify-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8px 10px;
}

.item-title {
  font-size: 16px;
  font-weight: bold;
  padding: 8px;
}

.item-sample > span {
  width: 200px;
  background-color: #ccc;
  text-align: center;
  display: inline-block;
  line-height: 24px;
}



</style>