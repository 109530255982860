<template>
  <div>
    <div class="imsr-content-container" style="margin-top: 30px;">
      <div class="imsr-container" v-loading="loading" style="display: flex;">
        <div style="position: relative; width: 1200px; overflow-y: auto; overflow-x: hidden; height: 100%; margin-right: 48px;">
          <div style="position: absolute; left: 0; width: 100%; padding-right: 0; top: 0; padding-bottom: 30px;">
            <div class="imsr-page-title">
              <div class="imsr-text-button left-container" @click="clickBack">
                <span class="mdi mdi-arrow-left mdi-12px"></span>
                <span class="text"> BACK</span>
              </div>
              <span></span>
              <div class="imsr-text-button right-container" v-if="this.selectedTrend.pdfFileName">
                <span class="mdi mdi-download mdi-12px"></span>
                <span class="text" >
                  <a class="pdf-download" 
                    :href="selectedTrend.pdfFilePath" 
                    :download="selectedTrend.pdfFileName"> DOWNLOAD PDF</a>
                </span>
              </div>
            </div>
            <hr class="imsr-divider"/>
            <div class="imsr-breadcrumb">
              {{breadcrumb}}
            </div>
            <trend-content-view v-if="!loading"
              style="padding-right: 0;"
              :content="selectedTrend"
              @handleWheel="handleWheel">
            </trend-content-view>
          </div>
        </div>
        <div>
          <div style="width: 372px;">
            <hr class="imsr-divider" style="margin-top: 69px;"/>
            <div style="position: relative;">
              <div style="position: absolute; top: 16px; width: 100%;" v-if="selectedTrend.trendItems.length > 0">
                <div class="trend-more-button" v-if="selectedTrend.trendItems.length > 0" @click="clickItem(selectedTrend.trendItems[0])">more ></div>
                <el-carousel arrow="always" indicator-position="none"
                  height="372px"
                  :autoplay="false"
                  @change="changeThumbSlide">
                  <el-carousel-item v-for="item in selectedTrend.trendItems" :key="item.itemId">
                    <div>
                      <div class="trend-item-thumb"
                        style="cursor: pointer;" 
                        @click="clickThumb(item)"
                        :style="{'background-image': `url(${$resizeServer}792-${item.itemRepImg.fileKey})`}">
                      </div>
                    </div>
                  </el-carousel-item>
                </el-carousel>
                <div style="color: #999; padding: 5px;">{{currentThumbSlideIdx + 1}} / {{selectedTrend.trendItems.length}}</div>
                <div class="primary-color" style="padding: 10px 0; font-size: 16px;" v-if="currentTrendItem">{{currentTrendItem.itemNo || currentTrendItem.no}}</div>
                <div style="font-size: 24px; font-weight: bold; padding: 5px;" v-if="selectedTrend.category === 'STYLE'">Our Most-Hearted<br/>Suggestion Style</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { saveAs } from 'file-saver'; 
import TrendContentView from  '../../../../admin/src/components/trend/preview/step/TrendContentView'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'StyleContents',
  components: {
    TrendContentView
  },
  computed: {
    ...mapGetters('common', [
      'pageBack'
    ]),
    ...mapGetters('menu', [
      'selectedMenu'
    ]),
    ...mapGetters('trend', [
      'selectedTrend'
    ]),
    breadcrumb () {
      return this.selectedTrend.menuPath.path.split(',').join(' > ');
    }
  },
  methods: {
    ...mapMutations('menu', [
      'setExtendFixedHeight'
    ]),
    ...mapMutations('trend', [
      'setSelectedTrend'
    ]),
    ...mapActions('trend', [
      'getTrendInfo'
    ]),
    ...mapMutations('common', [
      'setPageBack',
      'setIsShowFooter',
      'setContentPaddingBottom'
    ]),
    changeThumbSlide (idx) {
      this.currentThumbSlideIdx = idx;
      this.currentTrendItem = this.selectedTrend.trendItems[idx];
    },
    // 아이템 클릭 시 아이템 상세 조회
    clickThumb (item) {
      this.$router.push(`${this.$route.path}/item/${item.itemType}/${item.itemId}`);
      // this.$router.push(`${this.$route.path}/items?item=${item.itemId}`);
    },
    // more 클릭 시 아이템 리스트 페이지로 이동
    clickItem (item) {
      this.setPageBack({
        to: this.selectedMenu.type
      });

      const subMenu = this.selectedMenu.subMenu;

      if (subMenu.type === 'GRAPHIC' && subMenu.name !== 'TREND') {
        this.$router.push(`${this.$route.path}/3D/palette`);
      } else {
        this.$router.push(`${this.$route.path}/items?item=${item.itemId}`);
      }
    },
    clickBack () {
      if (this.pageBack.to) {
        this.setPageBack({
          isBack: true,
          fromType: this.selectedMenu.type
        });
        this.$router.go(-1);
      } else {
        const menu = this.selectedMenu.menu;
        const subMenuName = this.$route.params.subMenuName ? '/' + this.$route.params.subMenuName : '';
        this.$router.push(`/${menu.type.toLowerCase()}/${menu.id}/${this.selectedMenu.subMenu.id}${subMenuName}`);
      }
    },
    downloadPdf () {
      saveAs(this.selectedTrend.pdfFilePath, this.selectedTrend.pdfFileName);
    },
    handleWheel () {
      this.setIsShowFooter(false);
    }
  },
  data () {
    return {
      currentTrendItem: {},
      currentThumbSlideIdx: 0,
      loading: false,
    }
  },
  created () {
    if (!(this.pageBack.isBack && this.pageBack.to === this.selectedMenu.type)) {
      this.loading = true;
      this.setSelectedTrend({
        trendFiles: [],
        trendItems: [],
        menuPath: {
          path: ''
        }
      });
      this.getTrendInfo(this.$route.params.trendId)
        .then(() => {
          this.currentTrendItem = this.selectedTrend.trendItems[0];
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    } else {
      this.currentTrendItem = this.selectedTrend.trendItems[0];
    }
    this.setContentPaddingBottom(0);
  },
  mounted() {
    this.setExtendFixedHeight(125);
  },
  destroyed () {
    this.setContentPaddingBottom(130);
  }
}
</script>

<style scoped>
.imsr-conent-top-bottom {
  padding: 20px 0;
}

.imsr-conent-top-bottom .text {
  font-size: 12px;
  margin-left: 5px;
  line-height: 17px;
}

.imsr-style-contents-container {
  padding: 48px 0;
}

.imsr-style-contents-top {
  font-size: 16px;
  margin-bottom: 48px;
}

.trend-item-thumb {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 372px;
  height: 372px;
}

.pdf-download {
  color: #232323;
  text-decoration: none;
}

.trend-more-button {
  font-size: 16px;
  color: #6f6f6f;
  text-align: right;
  margin-bottom: 16px;
  cursor: pointer;
}
</style>