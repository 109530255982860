<template>
    <div class="center">
        <div class="text imsr-page-title">
            <span class="primary-color">404</span>
            <br/><br/>
            <span>Not Found</span>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.text {
    display: inline-block;
    font-size: 25px;
    margin-top: 50px;
    text-align: center;
    font-weight: bold;
}
</style>