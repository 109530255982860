<template>
  <div>
    <page-flip :pages="pages" v-if="type === 'ani-1'"></page-flip>
    <slide-h :pages="pages" v-else-if="type === 'ani-2'"></slide-h>
    <slide-v :pages="pages" v-else-if="type === 'ani-3'"></slide-v>
    <fade-in-out :pages="pages" v-else-if="type === 'ani-4'"></fade-in-out>
    <dissolve :pages="pages" v-else-if="type === 'ani-5'"></dissolve>
  </div>
</template>

<script>
import PageFlip from './animation/PageFlip'
import SlideH from './animation/SlideH'
import SlideV from './animation/SlideV'
import FadeInOut from './animation/FadeInOut'
import Dissolve from './animation/Dissolve'
import { map } from 'lodash'

export default {
  components: {
    PageFlip,
    SlideH,
    SlideV,
    FadeInOut,
    Dissolve
  },
  computed: {
    pages () {
      return map(this.content, item => item.src);
    }
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    content: {
      type: Array,
      default: () => {
        return [];
      }
    }
  }
}
</script>

<style>

</style>