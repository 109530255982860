<template>
  <div style="height: 100%; display: flex; flex-direction: column;">
    <div class="imsr-content-top-container" style="margin-bottom: 30px;">
      <div class="imsr-container">
        <div style="display: flex;">
          <div style="font-size: 12px; padding: 20px 0; text-align: left; flex: 1;">
            <span>
              <el-checkbox v-model="isAllChecked" @change="clickSelectAll">
                <span class="imsr-text-button" style="font-size: 12px; padding: 0;">
                  SELECT ALL
                </span>
              </el-checkbox>
            </span> 
            <span style="margin: 0 10px;">|</span>
            <span class="imsr-text-button" @click="clickRemoveWish">DELETE</span>
          </div>
          <div style="flex: 1; text-align: right;">
            <div class="item-top-button-group" style="padding: 20px 0;">
              VIEW BY 
              <span class="imsr-text-button" 
                :class="{active: listParam.itemType === ''}"
                @click="changeViewType('')">ALL</span> 
              <span>|</span>
              <span class="imsr-text-button" 
                :class="{active: listParam.itemType === 'STYLE'}"
                @click="changeViewType('STYLE')">STYLE</span> 
              <span>|</span> 
              <span class="imsr-text-button" 
                :class="{active: listParam.itemType === 'FABRIC'}"
                @click="changeViewType('FABRIC')">FABRIC</span>
              <span>|</span> 
              <span class="imsr-text-button" 
                :class="{active: listParam.itemType === 'GRAPHIC'}"
                @click="changeViewType('GRAPHIC')">GRAPHIC</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="imsr-content-container" style="flex: 1;">
      <div class="imsr-container imsr-item-list" v-loading="loading">
        <el-row :gutter="24" v-for="(rows, index) in itemRows" :key="index" style="margin-bottom: 48px;">
          <el-col :span="6" class="imsr-style-wrap" v-for="item in rows" :key="item.itemId">
            <div class="imsr-item-card" style="position: relative;">
              <div style="position: absolute; top: 10px; left: 10px; z-index: 10" class="large-checkbox">
                <el-checkbox v-model="item.checked" @change="changeWishChecked(item, $event)"></el-checkbox>
              </div>
              <el-card class="imsr-style-item" 
                :body-style="{ padding: '0px' }" shadow="never" style="border: 0; margin-bottom: 40px;">
                <div class="item-image-thumb" :style="{'background-image': `url(${$resizeServer}792-${item.itemRepImg.fileKey})`}"
                  @click="clickThumb(item)">
                </div>
                <div class="item-style-item-desc">
                  <div class="item-sample">
                    <span>{{ item.category_name || '-' }}</span>
                  </div>
                  <span class="item-title">{{ item.title }}</span>
                  <div class="primary-color" >{{ item.itemNo }}</div>
                  <div class="item-style-tag-group">
                    <span class="item-style-tag" v-for="tag in item.tags" :key="tag.name">{{ tag.name }}</span>
                  </div>
                  <div v-loading="wishLoading && wishLoading === item.itemId">
                    <div class="item-style-favorite" v-if="item.addedWish">
                      <span class="mdi mdi-heart mdi-24px error-color"
                        @click="clickWish(item)">
                      </span>
                      <span class="item-style-favorite-member" v-if="item.wishTotal === 1"></span>
                      <span class="item-style-favorite-member" v-else>
                        Added by {{userInfo.firstName}} and {{item.wishTotal - 1}} more
                      </span>
                    </div>
                    <div class="item-style-favorite" v-else-if="item.wishTotal > 0">
                      <span class="mdi mdi-heart-outline mdi-24px"
                        @click="clickWish(item)">
                      </span>
                      <span class="item-style-favorite-member">
                        Added by {{item.wishTotal}} more
                      </span>
                    </div>
                    <div class="item-style-favorite" v-else>
                      <span class="mdi mdi-heart-outline mdi-24px"
                        @click="clickWish(item)">
                      </span>
                      <span class="item-style-favorite-member">
                        
                      </span>
                    </div>
                  </div>
                </div>
                <div class="imsr-divider-group" style="margin-top: 10px;">
                  <hr class="imsr-divider imsr-style-item-divider"/>
                </div>
              </el-card>
            </div>
          </el-col>
        </el-row>
        <imsr-no-content v-if="!loading && listParam.total === 0"></imsr-no-content>
        <infinite-loading ref="infiniteLoading" spinner='default' @infinite="infiniteHandler">
          <div slot="spinner">
            <el-row :gutter="24" style="margin-bottom: 48px;">
              <el-col :span="6" class="imsr-style-wrap" v-for="idx in emptyCount" :key="idx">
                <empty-item-card></empty-item-card>
              </el-col>
            </el-row>
          </div>
          <div slot="no-more"></div>
          <div slot="no-result"></div>
          
        </infinite-loading>
      </div>
    </div>
  </div>
</template>

<script>
import EmptyItemCard from '@/components/common/EmptyItemCard'
import ImsrNoContent from '@/components/common/ImsrNoContent';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { chunk, map } from 'lodash';

export default {
  props: ['menu'],
  components: {
    EmptyItemCard,
    ImsrNoContent
  },
  computed: {
    ...mapGetters('common', [
      'pageBack'
    ]),
    ...mapGetters('auth', [
      'userInfo'
    ]),
    ...mapGetters('wish', [
      'wishList',
      'listParam',
      'checkedList'
    ]),
    emptyCount () {
      if (this.listParam.page === 0) {
        return this.countPerRow;
      } else if (this.listParam.total > this.wishList.length) {
        return (this.listParam.total - this.wishList.length) % this.countPerRow;
      } 
      return 0;
    },
    itemRows () {
      return chunk(this.wishList, this.countPerRow);
    },
  },
  methods: {
    ...mapMutations('common', [
      'setPageBack',
      'clearPageBack'
    ]),
    ...mapMutations('menu', [
      'setExtendFixedHeight'
    ]),
    ...mapActions('wish', [
      'getWishList',
      'addWish',
      'removeWish'
    ]),
    ...mapMutations('wish', [
      'setWishType',
      'setListParam',
      'initList',
      'initWish',
      'setCheckedItem',
      'setAllChecked'
    ]),
    changeViewType (itemType) {
      this.initWish();

      this.loading = true;
      this.$refs.infiniteLoading.stateChanger.reset();

      this.setListParam({
        itemType
      });
      
    },
    clickWish (item) {
      this.wishLoading = item.itemId;
      if (item.addedWish) {
        this.removeWish([item.itemId])
          .then(() => {
            this.wishLoading = false;
          })
          .catch(() => {
            this.wishLoading = false;
          });
      } else {
        this.addWish(item.itemId)
          .then(() => {
            this.wishLoading = false;
          })
          .catch(() => {
            this.wishLoading = false;
          });
      }
    },
    clickSelectAll () {
      this.setAllChecked(this.isAllChecked);
    },
    clickRemoveWish () {
      if (this.checkedList.length === 0) {
        return false;
      }
      
      this.loading = true;
      const ids = map(this.checkedList, item => item.itemId);
      
      this.removeWish(ids)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    changeWishChecked (item, checked) {
      this.setCheckedItem({
        itemId: item.itemId,
        checked
      });
    },
    clickThumb (item) {
      this.setPageBack({
        to: 'wish'
      });
      this.$router.push(`${this.$route.path}/${item.itemType}/${item.itemId}`);
    },
    infiniteHandler ($state) {
      this.getWishList(this.listParam.page + 1)
        .then(() => {
          $state.loaded();
          if (this.wishList.length === this.listParam.total) {
            $state.complete();
          }
          this.loading = false;
        })
        .catch(() => {
          $state.error();
          this.loading = false;
        });
    }
  },
  data () {
    return {
      countPerRow: 4,
      loading: false,
      isAllChecked: false,
      wishLoading: false
    }
  },
  mounted() {
  },
  created () {
    if (!(this.pageBack.isBack && this.pageBack.to === 'wish')) {
      this.loading = true;
      this.setWishType('USER');
      this.initWish();
    }
  }
}
</script>

<style scoped>
.item-top-button-group > span {
  padding-left: 13px;
}

.imsr-item-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.imsr-item-list .imsr-style-wrap {
  display: flex;
  justify-content: center;
}

.imsr-style-wrap .item-image-thumb {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 370px;
  height: 370px;
  cursor: pointer;
}

.imsr-item-list .item-style-item-desc {
  display: flex;
  justify-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8px 10px;
}

.imsr-item-list .item-title {
  font-size: 16px;
  font-weight: bold;
  padding: 8px;
}

.item-style-favorite > span {
  display: block;
}

.item-style-favorite > span.mdi {
  cursor: pointer;
}

.item-style-favorite .item-style-favorite-member {
  font-size: 12px;
  display: block;
  margin-top: 4px;
  line-height: 18px;
  min-height: 18px;
}

.imsr-divider-group {
  margin-top: 20px;
}

.imsr-style-item .imsr-style-item-divider {
  border-radius: 0;
  padding-top: 4px;
}

.imsr-style-item.active .imsr-style-item-divider {
  border-color: #98c8cc;
  border-bottom-width: 10px;
  padding: 0;
}

.item-style-tag-group {
  display: flex;
  justify-items: center;
  justify-content: center;
  padding: 8px 0;
}

.item-style-tag {
  font-size: 12px;
  line-height: 18px;
  padding: 0 7px;
  margin: 3px;
  background-color: #eee;
  border-radius: 7px;
}

.imsr-style-item {
  width: 100%;
  max-width: 372px;
  
}

.imsr-item-list .item-sample > span {
  width: 200px;
  background-color: #ccc;
  text-align: center;
  display: inline-block;
  line-height: 24px;
}
</style>