import http from './config/httpConfig';

const prefix = '/api/v1/faq';

export default {
  getFaqList (param) {
    return http.get(`${prefix}`, {
      params: param
    });
  },
  getFaqInfo (no) {
    return http.get(`${prefix}/${no}`);
  }
}