<template>
  <div class="empty-list-item">
    <div style="width: 100%; max-width: 512px; height: 372px;"></div>
    <div style="width: 96px; height: 24px; margin: 10px 0 5px;"></div>
    <div style="width: 100%; max-width: 512px; height: 72px; margin-bottom: 15px;"></div>
    <hr class="imsr-divider"/>
  </div>
</template>

<script>
export default {
  name: 'EmptyListCard'
}
</script>

<style scoped>
.empty-list-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.empty-list-item > div {
  background-color: #eee;
}
</style>