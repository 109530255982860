import http from './config/httpConfig';

const prefix = '/api/v1/carts';

export default {
  getCartItems (param) {
    return http.get(`${prefix}`, {
      params: param
    });
  },
  modifyCartItem (id, param) {
    return http.put(`${prefix}/${id}`, param);
  },
  addItem (form) {
    return http.post(`${prefix}`, form);
  },
  removeItems (ids) {
    return http.delete(`${prefix}`, {
      params: {
        ids
      }
    });
  },
  getItemCartRequest (id) {
    return http.get(`${prefix}/items/${id}`);
  },
  saveForLater ({ cartIds, forLater }) {
    return http.put(`${prefix}/saveForLater`, {
      cartIds,
      forLater
    });
  }
}