<template>
  <div>
    <div class="imsr-content-top-container">
      <div class="imsr-container">
        <div class="imsr-page-title">
          <div class="imsr-text-button left-container" @click="clickBack">
            <span class="mdi mdi-arrow-left mdi-12px"></span>
            <span style="font-size: 12px;"> BACK</span>
          </div>
          <span>ITEM DETAIL</span>
          <div class="right-container"></div>
        </div>
        <hr class="imsr-divider"/>
      </div>
    </div>

    <div class="imsr-content-container" style="height: 100%;">
      <div class="imsr-container" v-loading="loading">
        <div style="display: flex; padding-top: 20px;">
          <div style="flex: 1; padding-right: 20px;">
            <item-add-carousel 
              style="background-color: #fff;"
              v-if="!initFlag"
              ref="imageCarousel" 
              :selectedImage="viewImages"
              :itemType="itemType"
              :viewMode="true"
              @changeCarousel="changeCarousel">
            </item-add-carousel>
          </div>
          <div style="flex: 1;">
            <div style="padding-left: 10px; margin-bottom: 50px;">
              <item-thumb-image-select
                ref="itemThumbImageSelect"
                :currentImage="currentImage"
                :viewMode="true"
                @clickThumb="setCarouselActiveItem">
              </item-thumb-image-select>
              <studio-item-detail
                ref="itemDetailForm"
                v-if="!initFlag && itemType === 'graphic_studio'"
                :item="itemInfo"
                :detail="itemDetail"
                :isRecap="isRecap"
                :recapId="recapId"
                @clickAddToCart="clickAddToCart">
              </studio-item-detail>
              <inspiration-item-detail
                ref="itemDetailForm"
                v-else-if="!initFlag && itemType === 'inspiration'"
                :item="itemInfo"
                :detail="itemDetail"
                :isRecap="isRecap"
                :recapId="recapId"
                @clickAddToCart="clickAddToCart">
              </inspiration-item-detail>
              <item-detail-form
                v-else-if="!initFlag"
                ref="itemDetailForm"
                :item="itemInfo"
                :detail="itemDetail"
                :itemType="itemType"
                :isRecap="isRecap"
                :recapId="recapId"
                @clickAddToCart="clickAddToCart"
                @showRelatedItem="clickRelatedItem">
              </item-detail-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemAddCarousel from '../../../../admin/src/components/item/ItemAddCarousel'
import ItemThumbImageSelect from '../../../../admin/src/components/item/ItemThumbImageSelect'
import ItemDetailForm from '@/components/commStyle/ItemDetailForm'
import StudioItemDetail from '@/components/commStyle/StudioItemDetail'
import InspirationItemDetail from '@/components/commStyle/InspirationItemDetail'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'StyleItemDetail',
  components: {
    ItemAddCarousel,
    ItemThumbImageSelect,
    ItemDetailForm,
    StudioItemDetail,
    InspirationItemDetail
  },
  computed: {
    ...mapGetters('auth', [
      'userInfo',
    ]),
    ...mapGetters('menu', [
      'selectedMenu'
    ]),
    ...mapGetters('item', [
      'itemInfo',
      'itemDetail',
      'viewImages'
    ]),
    ...mapGetters('common', [
      'pageBack'
    ]),
    fullImageDialogVisible () {
      return !!this.fullImage;
    },
    isRecap () {
      return this.currentType === 'mypage' || this.currentType === 'recap';
    }
  },
  methods: {
    ...mapMutations('auth', [
      'setGuestInDialog'
    ]),
    ...mapMutations('common', [
      'setPageBack',
      'clearPageBack'
    ]),
    ...mapMutations('menu', [
      'setExtendFixedHeight'
    ]),
    ...mapMutations('item', [
      'clearItemInfo'
    ]),
    ...mapActions('item', [
      'getItemInfo'
    ]),
    ...mapActions('cart', [
      'addToCart'
    ]),
    changeCarousel (idx) {
      this.currentImage = this.viewImages[idx];
    },
    setCarouselActiveItem (item) {
      this.$refs.imageCarousel.setActiveItem(item.name);
    },
    clickBack () {
      if (this.pageBack.to) {
        this.setPageBack({
          isBack: true,
          fromType: this.currentType
        });
        this.$router.go(-1);
      } else {
        this.clearPageBack();
        const path = this.$route.path;

        let idx = path.indexOf('/related/');
        if (idx > -1) {
          this.$router.push(path.substring(0, idx));
        } else {
          idx = path.indexOf('/item');
          if (idx > -1) {
            if (this.selectedMenu.subMenu.type === 'GRAPHIC' && this.selectedMenu.subMenu.name !== 'TREND') {
              this.$router.push(`${path.substring(0, idx)}/3D/palette`);
            } else {
              this.$router.push(`${path.substring(0, idx)}/items`);
            }
          } else if (this.currentType === 'mypage' || this.currentType === 'recap') {
            this.$router.push(`/mypage/recap/${this.$route.params.recapId}`);
          } else if (this.currentType === 'wish') {
            this.$router.push(`/wish/${this.$route.params.wishType}`);
          } else if (this.currentType === 'sustainability') {
            this.$router.push(`${path.substring(0, path.indexOf('/library'))}/library`);
          } else if (this.currentType === 'cart') {
            this.$router.push('/cart');
          }
        }        
      }
    },
    clickAddToCart (form) {
      if ((form.requestList.indexOf('ETC') > -1 || form.requestList.indexOf('Additional_Request') > -1) && form.comment === "") {
        this.$alert(this.selectedMenu.subMenu.type === 'GRAPHIC' ? 'Please fill in Additional Request' : 'Please fill in ETC comment', {
          confirmButtonText: 'OK',
          showClose: false,
          confirmButtonClass: 'imsr-button'
        });
      } else {
        if (this.userInfo.companyInfo === null && !this.userInfo.admin) {
          this.setGuestInDialog(true)
        } else {
          this.loading = true;
          this.addToCart(form)
            .then(() => {
              this.$refs.itemDetailForm.checkedCart();
              this.loading = false;
              this.$toasted.show('Added to Cart Successfully.', {
                position: 'bottom-center',
                duration : 3000
              });
            })
            .catch(() => {
              this.loading = false;
              this.$message({
                message: '카트 등록 실패.',
                type: 'error'
              });
            });
        }
      }
    },
    clickRelatedItem ({ type, itemId }) {
      this.$router.push(`${this.$route.path.split('/related')[0]}/related/${type.toUpperCase()}/${itemId}`);
      // this.$router.push(`${this.$route.path}/related/${type.toUpperCase()}/${itemId}`);
    }
  },
  data () {
    return {
      currentType: '',
      itemType: '',
      currentImage: {},
      loading: true,
      initFlag: true,
      recapId: -1
    }
  },
  mounted () {
    this.setExtendFixedHeight(70);
    
    this.currentType = this.selectedMenu.menu.type ? this.selectedMenu.menu.type.toLowerCase() : this.selectedMenu.type;
    this.itemType = this.$route.params.itemType;
    this.recapId = this.$route.params.recapId;
    this.getItemInfo({
        type: this.itemType, 
        itemId: this.$route.params.itemId
      })
      .then(() => {
        this.loading = false;
        this.initFlag = false;
        this.currentImage = this.viewImages[0];
        this.$refs.itemThumbImageSelect.setImages(this.viewImages);
      });

  },
  beforeDestroy () {
    this.clearItemInfo();
  }
}
</script>

<style>

</style>