<template>
  <div>
    <div class="imsr-content-top-container">
      <div class="imsr-content-container" style="margin-bottom: 30px;">
        <div class="imsr-container">
          <div class="imsr-page-title">
            <span>LIBRARY</span>
          </div>
          <hr class="imsr-divider"/>
        </div>
      </div>
    </div>
    <div class="imsr-content-container">
      <item-card-list
        :loading="loading"
        :itemList="itemList"
        :listParam="listParam"
        @clickThumb="clickThumb"
        @setItemWish="setItemWish"
        @infiniteHandler="infiniteHandler">
      </item-card-list>

    </div>
  </div>
</template>

<script>
// SUSTAINABILITY > LIBRARY
import ItemCardList from '@/components/commStyle/ItemCardList'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  components: {
    ItemCardList
  },
  computed: {
    ...mapGetters('common', [
      'pageBack'
    ]),
    ...mapGetters('menu', [
      'selectedMenu'
    ]),
    ...mapGetters('item', [
      'listParam',
      'searchParam',
      'itemList'
    ])
  },
  methods: {
    ...mapMutations('common', [
      'setPageBack'
    ]),
    ...mapMutations('menu', [
      'setExtendFixedHeight'
    ]),
    ...mapActions('item', [
      'getSearchItems'
    ]),
    ...mapMutations('item', [
      'initItemList',
      'setSearchParam',
      'setItemWish'
    ]),
    getItemListByPageNo (page) {
      return this.getSearchItems(page);
    },
    clickThumb (item) {
      this.setPageBack({
        to: 'library'
      });

      this.$router.push(`${this.$route.path}/${item.itemType}/${item.itemId}`);
    },
    initPage () {
      this.loading = true;
      this.initItemList();
      this.setSearchParam({
        sustainability: true
      });
    },
    infiniteHandler ($state) {
      this.getItemListByPageNo(this.listParam.page + 1)
        .then(() => {
          $state.loaded();
          this.loading = false;
          if (this.itemList.length === this.listParam.total) {
            $state.complete();
          }
        })
        .catch(() => {
          $state.error();
          this.loading = false;
        });
    }
  },
  data () {
    return {
      loading: false
    }
  },
  mounted () {
    this.setExtendFixedHeight(70);
    
  },
  created () {
    if (!(this.pageBack.isBack && this.pageBack.to === 'library')) {
      this.initPage();
    }
  }
}
</script>

<style scoped>
.item-top-button-group {
  float: right; 
  padding: 20px 0;
  font-size: 12px;
}

.item-top-button-group > span {
  padding-left: 13px;
}

.item-top-button-group .imsr-text-button.active {
  font-weight: 900;
  color: #232323 !important;
}
</style>