<template>
  <div>
    <div class="imsr-content-top-container">
      <div class="imsr-content-container">
        <div class="imsr-container">
          <div class="imsr-page-title">
            <span>LIBRARY</span>
          </div>
          <hr class="imsr-divider"/>
        </div>
      </div>
    </div>
    <div class="imsr-content-container" style="height: 100%;">
      <div class="imsr-container" v-loading="loading">
        <div v-for="(category, idx) in categoryList" :key="category.id">
          <hr class="imsr-divider" v-if="idx > 0"/>
          <div class="menu-container">
            <div class="menu-name">{{category.name}}</div>
            <div style="flex: 1;">
              <el-row :gutter="32" v-for="(row, index) in category.children" :key="index" style="margin-bottom: 32px;">
                <el-col :span="8" v-for="child in row" :key="child.id">
                  <div class="menu-button" :style="{'background-image': `url(${child.thumbPath})`}"
                    @click="clickMenu(child.recentTrendId)">
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import cmmService from '@/services/cmmService'
import { map, chunk } from 'lodash'

export default {
  computed: {
    ...mapGetters('common', [
      'pageBack'
    ]),
    ...mapGetters('menu', [
      'selectedMenu'
    ]),
  },
  methods: {
    ...mapMutations('common', [
      'setPageBack'
    ]),
    ...mapMutations('menu', [
      'setExtendFixedHeight'
    ]),
    clickMenu (trendId) {
      if (!trendId) {
        return false;
      }
      
      this.setPageBack({
        to: this.selectedMenu.type
      });
      this.$router.push(`${this.$route.path}/trend/${trendId}`);
    }
  },
  data () {
    return {
      loading: true,
      categoryList: []
    }
  },
  created () {
    let tmpCategory;
    cmmService.getWashingSubCategory(this.selectedMenu.subMenu.id)
      .then(res => {
        tmpCategory = res.data;
        const ajax = map(tmpCategory, category => {
          return cmmService.getWashingSubCategory(category.id)
        });
        Promise.all(ajax)
          .then((res) => {
            this.categoryList = map(tmpCategory, (category, idx) => {
              category.children = chunk(res[idx].data, 3);
              return category;
            });
            this.loading = false;
          });
      });
    
  },
  mounted () {
    this.setExtendFixedHeight(70);
  }
}
</script>

<style scoped>
.menu-container {
  display: flex;
  margin-top: 32px;
}

.menu-container .menu-name {
  width: 440px;
  text-align: left;
  font-size: 16px;
  font-weight: 900;
}

.menu-button {
  height: 120px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #eee;
  cursor: pointer;
}
</style>