import trendService from '@/services/trendService';
import itemService from '@/services/itemService';
import { assignIn, chain, concat, findIndex, map, take, sortBy } from 'lodash';

const state = {
  list: [],
  page: {
    total: 0,
    page: 0,
    size: 30,
    sort: undefined
  },
  trend: {
    trendFiles: [],
    trendItems: [],
    menuPath: {
      path: ''
    }
  },
  itemPage: {
    total: 0,
    page: 0,
    size: 40
  },
  selectedTrendId: '',
  selectedItemType: '',
  selectedItemId: 0,
  items: [],
  relatedParam: {
    name: '',
    type: ''
  },
};

const getters = {
  trendList (state) {
    return state.list;
  },
  page (state) {
    return state.page;
  },
  itemPage (state) {
    return state.itemPage;
  },
  selectedTrend (state) {
    const trend = state.trend;
    let contents;
    if (trend.contentType === 'IMG' || trend.contentType === 'ANI') {
      contents = chain(trend.trendFiles)
        .map(file => {
          return assignIn(file, {
            name: file.orgName,
            src: file.path
          });
        })
        .sortBy('priority')
        .value();
    }
    return assignIn(trend, {
      trendItems: sortBy(trend.trendItems, 'priority'),
      contents
    });
  },
  trendItems (state) {
    return map(state.items, item => {
      if (item.category) {
        item.category_name = item.category.split('_').join(' ');
      }
      item.tags = take(item.itemTagNames, 2);
      return item;
    });
  },
  selectedItemType (state) {
    return state.selectedItemType;
  },
  relatedParam (state) {
    return state.relatedParam;
  },
  selectedItemId (state) {
    return state.selectedItemId;
  }
};

const actions = {
  getList ({ commit, state }, { menuId, page }) {
    return new Promise((resolve, reject) => {
      const param = {
        page: page - 1,
        size: state.page.size,
        sort: 'publishDate,desc'
      };
      trendService.list(menuId, param)
        .then(res => {
          commit('setList', res.data.list);
          commit('setPage', {
            total: res.data.total,
            page
          });
          resolve();
        })
        .catch(reject);
    });
  },
  getTrendInfo ({ commit, rootGetters }, trendId) {
    return new Promise((resolve, reject) => {
      trendService.getTrendInfo(trendId)
        .then(res => {
          commit('setSelectedTrend', res.data);
          if (rootGetters['menu/selectedMenu'].type === 'STYLE') {
            commit('setSelectedItemType', 'STYLE');
          }
          resolve();
        })
        .catch(reject);
    });
  },
  getTrendItems ({ commit, state, rootGetters }, page) {
    return new Promise((resolve, reject) => {
      const param = {
        page: page - 1,
        size: state.itemPage.size,
        itemType: rootGetters['menu/selectedMenu'].type
      };
      trendService.getTrendItems(state.trend.id, param)
        .then(res => {
          commit('setItems', res.data.list);
          commit('setItemPage', {
            total: res.data.total,
            page
          });
          resolve();
        })
        .catch(reject);
    });
  },
  getTrendRelatedItems ({ commit, state }, _param) {
    return new Promise((resolve, reject) => {
      const param = {
        page: _param.page - 1,
        size: state.itemPage.size,
        trendId: _param.trendId,
        sort: 'priority,asc'
      };

      if (state.relatedParam.name && state.relatedParam.name !== '' && 
        state.relatedParam.type && state.relatedParam.type !== '') {
        param[state.relatedParam.name] = state.relatedParam.type;
      }

      itemService.getItemList(param)
        .then(res => {
          commit('setItems', res.data.list);
          commit('setItemPage', {
            total: res.data.total,
            page: _param.page
          });
          resolve();
        })
        .catch(reject);
    });
  }
};

const mutations = {
  initTrend (state) {
    state.page = {
      page: 0,
      size: 30,
      total: 0,
      sort: undefined
    };
    state.list = [];
    state.selectedItemId = 0;
    state.trend = {
      trendFiles: [],
      trendItems: [],
      menuPath: {
        path: ''
      }
    };
  },
  setList (state, list) {
    state.list = concat(state.list, list);
  },
  setPage (state, param) {
    state.page = assignIn(state.page, param);
  },
  setSelectedTrend (state, info) {
    state.trend = info;
  },
  setSelectedTrendId (state, id) {
    state.trend.id = id;
  },
  setItems (state, list) {
    state.items = concat(state.items, list);
  },
  setItemPage (state, param) {
    state.itemPage = assignIn(state.itemPage, param);
  },
  clearItems () {
    state.items = [];
    state.itemPage = {
      total: 0,
      page: 0,
      size: 40
    };
    state.relatedParam = {
      name: '',
      type: ''
    };
  },
  setSelectedItemType (state, type) {
    state.selectedItemType = type;
  },
  setItemWish (state, { id, wish }) {
    const idx = findIndex(state.items, item => item.itemId === id);
    const item = state.items[idx];
    state.items[idx] = assignIn(item, {
      addedWish: wish,
      wishTotal: wish ? item.wishTotal + 1 : item.wishTotal - 1
    });
  },
  setRelatedParam (state, param) {
    state.relatedParam = param;
  },
  setSelectedItemId (state, id) {
    state.selectedItemId = id;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}