<template>
  <div>
    <div class="imsr-content-top-container">
      <div class="imsr-container">
        <div class="imsr-page-title">
          <span>RECAP</span>
        </div>
        <div style="position: absolute; left: 0; top: 40px; line-height: 19px;" class="imsr-text-button" @click="clickBack">
          <span class="mdi mdi-arrow-left mdi-12px"></span>
          <span style="font-size: 12px;"> BACK</span>
        </div>
        
        <hr class="imsr-divider"/>
      </div>
    </div>
    <div class="imsr-content-container">
      <div class="imsr-container" style="display: flex">
        <div style="flex: 1; margin-right: 30px;">
          <el-table
            v-loading="loading"
            ref="cartItemTable"
            :data="checkedCartItem"
            row-key="id">
            <el-table-column
              label="IMAGE"
              width="110"
              align="center">
              <template slot-scope="scope">
                <div class="item-thumb-wrap" style="width: 90px; height: 90px; display: flex; align-items: center;">
                  <img :src="`${$resizeServer}92-${scope.row.itemRepImg.fileKey}`" style="max-width: 90px; min-width: 90px;"/>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="ITEM NO."
              prop="cartItem.itemNo"
              align="center"
              width="150">
            </el-table-column>
            <el-table-column
              label="CATEGORY"
              width="180"
              align="center">
              <template slot-scope="scope">
                <div class="style-item-category">{{scope.row.category_name}}</div>
              </template>
            </el-table-column>
            <el-table-column
              label="TITLE"
              prop="cartItem.title"
              align="center">
            </el-table-column>
            <el-table-column
              label="FULL DESCRIPTION"
              prop="cartItem.info"
              width="200"
              align="center">
            </el-table-column>
            <el-table-column
              label="REQUEST"
              width="300"
              align="center">
              <template slot-scope="scope">
                <div style="text-align: center; margin: 8px 0;" v-for="code in scope.row.requestCodes" :key="code.code">
                  {{code.desc}}
                </div>
                <div style="white-space: pre-wrap; text-align: left;">{{ scope.row.comment }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="width: 370px;">
          <div style="font-weight: bold; padding: 14px 0; font-size: 12px; line-height: 20px;">RECAP INFO</div>
          <hr class="imsr-divider"/>
          <div style="text-align: left; padding: 15px 0; font-size: 12px;">
            <el-row style="padding: 7px 0;">
              <el-col :span="10" style="font-weight: bold;">COMPANY</el-col>
              <el-col :span="14">{{userInfo.company || '-' }}</el-col>
            </el-row>
            <el-row style="padding: 7px 0;">
              <el-col :span="10" style="font-weight: bold;">MEMBER</el-col>
              <el-col :span="14">{{userInfo.firstName}} {{userInfo.lastName}}</el-col>
            </el-row>
            <el-row style="padding: 7px 0;">
              <el-col :span="10" style="font-weight: bold;">DUE DATE</el-col>
              <el-col :span="14">-</el-col>
            </el-row>
          </div> 
          <el-button class="imsr-button" style="width: 100%;" @click="dueDatedialogVisible = true">REQUEST NOW</el-button>
        </div>
      </div>
    </div>
    <el-dialog
      title="SET A DUE DATE"
      :visible.sync="dueDatedialogVisible"
      :close-on-click-modal="false"
      :show-close="false"
      @close="dueDateClose"
      width="320px">
      <template slot="title">
        <div style="text-align: left; font-weight: bold; font-size: 18px; padding: 10px 10px 0 10px;">
          SET A DUE DATE
        </div>
      </template>
      <el-form ref="dueDateForm" :model="recapForm" label-position="left" :rules="dueDateRule">
        <el-form-item label="" label-width="0px" style="margin: 0;" prop="dueDate">
          <el-date-picker
            style="width: 100%;"
            v-model="recapForm.dueDate"
            type="date"
            format="yyyy.MM.dd"
            value-format="timestamp"
            placeholder="0000.00.00">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="imsr-text-button" @click="setDueDate(false)">SKIP</el-button>
        <el-button type="primary" class="imsr-text-button" @click="setDueDate(true)">SET</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import recapService from '@/services/recapService';
import { map } from 'lodash';
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  computed: {
    ...mapGetters('auth', [
      'userInfo'
    ]),
    ...mapGetters('cart', [
      'checkedCartItem'
    ])
  },
  methods: {
    ...mapActions('auth', [
      'getBadgeInfo'
    ]),
    ...mapMutations('menu', [
      'setExtendFixedHeight'
    ]),
    ...mapMutations('cart', [
      'initChecked'
    ]),
    clickBack () {
      this.initChecked();
      this.$router.go(-1);
    },
    dueDateClose () {
      this.recapForm = {
        dueDate: 0,
        cartIds: []
      };
      this.dueDateRule = {};
    },
    setDueDate (chekedRule) {
      this.dueDatedialogVisible = false;
      if (chekedRule) {
        this.dueDateRule = {
          dueDate: [
            { required: true, message: ' ', trigger: 'blur' },
            { required: true, message: ' ', trigger: 'change' }
          ]
        };
      } else {
        this.dueDateRule = {};
      }
      this.recapForm.cartIds = map(this.checkedCartItem, item => item.id);

      this.$refs.dueDateForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          recapService.addRecap(this.recapForm)
            .then(() => {
              return this.getBadgeInfo();
            })
            .then(() => {
              this.loading = false;
              this.dueDatedialogVisible = false;
              this.$toasted.show(`   Sent Successfully.   `, {
                position: 'bottom-center',
                duration : 3000
              });
              this.$router.replace('/cart');
            })
            .catch(() => {
              this.recapForm = { dueDate: '', cartIds: [] }
              this.loading = false;
              this.$message({
                message: 'recap 등록 실패.',
                type: 'error'
              });
            });
        }
      })
      
    }
  },
  data () {
    return {
      dueDatedialogVisible: false,
      recapForm: {
        dueDate: '',
        cartIds: []
      },
      dueDateRule: {},
      loading: false
    }
  },
  mounted () {
    this.setExtendFixedHeight(75);
  },
  created () {
    if (this.checkedCartItem.length === 0) {
      this.$router.replace('/cart');
    }
  }
}
</script>

<style scoped>
.style-item-category {
  background-color: #ccc; 
  line-height: 25px; 
  font-size: 12px;
}
</style>