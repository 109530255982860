import http from './config/httpConfig';

const prefix = '/api/v1/users';

export default {
  myInfo () {
    return http.get(`${prefix}/info`);
  },
  modifyInfo (form) {
    return http.put(`${prefix}/info`, form);
  },
  getBadgeInfo () {
    return http.get(`${prefix}/badge/count`);
  },
  myStatusHistory () {
    return http.get(`${prefix}/info/history/status`);
  }
}