<template>
  <div>
    <div class="imsr-content-top-container">
      <div class="imsr-content-container">
        <div class="imsr-container">
          <div class="imsr-page-title">
            <div class="imsr-text-button left-container" @click="clickBack">
              <span class="mdi mdi-arrow-left mdi-12px"></span>
              <span style="font-size: 12px;"> BACK</span>
            </div>
            <span>LIBRARY</span>
            <div class="right-container"></div>
          </div>
          <hr class="imsr-divider"/>
          <div class="clearfix">
            <div class="item-top-button-group">
              VIEW BY 
              <!-- <span class="imsr-text-button" 
                :class="{active: !searchParam.materialType || searchParam.materialType === ''}"
                @click="changeViewType()">ALL</span> 
              <span>|</span>  -->
              <span class="imsr-text-button" 
                :class="{active: searchParam.materialType === 'ASIA'}"
                @click="changeViewType('ASIA')">ASIA</span>
              <span>|</span> 
              <span class="imsr-text-button" 
                :class="{active: searchParam.materialType === 'CAFTA'}"
                @click="changeViewType('CAFTA')">CAFTA</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="imsr-content-container" style="height: 100%;">
      <item-card-list
        ref="itemCartList"
        :loading="loading"
        :itemList="itemList"
        :listParam="listParam"
        @clickThumb="clickThumb"
        @setItemWish="setItemWish"
        @infiniteHandler="infiniteHandler">
      </item-card-list>

    </div>
  </div>
</template>

<script>
import ItemCardList from '@/components/commStyle/ItemCardList'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  components: {
    ItemCardList
  },
  computed: {
    ...mapGetters('common', [
      'pageBack'
    ]),
    ...mapGetters('menu', [
      'selectedMenu'
    ]),
    ...mapGetters('item', [
      'listParam',
      'searchParam',
      'itemList'
    ])
  },
  methods: {
    ...mapMutations('common', [
      'setPageBack'
    ]),
    ...mapMutations('menu', [
      'setExtendFixedHeight'
    ]),
    ...mapActions('item', [
      'getSearchItems'
    ]),
    ...mapMutations('item', [
      'initItemList',
      'setSearchParam',
      'setItemWish'
    ]),
    getItemListByPageNo (page) {
      return this.getSearchItems(page);
    },
    clickBack () {
      const menu = this.selectedMenu.menu
      this.$router.push(`/fabric/${menu.id}/${this.selectedMenu.subMenu.id}/library`);
    },
    changeViewType (materialType) {
      this.$refs.itemCartList.reset();
      if (this.searchParam.materialType === materialType) {
        return false;
      }
      this.loading = true;
      this.initItemList();
      this.setSearchParam({
        itemType: 'FABRIC',
        materialType
      });      
    },
    clickThumb (item) {
      this.setPageBack({
        to: 'library'
      });

      this.$router.push(`${this.$route.path}/${item.itemType}/${item.itemId}`);
    },
    initPage () {
      this.initItemList();
      const param = {
        itemType: 'FABRIC',
        materialType: 'ASIA'
      };
      if (this.$route.query.type && this.$route.query.type !== '') {
        param.materialType = this.$route.query.type;
      }
      this.setSearchParam(param);
    },
    infiniteHandler ($state) {
      this.getItemListByPageNo(this.listParam.page + 1)
        .then(() => {
          this.loading = false;
          $state.loaded();
          if (this.itemList.length === this.listParam.total) {
            $state.complete();
          }
        })
        .catch(() => {
          this.loading = false;
          $state.error();
        });
    }
  },
  data () {
    return {
      loading: true
    }
  },
  mounted () {
    this.setExtendFixedHeight(120);
  },
  created () {
    if (!(this.pageBack.isBack && this.pageBack.to === 'library')) {
      this.initPage();
    } else {
      this.loading = false;
    }
  }
}
</script>

<style scoped>
.item-top-button-group {
  float: right; 
  padding: 20px 0;
  font-size: 12px;
}

.item-top-button-group > span {
  padding-left: 13px;
}

.item-top-button-group .imsr-text-button.active {
  font-weight: 900;
  color: #232323 !important;
}
</style>