import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import router from './router'
import store from './store'
import vueMoment from 'vue-moment'
import contenteditable from 'vue-contenteditable'
import InfiniteLoading from 'vue-infinite-loading';
import CKEditor from '@ckeditor/ckeditor5-vue2';

Vue.use( CKEditor );
Vue.config.productionTip = false
Vue.use(vueMoment)
Vue.use(contenteditable)

Vue.prototype.$resizeServer = 'https://shinwon-ims-resize.s3.amazonaws.com/';
Vue.use(InfiniteLoading);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
