import http from './config/httpConfig';

const prefix = '/api/v1/wish';

export default {
  getWishList (type, param) {
    return http.get(`${prefix}/${type}/items`, {
      params: param
    })
  },
  addWishItem (id) {
    return http.post(`${prefix}/items/${id}`);
  },
  removeWishItems (itemIds) {
    return http.delete(`${prefix}/items`, {
      params: {
        itemIds: itemIds.join()
      }
    });
  }
}