import { filter } from 'lodash'
import studioService from '../../services/studioService'

const state = {
  paletteList: [],
  mockUpList: [],
  selectedMockUp: {
    objectPath: ''
  },
  selectedGraphicItem: undefined,
  selectedPatternItem: undefined,
  visualStudioItems: []
};

const getters = {
  paletteList (state) {
    return state.paletteList;
  },
  mockUpList (state) {
    return state.mockUpList;
  },
  selectedMockUp (state) {
    return state.selectedMockUp;
  },
  selectedGraphicItem (state) {
    return state.selectedGraphicItem;
  },
  selectedPatternItem (state) {
    return state.selectedPatternItem;
  },
  visualStudioItems (state) {
    return state.visualStudioItems;
  }
};

const actions = {
  addToCart ({ dispatch }, form) {
    return new Promise((resolve, reject) => {
      studioService.addToCart(form)
        .then(() => {
          return dispatch('auth/getBadgeInfo', {}, { root: true });
        })
        .then(resolve)
        .catch(reject);
    });
  }
};

const mutations = {
  initList (state) {
    state.paletteList = [];
    state.mockUpList = [];
    state.selectedGraphicItem = undefined;
    state.selectedPatternItem = undefined;
    state.selectedMockUp = {
      objectPath: ''
    };
    state.visualStudioItems = [];
  },
  addPaletteList (state, item) {
    state.paletteList.push(item);
  },
  removePaletteList (state, item) {
    state.paletteList = filter(state.paletteList, selected => selected.itemId !== item.itemId);
  },
  removeAllPaletteList (state) {
    state.paletteList = [];
  },
  setMockUpList (state, list) {
    state.mockUpList = list;
  },
  setSelectedMockUp (state, item) {
    state.selectedMockUp = item;
  },
  setSelectedGraphicItem (state, item) {
    state.selectedGraphicItem = item;
  },
  setSelectedPatternItem (state, item) {
    state.selectedPatternItem = item;
  },
  setVisualStudioItems (state, item) {
    state.visualStudioItems.push(item);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}