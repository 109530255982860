<template>
  <el-dialog
    title=""
    :visible="guideDialogVisible"
    :show-close="false"
    width="1200px">
    <div style="position: relative;">
      <div>
        <swipe class="guide-swipe" ref="swipe" @change="change" style="height: 700px;" :auto="-1" :defaultIndex="0" :continuous="false">
          <swipe-item><img src="@/assets/guide/onboarding_web_1.png" draggable="false"></swipe-item>
          <swipe-item><img src="@/assets/guide/onboarding_web_2.png"></swipe-item>
          <swipe-item><img src="@/assets/guide/onboarding_web_3.png"></swipe-item>
          <swipe-item><img src="@/assets/guide/onboarding_web_4.png"></swipe-item>
          <swipe-item><img src="@/assets/guide/onboarding_web_5.png"></swipe-item>
        </swipe>
      </div>
      <div style="position: absolute; bottom: 40px; z-index: 10; width: 100%; text-align: center;">
        <el-button class="imsr-button" style="width: 250px;" @click="clickNext" v-if="currentIdx < 4">NEXT</el-button>
        <el-button type="primary" style="width: 250px;"  v-else @click="setGuideDialogVisible(false)">GET STARTED</el-button>
     
        <div class="indicator-container" style="position: relative;">
          <div style="position: absolute; right: 50px; top: 0px; font-size: 16px; font-weight: bold; cursor: pointer;" @click="setGuideDialogVisible(false)">SKIP</div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
require('vue-swipe/dist/vue-swipe.css');
import { Swipe, SwipeItem } from 'vue-swipe';
import { mapGetters, mapMutations } from 'vuex'
export default {
  components: {
    Swipe, SwipeItem
  },
  computed: {
    ...mapGetters('common', [
      'guideDialogVisible'
    ])
  },
  methods: {
    ...mapMutations('common', [
      'setGuideDialogVisible'
    ]),
    change ($event) {
      this.currentIdx = $event;
    },
    clickNext () {
      this.$refs.swipe.goto(this.currentIdx + 1);
    }
  },
  data () {
    return {
      currentIdx: 0
    }
  }
}
</script>

<style scoped>
.indicator-container {
  margin-top: 20px;
}

.indicator-container > span {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #c4c4c4;
  border-radius: 12px;
  margin: 0 5px;
}

.indicator-container > span.active {
  background-color: #232323;
}

.guide-swipe img {
  user-drag: none; 
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
</style>