import cmmService from '@/services/cmmService';
import { assignIn, chain, sortBy } from 'lodash';

const state = {
  pageBack: {
    isBack: false,
    fromType: '',
    toType: '',
    isSearchResult: false
  },
  landing: {
    bannerList: [],
    companyList: [],
    menuList: []
  },
  interestList: ['Design', 'Trend', 'Marketing', 'Production', 'Material', 'Graphic', 'Wash'],
  contryList: [
    'Afghanistan', 'Albania', 'Algeria', 'American Samoa', 'Andorra', 'Angola', 'Anguilla', 'Antarctica', 'Antigua and Barbuda', 'Argentina', 'Armenia', 'Aruba', 'Australia', 'Austria', 'Azerbaijan',
    'Bahamas', 'Bahrain', 'Bangladesh', 'Barbados', 'Belarus', 'Belgium', 'Belize', 'Benin','Bermuda','Bhutan', 'Bolivia', 'Bosnia and Herzegovina', 'Botswana', 'Brazil', 'British Indian Ocean Territory', 
    'British Virgin Islands', 'Brunei', 'Bulgaria', 'Burkina Faso', 'Burundi',
    'Cambodia', 'Cameroon', 'Canada', 'Cape Verde', 'Cayman Islands', 'Central African Republic', 'Chad', 'Chile', 'China', 'Christmas Island','Cocos Islands', 'Colombia', 'Comoros', 'Cook Islands',
    'Costa Rica', 'Croatia', 'Cuba', 'Curacao', 'Cyprus','Czech Republic',
    'Democratic Republic of the Congo', 'Denmark', 'Djibouti', 'Dominica', 'Dominican Republic', 
    'East Timor', 'Ecuador', 'Egypt', 'El Salvador', 'Equatorial Guinea', 'Eritrea', 'Estonia', 'Ethiopia',
    'Falkland Islands', 'Faroe Islands', 'Fiji', 'Finland', 'France', 'French Polynesia',
    'Gabon', 'Gambia', 'Georgia', 'Germany', 'Ghana', 'Gibraltar', 'Greece', 'Greenland', 'Grenada', 'Guam', 'Guatemala', 'Guernsey', 'Guinea', 'Guinea-Bissau', 'Guyana',
    'Haiti', 'Honduras', 'Hong Kong', 'Hungary',
    'Iceland', 'India', 'Indonesia', 'Iran', 'Iraq', 'Ireland', 'Isle of Man', 'Israel', 'Italy', 'Ivory Coast',
    'Jamaica', 'Japan', 'Jersey', 'Jordan', 'Kazakhstan', 'Kenya', 'Kiribati', 'Kosovo', 'Kuwait', 'Kyrgyzstan',
    'Laos', 'Latvia', 'Lebanon', 'Lesotho', 'Liberia', 'Libya', 'Liechtenstein', 'Lithuania', 'Luxembourg',
    'Macau', 'Macedonia', 'Madagascar', 'Malawi', 'Malaysia', 'Maldives', 'Mali', 'Malta', 'Marshall Islands', 'Mauritania', 'Mauritius', 'Mayotte', 'Mexico', 'Micronesia', 'Moldova', 'Monaco',
    'Mongolia', 'Montenegro', 'Montserrat', 'Morocco', 'Mozambique', 'Myanmar',
    'Namibia', 'Nauru', 'Nepal', 'Netherlands', 'Netherlands Antilles', 'New Caledonia', 'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'Niue', 'North Korea', 'Northern Mariana Islands', 'Norway',
    'Oman',
    'Pakistan', 'Palau', 'Palestine', 'Panama', 'Papua New Guinea', 'Paraguay', 'Peru', 'Philippines', 'Pitcairn', 'Poland', 'Portugal', 'Puerto Rico',
    'Qatar',
    'Republic of the Congo', 'Reunion', 'Romania', 'Russia', 'Rwanda',
    'Saint Barthelemy', 'Saint Helena', 'Saint Kitts and Nevis', 'Saint Lucia', 'Saint Martin', 'Saint Pierre and Miquelon', 'Saint Vincent and the Grenadines', 'Samoa', 'San Marino', 'Sao Tome and Principe', 
    'Saudi Arabia', 'Senegal', 'Serbia', 'Seychelles', 'Sierra Leone', 'Singapore', 'Sint Maarten', 'Slovakia', 'Slovenia', 'Solomon Islands', 'Somalia', 'South Africa', 'South Korea', 'South Sudan',
    'Spain', 'Sri Lanka', 'Sudan', 'Suriname', 'Svalbard and Jan Mayen', 'Swaziland', 'Sweden', 'Switzerland', 'Syria',
    'Taiwan', 'Tajikistan', 'Tanzania', 'Thailand', 'Togo', 'Tokelau', 'Tonga', 'Trinidad and Tobago', 'Tunisia', 'Turkey', 'Turkmenistan', 'Turks and Caicos Islands', 'Tuvalu',
    'U.S. Virgin Islands', 'Uganda', 'Ukraine', 'United Arab Emirates', 'United Kingdom', 'United States', 'Uruguay', 'Uzbekistan',
    'Vanuatu', 'Vatican', 'Venezuela', 'Vietnam',
    'Wallis and Futuna', 'Western Sahara',
    'Yemen',
    'Zambia', 'Zimbabwe'
  ],
  viewSearchForm: false,
  fullImage: undefined, // 이미지 클릭 시 전체 화면으로 보여질 대상.
  fullImageWatermark: false,
  guideDialogVisible: false,
  studioGuideDialogVisible: false,
  cropDialogVisible: false,
  isShowFooter: false,
  contentPaddingBottom: 130,
  numOfRecapComment: 0,
  searchHashtag: '',
  itemDel: false,
  isFooter: false,
};

const getters = {
  interestList (state) {
    return state.interestList;
  },
  contryList (state) {
    return state.contryList;
  },
  menuList (state) {
    return chain(state.landing.menuList)
      .map(menu => {
        menu.subMenu = sortBy(menu.subMenu, 'priority');
        return menu;
      })
      .sortBy('priority')
      .value();
  },
  bannerList (state) {
    return sortBy(state.landing.bannerList, 'priority');
  },
  companyList (state) {
    return state.landing.companyList;
  },
  pageBack (state) {
    return state.pageBack;
  },
  fullImage (state) {
    return state.fullImage;
  },
  fullImageWatermark (state) {
    return state.fullImageWatermark;
  },
  viewSearchForm (state) {
    return state.viewSearchForm;
  },
  guideDialogVisible (state) {
    return state.guideDialogVisible
  },
  studioGuideDialogVisible (state) {
    return state.studioGuideDialogVisible
  },
  cropDialogVisible (state) {
    return state.cropDialogVisible
  },
  isShowFooter (state) {
    return state.isShowFooter;
  },
  contentPaddingBottom (state) {
    return state.contentPaddingBottom;
  },
  numOfRecapComment (state) {
    return state.numOfRecapComment;
  },
  searchHashtag (state) {
    return state.searchHashtag;
  },
  itemDel (state) {
    return state.itemDel;
  },
  isFooter (state) {
    return state.isFooter;
  },
};

const actions = {
  requestResetPassword (context, param) {
    return new Promise((resolve, reject) => {
      cmmService.requestResetPassword(param)
        .then(resolve)
        .catch(reject);
    });
  },
  requestToAccess (context, param) {
    return new Promise((resolve, reject) => {
      cmmService.requestToAccess(param)
        .then(resolve)
        .catch(reject);
    });
  },
  getLadingData ({ commit }) {
    return new Promise((resolve, reject) => {
      cmmService.landing()
        .then(res => {
          commit('setLandingData', res.data);
          resolve();
        })
        .catch(reject);
    })
  },
  commonNotiAction ({ commit }, cnt) {
    commit('setNotiCnt', cnt)
  }
};

const mutations = {
  setLandingData (state, data) {
    state.landing = data;
  },
  setPageBack (state, param) {
    state.pageBack = assignIn(state.pageBack, param);
  },
  clearPageBack (state) {
    state.pageBack = {
      isBack: false,
      toType: '',
      fromType: '',
      isSearchResult: false
    };
  },
  setFullImage (state, { img, watermark }) {
    state.fullImage = img;
    state.fullImageWatermark = watermark;
  },
  clearFullImage (state) {
    state.fullImage = undefined;
  },
  setViewSearchForm (state, value) {
    state.viewSearchForm = value;
  },
  setGuideDialogVisible (state, value) {
    state.guideDialogVisible = value;
  },
  setStudioGuideDialogVisible (state, value) {
    state.studioGuideDialogVisible = value;
  },
  setCropDialogVisible (state, value) {
    state.cropDialogVisible = value;
  },
  checkScroll (state, e) {
    
    const bodyHeight = document.body.offsetHeight;
    const contentsHeight = document.getElementsByTagName('main')[0].offsetHeight;

    if (e.type === 'wheel' && bodyHeight === contentsHeight) {
      state.isShowFooter = e.deltaY > 0;
    } else {
      if (window.scrollY === 0) {
        state.isShowFooter = false;
      } else {
        state.isShowFooter = (window.innerHeight + window.scrollY + 2) >= contentsHeight;
      }
    }

    
  },
  setIsShowFooter (state, value) {
    state.isShowFooter = value;
  },
  setContentPaddingBottom (state, value) {
    state.contentPaddingBottom = value;
  },
  setNotiCnt (state, cnt) {
    state.numOfRecapComment = cnt;
  },
  setSearchHashtag (state, value) {
    state.searchHashtag = value;
  },
  setItemDel (state, value) {
    state.itemDel = value;
  },
  setIsFooter (state, value) {
    state.isFooter = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
