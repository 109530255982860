<template>
  <el-dialog
    :visible="fullImageDialogVisible"
    :fullscreen="true"
    @close="handleClose">
    <div style="height: 100%; display:flex; ">
      <div ref="watermark" style="flex: 1; overflow: auto; display: flex; text-align: center; align-items: center; position: relative;">
        <div class="watermark" v-if="fullImageWatermark"
          :style="{ width: waterMarkSize.width, height: waterMarkSize.height}"></div>
        <img ref="fullImage" :src="fullImage ? fullImage.src : ''" style="margin: auto;" @load="imageLoad"/>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  computed: {
    ...mapGetters('common', [
      'fullImage',
      'fullImageWatermark',
      'fullImageDialogVisible'
    ]),
    fullImageDialogVisible () {
      return !!this.fullImage;
    }
  },
  methods: {
    ...mapMutations('common', [
      'clearFullImage'
    ]),
    imageLoad () {
    //  this.$refs.fullImage.onload = () => {
        if (this.$refs.fullImage.width > this.$refs.watermark.clientWidth) {
          this.waterMarkSize.width = this.$refs.fullImage.width + 'px';
        }
        if (this.$refs.fullImage.height > this.$refs.watermark.clientHeight) {
          this.waterMarkSize.height = this.$refs.fullImage.height + 'px';
        }
    //  }
    },
    handleClose () {
      this.waterMarkSize = {
        width: '100%',
        height: '100%'
      };
      this.clearFullImage();
    }
  },
  data () {
    return {
      waterMarkSize: {
        width: '100%',
        height: '100%'
      }
    }
  },
  mounted () {
    
  }
}
</script>

<style>
.imsr-full-image-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
}

.imsr-full-image-container > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.imsr-full-image-container > .imsr-full-image-background {
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
}

.imsr-full-image-container > .imsr-full-image {
  z-index: 110;
  display: flex;
  
}

.imsr-full-image-container > .imsr-full-image > div {
  flex: 1;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.full-image-close-btn {
  position :absolute;
  right: 10px;
  top: 10px;
  background-color: #fff;
  color: #000;
  cursor: pointer;
  padding: 5px;
}

.full-image-close-btn > span {
  color: #000;
  font-weight: bold;
}

.watermark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('~@/assets/watermark_3000.png');
}

.is-fullscreen .el-dialog__header {
  position: fixed;
  z-index: 100;
  width: 100px;
  right: 0;
}
</style>