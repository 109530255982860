<template>
  <div>
    <div class="imsr-content-top-container">
      <div class="imsr-container">
        <div class="imsr-page-title">
          <div class="imsr-text-button left-container" @click="clickBack()">
            <span class="mdi mdi-arrow-left mdi-12px"></span>
            <span style="font-size: 12px;"> BACK</span>
          </div>
          <span>MY INSPIRATION REQUEST</span>
          <div class="right-container"></div>
        </div>
        <hr class="imsr-divider"/>
      </div>
    </div>

    <div class="imsr-content-container" style="height: 100%;">
      <div class="imsr-container" v-loading="loading">
        <div style="display: flex; padding-top: 20px;">
          <div style="flex: 1; padding-right: 20px;">
            <item-add-carousel 
              style="background-color: #fff;"
              v-if="!initFlag"
              ref="imageCarousel" 
              :selectedImage="addedImages"
              :viewMode="true"
              @changeCarousel="changeCarousel">
            </item-add-carousel>
          </div>
          <div style="flex: 1;">
            <div style="padding-left: 10px; margin-bottom: 50px;">
              <item-thumb-image-select
                ref="itemThumbImageSelect"
                :currentImage="currentImage"
                :viewMode="false"
                :extenalAdd="true"
                @changeList="changeImageList"
                @clickAddButton="clickAddButton"
                @clickThumb="setCarouselActiveItem">
              </item-thumb-image-select>

              <el-form style="margin-top: 30px;" :model="itemForm" :rules="rules" ref="itemForm" label-position="left" label-width="215px">
                <el-form-item label="TITLE" prop="title">
                  <el-input v-model="itemForm.title" placeholder="" style="width: 100%;"></el-input>
                </el-form-item>
                <el-form-item label="BUYER" prop="buyer">
                  <el-input v-model="itemForm.buyer" placeholder="If wanted" style="width: 100%;"></el-input>
                </el-form-item>
                <el-form-item label="BRAND" prop="brand">
                  <el-input v-model="itemForm.brand" placeholder="If you know" style="width: 100%;"></el-input>
                </el-form-item>
                <el-form-item label="GENDER" prop="gender">
                  <el-checkbox-group v-model="itemForm.gender">
                    <el-row>
                      <el-col :span="5" style="text-align: left;">
                        <el-checkbox label="3">WOMEN</el-checkbox>
                      </el-col>
                      <el-col :span="5" style="text-align: left;">
                        <el-checkbox label="4">MEN</el-checkbox>
                      </el-col>
                      <el-col :span="5" style="text-align: left;">
                        <el-checkbox label="0">GIRLS</el-checkbox>
                      </el-col>
                      <el-col :span="5" style="text-align: left;">
                        <el-checkbox label="1">BOYS</el-checkbox>
                      </el-col>
                      <el-col :span="4" style="text-align: left;">
                        <el-checkbox label="2">JUNIORS</el-checkbox>
                      </el-col>
                    </el-row>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="SEASON" prop="season">
                  <el-checkbox-group v-model="itemForm.season">
                    <el-row>
                      <el-col :span="5" style="text-align: left;" v-for="code in seasonCodes" :key="code.value">
                        <el-checkbox :label="code.value">{{code.label}}</el-checkbox>
                      </el-col>
                    </el-row>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="HASH TAG" prop="hashtags"  :class="{'is-error': isTagError}">
                  <contenteditable class="additional-info" 
                    style="min-height: 0; text-transform: uppercase; width: 575px;"
                    tag="div" 
                    :contenteditable="true" 
                    v-model="itemForm.hashtags"
                    placeholder="#sport#pattern#sustainable (Max. 30 bytes each)"
                    @keypress.native="isOverTagLength = false">
                  </contenteditable>
                </el-form-item>
                <hr class="imsr-divider" style="margin-bottom: 30px;"/>
                <el-form-item label="REQUEST" prop="request">
                  <el-checkbox-group v-model="itemForm.request"
                    @change="changeRequestList">
                    <el-row>
                      <el-col :span="5" style="text-align: left;">
                        <el-checkbox label="Style">Style</el-checkbox>
                      </el-col>
                      <el-col :span="5" style="text-align: left;">
                        <el-checkbox label="Fabric">Fabric</el-checkbox>
                      </el-col>
                      <el-col :span="5" style="text-align: left;">
                        <el-checkbox label="Detail">Detail</el-checkbox>
                      </el-col>
                      <el-col :span="5" style="text-align: left;">
                        <el-checkbox label="Washing">Washing</el-checkbox>
                      </el-col>
                      <el-col :span="4" style="text-align: left;">
                        <el-checkbox label="Graphic">Graphic</el-checkbox>
                      </el-col>
                    </el-row>
                    <el-row style="margin-top: 10px;">
                      <el-col :span="4" style="text-align: left;">
                        <el-checkbox label="ETC">ETC</el-checkbox>
                      </el-col>
                      <el-col :span="20">
                        <el-input
                          style="margin-top: 10px;"
                          resize="none"
                          type="textarea"
                          :rows="7"
                          :disabled="commentDisabled"
                          placeholder="Leave your comment if you have"
                          v-model="itemForm.comment">
                        </el-input>
                      </el-col>
                    </el-row>
                  </el-checkbox-group>
                </el-form-item>
              </el-form>
              <el-button class="imsr-button" style="width: 100%;"
                @click="clickAddToCart">ADD TO CART</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ItemAddCarousel from '../../../../admin/src/components/item/ItemAddCarousel'
import ItemThumbImageSelect from '../../../../admin/src/components/item/ItemThumbImageSelect'
import { assignIn, differenceWith, map } from 'lodash'

export default {
  components: {
    ItemAddCarousel,
    ItemThumbImageSelect
  },
  computed: {
    ...mapGetters('auth', [
      'userInfo',
    ]),
    ...mapGetters('menu', [
      'selectedMenu'
    ]),
    ...mapGetters('common', [
      'pageBack'
    ]),
    ...mapGetters('inspiration', [
      'addedItems',
      'addedImages',
      'seasonCodes'
    ])
  },
  methods: {
    ...mapMutations('auth', [
      'setGuestInDialog'
    ]),
    ...mapMutations('common', [
      'setPageBack',
      'clearPageBack'
    ]),
    ...mapMutations('menu', [
      'setExtendFixedHeight'
    ]),
    ...mapMutations('item', [
      'clearItemInfo'
    ]),
    ...mapMutations('inspiration', [
      'initSearchParam',
      'removeRequestItem'
    ]),
    ...mapActions('inspiration', [
      'addToCart',
      'formList'
    ]),
    changeCarousel (idx) {
      this.currentImage = this.addedImages[idx];
    },
    setCarouselActiveItem (item) {
      this.$refs.imageCarousel.setActiveItem(item.name);
    },
    clickBack () {
      if (this.pageBack.to) {
        this.setPageBack({
          isBack: true,
          fromType: 'inspiration'
        });
        this.$router.go(-1);
      } else {
        this.clearPageBack();
        this.$router.push('/inspiration');
      }
    },
    clickAddToCart () {
      if ((this.itemForm.request.indexOf('ETC') > -1 || this.itemForm.request.indexOf('Additional_Request') > -1) && this.itemForm.comment === "") {
        this.$alert(this.selectedMenu.subMenu.type === 'GRAPHIC' ? 'Please fill in Additional Request' : 'Please fill in ETC comment', {
          confirmButtonText: 'OK',
          showClose: false,
          confirmButtonClass: 'imsr-button'
        });
      } else {
        if (this.userInfo.companyInfo === null && !this.userInfo.admin) {
          this.setGuestInDialog(true)
        }
        this.validate()
          .then(form => {
            this.loading = true;
            return this.addToCart(form);
          })
          .then(() => {
            this.loading = false;
            this.$alert('ADDED TO CART SUCCESSFULLY.', '', {
              confirmButtonText: 'OK',
              callback: this.clickBack,
              showClose: false
            });
          })
          .catch(err => {
            console.log('err', err)
            if (err !== 'invalid') {
              this.loading = false;
              this.$message({
                type: 'error',
                message: `Failed`
              });
            }
          });
      }
    },
    refreshImages () {
      this.$refs.itemThumbImageSelect.setImages(this.addedImages);
    },
    clickAddButton () {
      this.initSearchParam();
      this.setPageBack({
        to: 'inspiration',
        param: this.itemForm
      });
      this.$router.push('/inspiration/items');
    },
    getFormData () {
      const param = assignIn({}, this.itemForm);
      delete param.hashtags;
      param.inspirationFileIds = map(this.addedItems, item => item.id);
      return param;
    },
    checkTagLength () {
      this.itemForm.tags = [];
      const tags = this.itemForm.hashtags.split('#');
      let result = false;
      if (tags[0] !== '') {
        return false;
      }
      tags.shift();
      const pattern = /[~!@$%^&*()+|<>?:,{}]/;
      for (let i = 0; i < tags.length; i++) {
        const tag = tags[i].replace(/ /g, '');
        if (tag.length > 30 || tag.split(' ').length > 1 || pattern.test(tag)) {
          result = true;
          break;
        } else {
          this.itemForm.tags.push(`#${tag}`);
        }
      }
      return result;
    },
    validate () {
      return new Promise((resolve, reject) => {
        this.isTagError = this.checkTagLength();

        this.$refs.itemForm.validate((valid) => {
          if (!valid) {
            reject('invalid');
          }

          if (!this.isTagError) {
            resolve(this.getFormData());
          } else {
            const h = this.$createElement;
            this.$msgbox({
              title: '',
              message: h('div', null, [
                h('div', { style: 'margin-bottom: 20px; font-weight: bold; font-size: 15px;' }, 'HASHTAG ERROR'),
                h('div', null, '- Max. 30 bytes'),
                h('div', { style: 'margin: 7px 0' }, '- No space'),
                h('div', null, '- Only ‘#’ or ‘_’ available'),
              ]),
              confirmButtonText: 'OK',
              showCancelButton: false,
              showClose: false,
              confirmButtonClass: 'imsr-text-button',
              beforeClose: (action, instance, done) => {
                done();
                reject('invalid');
              }
            });
          }
        });
      });
    },
    changeRequestList () {
      this.commentDisabled = this.itemForm.request.indexOf('ETC') === -1;

      if (this.commentDisabled) {
        this.itemForm.comment = '';
      }
    },
    changeImageList (list) {
      const result = differenceWith(this.addedItems, list, (added, remove) => {
        return added.id === remove.itemId;
      });
      this.removeRequestItem(result[0].id);
    }
  },
  data () {
    const requestValidator = (rule, value, callback) => {
      if (!value || value.length === 0) {
        callback(new Error('request is empty!'));
      } else {
        callback();
      }
    };

    return {
      currentType: '',
      currentImage: {},
      loading: false,
      initFlag: false,
      itemForm: {
        title: '',
        gender: [],
        season: [],
        hashtags: '',
        request: [],
        comment: ''
      },
      isTagError: false,
      isOverTagLength: false,
      commentDisabled: true,
      rules: {
        title: [
          { required: true, message: ' ', trigger: 'blur' },
          { required: true, message: ' ', trigger: 'change' }
        ],
        request: [
          { required: true, message: ' ', trigger: 'blur' },
          { validator: requestValidator, trigger: 'blur' },
          { validator: requestValidator, message: ' ', trigger: 'change' }
        ]
      }
    }
  },
  mounted () {
    if (this.pageBack.param) {
      this.itemForm = this.pageBack.param
      this.pageBack.param = undefined
    }
    this.setExtendFixedHeight(70);
    if (!this.addedImages || this.addedImages.length === 0) {
      this.$router.replace('/inspiration');
    } else {
      this.refreshImages();
      if (!this.currentImage.id) {
        this.currentImage = this.addedImages[0];
      }
      if (this.itemForm.title === '') {
        this.itemForm.title = this.addedItems[0].dateTitle;
      }
    }
  },
  beforeDestroy () {
  }
}
</script>

<style scoped>
.additional-info:empty::before {
  content: attr(placeholder);
  width: inherit;
  height: inherit;
  color: #c9c9c9;
  display: block;
}
</style>