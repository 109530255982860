import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store'

import DefaultView from '../pages/DefaultView'
import MainView from '../pages/MainView'
import RequestToAccess from '../pages/RequestToAccess'

import MyPage from '../pages/mypage/MyPage'
import RecapHistory from '../pages/mypage/RecapHistory'
import RecapDetail from '../pages/mypage/RecapDetail'
import MyInfo from '../pages/mypage/MyInfo'
import CartList from '../pages/cart/CartItemList'
import CartRecap from '../pages/cart/CartRecap'
import WishMain from '../pages/wish/WishMain'
import WishList from '../pages/wish/WishList'
import DomainWishList from '../pages/wish/DomainWishList'
import SaveForLater from '../pages/wish/SaveForLater'
import InspirationList from '../pages/inspiration/InspirationList'
import InspirationForm from '../pages/inspiration/InspirationForm'
import InspirationItems from '../pages/inspiration/InspirationItems'
import InspirationImages from '../pages/inspiration/InspirationImages'

import StyleList from '../pages/commStyle/StyleList'
import StyleContents from '../pages/commStyle/StyleContents'
import StyleItemList from '../pages/commStyle/StyleItemList'
import Style3DView from '../pages/commStyle/Style3DView'
import GraphicItemList from '../pages/commStyle/GraphicItemList'
import StyleMockUpList from '../pages/commStyle/StyleMockUpList'
import VisualStudio from '../pages/commStyle/VisualStudio'
import VisualStudioExportList from '../pages/commStyle/VisualStudioExportList'
import VisualStudioItemDetail from '../pages/commStyle/VisualStudioItemDetail'
import StyleItemDetail from '../pages/commStyle/StyleItemDetail'
import BestSelling from '../pages/commStyle/BestSellingItems'

import FabricLibrary from '../pages/fabric/FabricLibrary'
import FabricItems from '../pages/fabric/FabricItems'

import NewArrivals from '../pages/washing/WashingNewArrivals'
import WashingSustainability from '../pages/washing/WashingSustainability'
import WashingLibrary from '../pages/washing/WashingLibrary'

import SustainabilityItems from '../pages/sustainability/SustainabilityItems'

import SearchList from '../pages/search/SearchList'

import ImsrAbout from '../pages/footer/ImsrAbout'
import ContactUs from '../pages/footer/ContactUs'
import FaqList from '../pages/footer/FaqList'
import FaqView from '../pages/footer/FaqView'
import TermsConditions from '../pages/footer/TermsConditions'
import PrivacyPolicy from '../pages/footer/PrivacyPolicy'
import DownloadApp from '../pages/footer/DownloadApp'
import MobilewebDownloadApp from '../pages/commStyle/MobilewebDownloadApp'

import NotFound from '../pages/error/NotFound.vue'

Vue.use(VueRouter)

const scrollBehavior = function (to, from, savedPosition) {
  const isBack = store.getters['common/pageBack'].isBack;
  if (savedPosition && isBack) {
    store.commit('common/setPageBack', {
      isBack: false
    }, { root: true });
    return savedPosition
  } else {
    return { x: 0, y: 0 }
  }
}

const routes = [
  {
    path: '/',
    component: DefaultView
  },
  {
    path: '/main',
    name: 'main',
    component: MainView
  }, {
    path: '/request-to-access',
    name: 'requestToAccess',
    component: RequestToAccess
  }, 

  {
    path: '/mypage',
    name: 'mypage',
    component: MyPage,
    children: [{
        path: 'recap',
        component: RecapHistory
      }, {
        path: 'info',
        component: MyInfo
    }]
  }, 
  {
    path: '/mypage/recap/:recapId',
    component: RecapDetail
  },
  {
    path: '/mypage/recap/:recapId/:itemType/:itemId',
    component: StyleItemDetail
  },
  {
    path: '/mypage/recap/:recapId/:orgItemType/:orgItemId/related/:itemType/:itemId',
    component: StyleItemDetail
  },
  {
    path: '/wish',
    component: WishMain,
    children: [{
      path: 'my',
      component: WishList
    }, {
      path: 'team',
      component: DomainWishList
    }, {
      path: 'saved',
      component: SaveForLater
    }]
  },
  {
    path: '/wish/:wishType/:itemType/:itemId',
    component: StyleItemDetail
  },
  {
    path: '/wish/:wishType/:orgItemType/:orgItemId/related/:itemType/:itemId',
    component: StyleItemDetail
  },

  {
    path: '/cart',
    name: 'cart',
    component: CartList
  },
  {
    path: '/cart/recap',
    name: 'cartRecap',
    component: CartRecap
  },
  {
    path: '/cart/:itemType/:itemId',
    component: StyleItemDetail
  },
  {
    path: '/cart/:orgItemType/:orgItemId/related/:itemType/:itemId',
    component: StyleItemDetail
  },
  
  {
    path: '/inspiration',
    component: InspirationList
  },
  {
    path: '/inspiration/form',
    component: InspirationForm
  },
  {
    path: '/inspiration/items',
    component: InspirationItems
  },
  {
    path: '/inspiration/images',
    component: InspirationImages
  },

  {
    path: '/style/:menuId/:subMenuId',
    component: StyleList
  }, {
    path: '/style/:menuId/:subMenuId/trend/:trendId',
    component: StyleContents
  }, {
    path: '/style/:menuId/:subMenuId/trend/:trendId/items',
    component: StyleItemList
  }, {
    path: '/style/:menuId/:subMenuId/trend/:trendId/item/:itemType/:itemId',
    component: StyleItemDetail
  }, {
    path: '/style/:menuId/:subMenuId/trend/:trendId/item/:orgItemType/:orgItemId/related/:itemType/:itemId',
    component: StyleItemDetail
  }, {
    path: '/style/:menuId/:subMenuId/best/items',
    component: BestSelling
  }, {
    path: '/style/:menuId/:subMenuId/best/items/:itemType/:itemId',
    component: StyleItemDetail
  }, {
    path: '/style/:menuId/:subMenuId/best/items/:orgItemType/:orgItemId/related/:itemType/:itemId',
    component: StyleItemDetail
  },

  {
    path: '/fabric/:menuId/:subMenuId',
    component: StyleList
  }, {
    path: '/fabric/:menuId/:subMenuId/trend/:trendId',
    component: StyleContents
  }, {
    path: '/fabric/:menuId/:subMenuId/trend/:trendId/items',
    component: StyleItemList
  }, {
    path: '/fabric/:menuId/:subMenuId/trend/:trendId/item/:itemType/:itemId',
    component: StyleItemDetail
  }, {
    path: '/fabric/:menuId/:subMenuId/trend/:trendId/item/:orgItemType/:orgItemId/related/:itemType/:itemId',
    component: StyleItemDetail
  }, {
    path: '/fabric/:menuId/:subMenuId/library',
    component: FabricLibrary
  }, {
    path: '/fabric/:menuId/:subMenuId/library/items',
    component: FabricItems
  }, {
    path: '/fabric/:menuId/:subMenuId/library/items/:itemType/:itemId',
    component: StyleItemDetail
  }, {
    path: '/fabric/:menuId/:subMenuId/library/items/:orgItemType/:orgItemId/related/:itemType/:itemId',
    component: StyleItemDetail
  },

  {
    // 그래픽 트랜드 목록
    path: '/graphic/:menuId/:subMenuId',
    component: StyleList
  }, {
    // 그래픽 트랜드 상세 
    path: '/graphic/:menuId/:subMenuId/trend/:trendId',
    component: StyleContents
  },
  {
    // 그래픽 트랜드 아이템 목록
    path: '/graphic/:menuId/:subMenuId/trend/:trendId/items',
    component: StyleItemList
  }, {
    // 그래픽 트랜드 아이템 조회 중 palette가 있는 아이템 목록
    path: '/graphic/:menuId/:subMenuId/trend/:trendId/3D',
    name: 'graphic3DView',
    component: Style3DView,
    children: [
      {
        path: 'palette',
        component: GraphicItemList
      },
      {
        path: 'mockup',
        component: StyleMockUpList
      },
      {
        path: 'studio',
        component: VisualStudio
      }
    ]
  }, {
    path: '/graphic/:menuId/:subMenuId/trend/:trendId/3D/export',
    name: 'graphic3DExport',
    component: VisualStudioExportList
  }, {
    path: '/graphic/:menuId/:subMenuId/trend/:trendId/3D/export/item/:idx',
    component: VisualStudioItemDetail
  }, {
    // 그래픽 아이템 상세 조회
    path: '/graphic/:menuId/:subMenuId/trend/:trendId/item/:itemType/:itemId',
    component: StyleItemDetail
  }, {
    path: '/graphic/:menuId/:subMenuId/trend/:trendId/item/:orgItemType/:orgItemId/related/:itemType/:itemId',
    component: StyleItemDetail
  },

  {
    path: '/washing/:menuId/:subMenuId/new_arrivals',
    component: NewArrivals
  }, {
    path: '/washing/:menuId/:subMenuId/sustainability',
    component: WashingSustainability
  }, {
    path: '/washing/:menuId/:subMenuId/library',
    component: WashingLibrary
  }, {
    path: '/washing/:menuId/:subMenuId/:subMenuName/trend/:trendId',
    component: StyleContents
  }, {
    path: '/washing/:menuId/:subMenuId/:subMenuName/trend/:trendId/items',
    component: StyleItemList
  }, {
    path: '/washing/:menuId/:subMenuId/:subMenuName/trend/:trendId/item/:itemType/:itemId',
    component: StyleItemDetail
  }, {
    path: '/washing/:menuId/:subMenuId/:subMenuName/trend/:trendId/item/:orgItemType/:orgItemId/related/:itemType/:itemId',
    component: StyleItemDetail
  },

  {
    path: '/sustainability/:menuId/:subMenuId',
    name: 'sustainability',
    component: StyleList
  }, {
    path: '/sustainability/:menuId/:subMenuId/trend/:trendId',
    name: 'sustainabilityContent',
    component: StyleContents
  }, {
    path: '/sustainability/:menuId/:subMenuId/trend/:trendId/items',
    component: StyleItemList
  }, {
    path: '/sustainability/:menuId/:subMenuId/trend/:trendId/item/:itemType/:itemId',
    component: StyleItemDetail
  }, {
    path: '/sustainability/:menuId/:subMenuId/trend/:trendId/item/:orgItemType/:orgItemId/related/:itemType/:itemId',
    component: StyleItemDetail
  }, {
    path: '/sustainability/:menuId/:subMenuId/library',
    component: SustainabilityItems
  }, {
    path: '/sustainability/:menuId/:subMenuId/library/:itemType/:itemId',
    component: StyleItemDetail
  }, {
    path: '/sustainability/:menuId/:subMenuId/library/:orgItemType/:orgItemId/related/:itemType/:itemId',
    component: StyleItemDetail
  },

  {
    path: '/search',
    component: SearchList
  },
  {
    path: '/search/:itemType/:itemId',
    component: StyleItemDetail
  },
  {
    path: '/search/:orgItemType/:orgItemId/related/:itemType/:itemId',
    component: StyleItemDetail
  },

  {
    path: '/about',
    component: ImsrAbout
  },
  {
    path: '/contact',
    component: ContactUs
  },
  {
    path: '/faq',
    component: FaqList
  },
  {
    path: '/faq/:no',
    component: FaqView
  },
  {
    path: '/privacy',
    component: PrivacyPolicy
  },
  {
    path: '/termspage',
    component: TermsConditions
  },
  {
    path: '/downloadapp',
    component: DownloadApp
  },
  {
    path: '/downloadappMobile',
    component: MobilewebDownloadApp
  },

  {
    path: '*',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior,
  routes
})

router.beforeEach((to, from, next) => {
  store.commit('common/setIsShowFooter', false);
  let ajax;
  if (store.getters['common/menuList'].length === 0) {
    ajax = store.dispatch('common/getLadingData');
  } else {
    ajax = Promise.resolve();
  }
  ajax
    .then(() => {
      if (!(to.path === '/' || to.path === '/downloadappMobile' || to.path === '/main' ||to.path === '/request-to-access') && 
          !store.state.auth.isLoggedIn) {
          if (!store.state.common.isFooter) {
            store.commit('auth/path', to.path);

            next({path: '/'});
          }
          if (store.state.common.isFooter) {
            next();
          }
        } else {
          const pathArr = to.path.split('/');
          store.dispatch('menu/setSelectedMenuById', {
              type: pathArr.length > 1 ? pathArr[1] : '',
              menuId: pathArr.length > 2 ? pathArr[2] : '',
              subMenuId: pathArr.length > 3 ? pathArr[3] : ''
            }, { 
              root: true 
          });
      
          next();
        }
    })
  
});
export default router;