import bestService from '@/services/bestService'
import { assignIn, concat, findIndex, map, take } from 'lodash'

const state = {
  bestItems: [],
  listParam: {
    page: 0,
    total: 0,
    size: 40
  }
};

const getters = {
  bestItems (state) {
    return map(state.bestItems, item => {
      if (item.category) {
        item.category_name = item.category.split('_').join(' ');
      }
      item.tags = take(item.itemTagNames, 2);
      return item;
    });
  },
  listParam (state) {
    return state.listParam;
  }
};

const actions = {
  getBestItems ({ commit, state, rootState }, page) {
    return new Promise((resolve, reject) => {
      const param = {
        page: page - 1,
        size: state.listParam.size
      };
      bestService.getBestItems(rootState.menu.selected.menu.id, param)
        .then(res => {
          commit('setBestItems', res.data.list);
          commit('setListParam', {
            page,
            total: res.data.total
          });
          resolve();
        })
        .catch(reject);
    })
  }
};

const mutations = {
  setBestItems (state, list) {
    state.bestItems = concat(state.bestItems, list);
  },
  setListParam (state, param) {
    state.listParam = assignIn(state.listParam, param);
  },
  clearItems (state) {
    state.bestItems = [];
    state.listParam = {
      page: 0,
      total: 0,
      size: 40
    };
  },
  setItemWish (state, { id, wish }) {
    const idx = findIndex(state.bestItems, item => item.itemId === id);
    const item = state.bestItems[idx];
    state.bestItems[idx] = assignIn(item, {
      addedWish: wish,
      wishTotal: wish ? item.wishTotal + 1 : item.wishTotal - 1
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}