import { find } from 'lodash'

const state = {
  extendFixedHeight: 0,
  selected: {
    menu: {},
    subMenu: {},
    type: ''
  }
}

const getters = {
  selectedMenu (state) {
    return state.selected;
  },
  extendFixedHeight (state) {
    return state.extendFixedHeight;
  }
}

const actions = {
  setSelectedMenuById ({ rootState, commit }, { menuId, subMenuId, type }) {
    const menu = find(rootState.common.landing.menuList, menu => menu.id == menuId);
    if (!menu) {
      commit('setSelectedMenu', {
        type,
        menu: {
          name: menuId
        },
        subMenu: subMenuId ? subMenuId : {}
      });
    } else {
      commit('setSelectedMenu', {
        menu,
        subMenu: find(menu.subMenu, sub => sub.id == subMenuId)
      });
    }
    
  }
};

const mutations = {
  setSelectedMenu (state, { menu, subMenu, type }) {
    state.selected = {
      menu,
      subMenu,
      type: type ? type : menu.type
    };
    state.extendFixedHeight = 0;
  },
  setExtendFixedHeight (state, value) {
    state.extendFixedHeight = value;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}