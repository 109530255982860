<template>
  <div>
    <div class="imsr-content-top-container">
      <div class="imsr-content-container" style="min-width: 0;">
        <div class="imsr-container">
          <div class="imsr-page-title">
            <span>ABOUT</span>
          </div>
        </div>
      </div>
    </div>
    <div class="imsr-content-container" style="height: 100%;">
      <div class="imsr-container">
        <div style="margin-bottom: 50px;">
          <img src="@/assets/footer/footer_about.png">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  methods: {
    ...mapMutations('menu', [
      'setExtendFixedHeight'
    ])
  },
  mounted () {
    this.setExtendFixedHeight(70);
  },
}
</script>

<style>

</style>