<template>
  <div>
    <div class="imsr-content-top-container">
      <div class="imsr-content-container" style="min-width: 0;">
        <div class="imsr-container">
          <div class="imsr-page-title">
            <span>SEARCH RESULT</span>
          </div>
          <hr class="imsr-divider"/>
          <div class="clearfix">
            <div class="item-top-button-group" style="float: right">
              VIEW BY 
              <span class="imsr-text-button" 
                :class="{active: !searchParam.itemType}"
                @click="changeViewType()">ALL</span> 
              <span>|</span> 
              <span class="imsr-text-button"
                :class="{active: searchParam.itemType === 'STYLE'}"
                @click="changeViewType('STYLE')">STYLE</span>
              <el-select
                v-if="searchParam.itemType === 'STYLE'"
                size="mini"
                v-model="handleGender"
                placeholder="GENDER">
                <el-option v-for="item in styleGenderList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"> 
                </el-option>
              </el-select>
              <span>|</span> 
              <span class="imsr-text-button"
                :class="{active: searchParam.itemType === 'FABRIC'}"
                @click="changeViewType('FABRIC')">FABRIC</span>
            <el-select
                v-if="searchParam.itemType === 'FABRIC'"
                size="mini"
                v-model="handleGender"
                placeholder="TYPE">
                <el-option key="ASIA" label="ASIA" value="ASIA"></el-option>
                <el-option key="CAFTA" label="CAFTA" value="CAFTA"></el-option>
              </el-select>

              <span>|</span> 
              <span class="imsr-text-button"
                :class="{active: searchParam.itemType === 'GRAPHIC'}"
                @click="changeViewType('GRAPHIC')">GRAPHIC</span>
              <el-select
                v-if="searchParam.itemType === 'GRAPHIC'"
                size="mini"
                v-model="handleGender"
                placeholder="GENDER">
                <el-option v-for="item in genderList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"> 
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="imsr-content-container">
      <item-card-list
        ref="searchItemList"
        :loading="loading"
        :itemList="searchList"
        :listParam="itemPage"
        @setItemWish="setItemWish"
        @clickThumb="clickThumb"
        @infiniteHandler="infiniteHandler">
      </item-card-list>
    </div>
  </div>
</template>

<script>
import ItemCardList from '@/components/commStyle/ItemCardList'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  components: {
    ItemCardList
  },
  watch: {
    handleGender () {
      this.changeGender()
    }
  },
  computed: {
    ...mapGetters('searchItem', [
      'searchList',
      'itemPage',
      'searchParam',
      'genderList',
      'styleGenderList'
    ]),
    ...mapGetters('common', [
      'pageBack'
    ]),
  },
  methods: {
    ...mapMutations('menu', [
      'setExtendFixedHeight'
    ]),
    ...mapMutations('common', [
      'clearPageBack',
      'setPageBack'
    ]),
    ...mapMutations('searchItem', [
      'initParam',
      'setSearchParam',
      'setItemWish'
    ]),
    ...mapActions('searchItem', [
      'getSearchList'
    ]),
    clickThumb (item) {
      this.setPageBack({
        to: 'search'
      });
      this.$router.push(`/search/${item.itemType}/${item.itemId}`);
    },
    changeViewType (type) {
      if (type === undefined) {
        this.changeAllType()
      }
      this.handleGender = undefined
      this.searchParam.gender = undefined
      this.searchParam.itemType = undefined
      if (this.searchParam.itemType === type || this.searchParam.gender === type) {
        return false;
      }

      this.loading = true;

      this.$refs.searchItemList.reset();
      this.initParam();
      if (type && this.searchParam.gender === undefined) {
        this.setSearchParam({
          keyword: this.searchParam.keyword,
          itemType: type,
          gender: undefined,
        });
      } else {
        this.setSearchParam({
          keyword: this.searchParam.keyword,
          itemType: undefined,
          gender: undefined,
        });
      }
    },
    changeAllType () {
      this.loading = true;

      this.$refs.searchItemList.reset();
      this.initParam();

      this.handleGender = undefined
      this.searchParam.gender = undefined
      this.searchParam.itemType = undefined
    },
    changeGender () {
      this.loading = true;
      this.$refs.searchItemList.reset();
      this.initParam();
      if(this.searchParam.itemType == 'FABRIC'){
          this.setSearchParam({
            keyword: this.searchParam.keyword,
            itemType: this.searchParam.itemType,
            materialType: this.handleGender,
          });
      } else {
          this.setSearchParam({
            keyword: this.searchParam.keyword,
            itemType: this.searchParam.itemType,
            gender: this.handleGender,
          });
      }
    },
    infiniteHandler ($state) {
      if (!this.searchParam.keyword || this.searchParam.keyword === '') {
        $state.loaded();
        $state.complete();
        this.loading = false;
      } else {
        this.getSearchList(this.itemPage.page + 1)
          .then(() => {
            $state.loaded();
            this.loading = false;
            
            if (this.searchList.length === this.itemPage.total) {
              $state.complete();
            }
          })
          .catch(() => {
            $state.error();
            this.loading = false;
          });
      }
    }
  },
  data () {
    return {
      loading: true,
      // value: '',
      handleGender: undefined
    }
  },
  mounted () {
    this.setExtendFixedHeight(120);
  },
  created () {
    const keyword = this.$route.query.keyword;

    if (!(this.pageBack.isBack && this.pageBack.to === 'search')) {
      this.initParam();
      this.setSearchParam({
        keyword,
        itemType: undefined,
        gender: undefined,
      });
      
    } else {
      this.loading = false;
    }
  }
}
</script>

<style>
.item-top-button-group {
  float: right; 
  padding: 20px 0;
  font-size: 12px;
}

.item-top-button-group > span {
  padding-left: 13px;
}

.item-top-button-group .imsr-text-button.active {
  font-weight: 900;
  color: #232323 !important;
}

.el-input--mini .el-input__inner {
  width: 100px;
  height: 25px;
  font-size: 12px;
  margin-left: 10px;
  padding-left: 10px;
}

.el-select .el-input .el-select__caret {
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-select-dropdown__item {
  font-size: 12px;
}

</style>