<template>
  <div>
    <div class="imsr-content-top-container">
      <div class="imsr-content-container">
        <div class="imsr-container">
          <div class="imsr-page-title">
            <span>SUSTAINABILITY</span>
          </div>
          <hr class="imsr-divider"/>
        </div>
      </div>
    </div>
    <div class="imsr-content-container" style="height: 100%;">
      <div class="imsr-container" v-loading="loading">
        <div style="display: flex; padding: 100px 0; justify-content: center;">
          <div v-for="menu in menuList" :key="menu.id" style="margin: 0 40px;">
            <div style="cursor: pointer;">
              <div class="menu-button"
                :style="{'background-image': `url(${menu.thumbPath})`}"
                @click="clickMenu(menu.recentTrendId)">
              </div>
              <span class="menu-name">{{ menu.name }}</span>
            </div>
            <hr class="imsr-divider"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import cmmService from '@/services/cmmService'

export default {
  computed: {
    ...mapGetters('menu', [
      'selectedMenu'
    ]),
  },
  methods: {
    ...mapMutations('common', [
      'setPageBack'
    ]),
    ...mapMutations('menu', [
      'setExtendFixedHeight'
    ]),
    clickMenu (trendId) {
      if (!trendId) {
        return false;
      }
      
      this.setPageBack({
        to: this.selectedMenu.type
      });
      this.$router.push(`${this.$route.path}/trend/${trendId}`);
    }
  },
  created () {
    cmmService.getWashingSubCategory(this.selectedMenu.subMenu.id)
      .then(res => {
        this.menuList = res.data;
        this.loading = false;
      });
  },
  data () {
    return {
      loading: true,
      menuList: []
    }
  },
  mounted () {
    this.setExtendFixedHeight(70);
  }
}
</script>

<style scoped>
.menu-button {
  background-position: center;
  background-repeat: no-repeat;
  background-color: #eee;
  width: 370px;
  height: 370px;
}

.menu-name {
  display: inline-block;
  margin: 50px 0;
  font-size: 16px;
  font-weight: bold;
}
</style>