<template>
  <div style="position: relative;">
    <button type="button" class="el-carousel__arrow el-carousel__arrow--left" 
      @click="$refs.flipbook.flipLeft()">
      <i class="el-icon-arrow-left"></i> 
    </button>
    <flipbook v-if="!loading" ref="flipbook" :style="{height: height + 'px'}" class="flipbook" :pages="pages"></flipbook>
    <button type="button" class="el-carousel__arrow el-carousel__arrow--right" 
      @click="$refs.flipbook.flipRight()">
      <i class="el-icon-arrow-right"></i> 
    </button>
  </div>
</template>

<script>
import Flipbook from 'flipbook-vue'
export default {
  components: {
    Flipbook
  },
  props: {
    pages: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  created () {
    const img = new Image();
    img.onload = () => {
      this.height =  (1220 * img.height) / (img.width * 2);
      this.loading = false;
    };
    img.src = this.pages[0];
  },
  data () {
    return {
      height: 0,
      loading: true
    }
  }
}
</script>

<style>
.flipbook {
  height: 744px;
}
</style>