<template>
  <div style="width: 100%; height: 100%; margin-top: -50px; margin-bottom: 30px">
    <div style="position: relative;">
      <el-carousel indicator-position="none" height="850px">
        <el-carousel-item v-for="banner in bannerList" :key="banner.no">
          <img style="width: 1620px; height: 850px;" :src="banner.filePath" :alt="banner.orgName"/>
        </el-carousel-item>
      </el-carousel>
      <!-- company logo 제거 요청 -->
      <!-- <div class="imsr-company-logo-container">
        <div class="slider">
          <div class="slide-track">
              <div v-for="(company, index) in companyImageList"
                class="slide"
                :key="company.id + '' + index" 
                @click="setSignInDialog(true)">
                <div class="brand-image"
                  :style="{'background-image': 'url(' + company.logoFilePath + ')'}">

                </div>
              </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
// import { concat, take } from 'lodash'

export default {
  computed: {
    ...mapGetters('common', [
      'bannerList',
      'companyList'
    ]),
    // companyImageList () {
    //   let list = this.companyList;
    //   while (list.length < 40) {
    //     list = concat(list, this.companyList);
    //   }
    //   return take(list, 40);
    // }
  },
  methods: {
    ...mapMutations('auth', [
      'setSignInDialog'
    ])
  },
  data () {
    return {
      
    }
  }
}
</script>

<style>

.imsr-button {
  background-color: #232323;
  color: #fff;
  border-width: 3px;
  border-color: #232323;
}

.imsr-button:hover {
  background-color: #232323;
  color: #fff;
  border-color: #98c8cc;
}

.imsr-button:focus {
  background-color: #232323;
  color: #98c8cc;
  border-color: #98c8cc;
}

.imsr-button.is-disabled,
.imsr-button.is-disabled:hover {
  background-color: #ccc;
  color: #fff;
  border-color: #ccc;
}

.imsr-white-button {
  background-color: #fff;
  border-width: 2px;
  border-color: #232323;
}

.imsr-white-button:hover {
  border-color: #98c8cc;
}

.imsr-white-button:focus {
  color: #98c8cc;
  border-color: #98c8cc;
}

.imsr-black-button {
  background-color: #232323;
  border-width: 2px;
  border-color: #232323;
  color: #fff;
}

/*
.el-dialog__header {
  padding-top: 50px;
}

.el-dialog__title {
  font-weight: bold;
  font-size: 24px;
  color: #232323;
}
*/
.el-form-item__label {
  font-weight: bold;
  font-size: 14px;
  color: #232323;
}

.imsr-company-logo-container {
  width: 100%;
  margin: 50px 0;
}

.imsr-company-logo-container .brand-image {
  width: 228px;
  height: 228px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #fff;
  border: 1px solid #d6d6d6;
}



@-webkit-keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-270px * 20));
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-270px * 20));
  }
}
.slider {
  height: 230px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}
/*
.slider::before, .slider::after {
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
  content: "";
  height: 230px;
  position: absolute;
  width: 200px;
  z-index: 2;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}
*/
.slider .slide-track {
  -webkit-animation: scroll 100s linear infinite;
          animation: scroll 100s linear infinite;
  display: flex;
  width: calc(270px * 40);
}
.slider .slide {
  height: 230px;
  width: 270px;
  
}
</style>