<template>
  <div style="display: flex; margin: 40px 0;" v-loading="loading">
    <div style="flex: 1; padding-right: 40px;">
      <el-form ref="myInfoForm" :model="viewUserInfo" :rules="viewType === 'edit' ? rules : {}" label-position="left" label-width="130px">
        <el-form-item label="NAME" :required="viewType === 'edit'">
          <el-col :span="11">
            <el-form-item prop="firstName">
              <el-input v-model="viewUserInfo.firstName" placeholder="First Name" v-if="viewType === 'edit'"></el-input>
              <span class="imsr-form-text" v-else>{{userInfo.firstName}} {{userInfo.lastName}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="2">
            <el-form-item prop="lastName">
              <el-input v-model="viewUserInfo.lastName" placeholder="Last Name" v-if="viewType === 'edit'"></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="EMAIL" prop="email">
          <span class="imsr-form-text" >{{userInfo.id}}</span>
        </el-form-item>
        <div v-if="viewType === 'edit'">
          <el-form-item style="width: 50%;" label="PASSWORD" prop="password">
            <el-input :type="passType" maxlength="18" v-model="viewUserInfo.password" placeholder="Please set a password between 6 and 18 characters.">
              <!-- <i slot="suffix" class="el-icon-view" @click="showPass"></i> -->
            </el-input>
          </el-form-item>
        </div>
        <div style="margin-bottom: 15px; margin-top: -16px; color: #FD1515; padding-left: 135px; text-align: left;" v-if="viewType === 'edit' && passwordErr">
          Please set a password between 6 and 18 characters.
        </div>
        <div v-if="viewType === 'edit'">
          <el-form-item style="width: 50%;" label="CONFIRM" prop="newPassword">
            <el-input :type="passType" maxlength="18" v-model="viewUserInfo.newPassword" placeholder="Please make sure your passwords match.">
              <!-- <i slot="suffix" class="el-icon-view" @click="showPass"></i> -->
            </el-input>
          </el-form-item>
        </div>
        <div style="margin-bottom: 15px; margin-top: -18px; color: #FD1515; padding-left: 135px; text-align: left;" v-if="viewType === 'edit' && passwordNotCurrect">
          Please make sure your passwords match.
        </div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="COMPANY" prop="company">
              <el-input v-model="viewUserInfo.company" placeholder="e.g. ABCD.Inc." v-if="viewType === 'edit'"></el-input>
              <span class="imsr-form-text" v-else>{{userInfo.company}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="DIVISION" prop="division">
              <el-input v-model="viewUserInfo.division" placeholder="e.g. Design.Dept." v-if="viewType === 'edit'"></el-input>
              <span class="imsr-form-text" v-else>{{userInfo.division}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="2">
            <el-form-item label="POSITION">
                <el-input v-model="viewUserInfo.position" placeholder="Input Your Position" v-if="viewType === 'edit'"></el-input>
                <span class="imsr-form-text" v-else>{{userInfo.position}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="COUNTRY" prop="country">
              <el-select style="width: 100%;" v-model="viewUserInfo.country" placeholder="Select Yout Country" v-if="viewType === 'edit'">
                <el-option v-for="contry in contryList" :key="contry" :label="contry" :value="contry"></el-option>
              </el-select>
              <span class="imsr-form-text" v-else>{{userInfo.country}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="ADDRESS" prop="address">
              <el-input v-model="viewUserInfo.address" placeholder="e.g. Stevens Creek Blvd, Suite 100, Cupertino, CA" v-if="viewType === 'edit'"></el-input>
              <span class="imsr-form-text" v-else>{{userInfo.address}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="2">
            <el-form-item label="ZIP CODE" prop="zipCode">
              <el-input v-model="viewUserInfo.zipCode" placeholder="00000" v-if="viewType === 'edit'"></el-input>
              <span class="imsr-form-text" v-else>{{userInfo.zipCode}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="SUB EMAIL" prop="subEmail" style="margin-bottom: 5px;">
              <el-input v-model="viewUserInfo.subEmail" placeholder="email@email.com" v-if="viewType === 'edit'"></el-input>
              <span class="imsr-form-text" v-else>{{userInfo.subEmail}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <div style="margin-bottom: 15px; color: #999; padding-left: 135px; text-align: left;" v-if="viewType === 'edit'">
          In case you lost your ID
        </div>
        <el-form-item label="INTEREST" v-if="viewType === 'edit'">
          <el-checkbox-group v-model="viewUserInfo.interestArr">
            <el-row>
              <el-col :span="6" style="text-align: left;" 
                v-for="interest in interestList"
                :key="interest">
                <el-checkbox :label="interest" :checked="viewUserInfo.interestArr.indexOf(interest) > -1">{{interest}}</el-checkbox>
              </el-col>
            </el-row>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="INTEREST" v-else>
          <el-checkbox-group v-model="userInfo.interestArr">
            <el-row>
              <el-col :span="6" style="text-align: left;" 
                v-for="interest in interestList"
                :key="interest">
                <el-checkbox :label="interest" :checked="userInfo.interestArr.indexOf(interest) > -1" disabled>{{interest}}</el-checkbox>
              </el-col>
            </el-row>
          </el-checkbox-group>
          
        </el-form-item>
        <el-form-item label="ADDITIONAL INFO" class="line">
          <contenteditable class="additional-info" style="width: 1080px;" tag="div" :contenteditable="true" v-model="viewUserInfo.extraInfo"  v-if="viewType === 'edit'"/>
          <contenteditable class="additional-info-view" style="width: 1080px;" tag="div" :contenteditable="false" v-model="userInfo.extraInfo"  v-else/>
          <!--
          <div contenteditable="true" class="additional-info" v-if="viewType === 'edit'" @input="handleInput">{{viewUserInfo.extraInfo}}</div>
          <div v-else>{{userInfo.extraInfo}}</div>
          -->
        </el-form-item>
        <hr class="imsr-divider" style="border-bottom: 1px solid #eee; margin: 20px 0;"/>
        <el-form-item label="STATUS" v-if="viewType !== 'edit'">
          <div style="text-align: left; border-bottom: 1px solid #eee;" v-for="history in stateHistory" :key="history.no">
            {{history.createDate | moment('MM.DD.YYYY HH:mm:A')}}
            <span style="display: inline-block; margin-left: 50px;" v-if="history.state === 1">Active</span>
            <span style="display: inline-block; margin-left: 50px;" v-else>Inactive</span>
          </div>
          <div style="text-align: left; border-bottom: 1px solid #eee;">
            {{userInfo.createDate | moment('MM.DD.YYYY HH:mm:A')}}
            <span style="display: inline-block; margin-left: 50px;">Requested to access</span>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div style="width: 370px;">
      <el-button style="width: 100%" class="imsr-button" @click="clickFormInfo" v-if="viewType === 'view'">EDIT</el-button>
      <div v-else>
        <el-button style="width: 100%" class="imsr-button" @click="clickChangeInfo">DONE</el-button><br/>
        <el-button style="width: 100%; margin-top: 10px;" class="imsr-white-button" @click="viewType = 'view'">CANCEL</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import usersService from '@/services/usersService'
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash';

export default {
  computed: {
    ...mapGetters('auth', [
      'userInfo'
    ]),
    ...mapGetters('common', [
      'interestList',
      'contryList'
    ])
  },
  methods: {
    ...mapActions('auth', [
      'modifyInfo',
      'getMyInfo'
    ]),
    handleInput (e) {
      this.viewUserInfo.extraInfo = e.target.innerHTML;
    },
    clickFormInfo () {
      this.viewUserInfo = cloneDeep(this.userInfo);
      this.viewType = 'edit';
    },
    clickChangeInfo () {
      if (this.viewUserInfo.password === undefined || this.viewUserInfo.password.length < 6) {
        this.passwordErr = true
      } else if (this.viewUserInfo.password !== this.viewUserInfo.newPassword || this.viewUserInfo.newPassword === '') {
        this.passwordErr = false
        this.passwordNotCurrect = true
      } else {
        this.passwordErr = false
        this.passwordNotCurrect = false
        this.$refs.myInfoForm.validate((valid) => {
          if (valid) {
            const loading = this.$loading({
              lock: true,
              text: 'Loading',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            this.viewUserInfo.interest = this.viewUserInfo.interestArr;
            this.modifyInfo(this.viewUserInfo)
              .then((res) => {
                console.log('res', res)
                loading.close();
                this.viewType = 'view';
              })
              .catch(err => {
                loading.close();
                this.$alert(`STATUS : ${err.response.status}`, 'REQUEST FAILED!', {
                  confirmButtonText: 'OK',
                  showClose: false,
                  confirmButtonClass: 'imsr-button'
                });
              });
          }
        });
        this.viewType = 'view';
      }
    },
    // showPass () {
    //   if (this.passType === "password") {
    //     this.passType = "text"
    //   } else if (this.passType === "text") {
    //     this.passType = "password"
    //   }
    // },
  },
  data () {
    const validateEmail = (rule, value, callback) => {
      const re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
      if (re.test(value)) {
        callback();
      } else {
        callback(new Error('invalid email address'));
      }
    };

    return {
      loading: true,
      viewType: 'view',
      viewUserInfo: {
        interestArr: []
      },
      stateHistory: [],
      rules: {
        firstName: [
          { required: true, message: ' ', trigger: 'blur' },
          { required: true, message: ' ', trigger: 'change' }
        ],
        lastName: [
          { required: true, message: ' ', trigger: 'blur' },
          { required: true, message: ' ', trigger: 'change' }
        ],
        password: [
          { required: true, message: ' ', trigger: 'blur' },
          { required: true, message: ' ', trigger: 'change' }
        ],
        newPassword: [
          { required: true, message: ' ', trigger: 'blur' },
          { required: true, message: ' ', trigger: 'change' }
        ],
        company : [
          { required: true, message: ' ', trigger: 'blur' },
          { required: true, message: ' ', trigger: 'change' }
        ],
        division : [
          { required: true, message: ' ', trigger: 'blur' },
          { required: true, message: ' ', trigger: 'change' }
        ],
        country : [
          { required: true, message: ' ', trigger: 'change' }
        ],
        address : [
          { required: true, message: ' ', trigger: 'blur' },
          { required: true, message: ' ', trigger: 'change' }
        ],
        zipCode : [
          { required: true, message: ' ', trigger: 'blur' },
          { required: true, message: ' ', trigger: 'change' }
        ],
        subEmail : [
          { required: true, message: ' ', trigger: 'blur' },
          { required: true, message: ' ', trigger: 'change' },
          { validator: validateEmail, message: ' ', trigger: 'blur'}
        ]
      },
      passType: 'password',
      passwordErr: false,
      passwordNotCurrect: false,
    }
  },
  mounted () {
    this.getMyInfo()
      .then(() => {
        return usersService.myStatusHistory();
      })
      .then((res) => {
        this.stateHistory = res.data.list.reverse();
        this.loading = false;
      });
  }
}
</script>

<style>
.imsr-form-text {
  float: left;
}

.additional-info {
  text-align: left;
  padding: 5px 15px;
  line-height: 30px;
  min-height: 180px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}

.additional-info:hover {
  border-color: #c0c4cc;
}

.additional-info:focus {
  border-color: #98c8cc;
  outline: none;
}

.additional-info-view {
  text-align: left; 
  line-height: 30px;
}

.additional-info-view:focus {
  outline: none;
}

.line .el-form-item__label {
  line-height: 1.5;
}
</style>