<template>
  <div class="imsr-top">
    <div class="imsr-container">
      <p class="logo"><span style="cursor: pointer;" @click="clickLogo()">INMYSHOWROOM</span></p>
      <div class="right-top-menu" v-if="isLoggedIn">
        <div @mouseleave="myInfoList = false">
          <div style="margin-right: 10px; text-align: right;">Welcome, 
            <span 
              style="font-weight: bold; text-decoration: underline; cursor: pointer;" 
              @click="clickMyPage('recap')"
              @mouseover="myInfoList = true">
              {{userInfo.firstName}}
            </span>
          </div>
          <transition name="fade">
            <div class="tool-wrap" v-if="myInfoList">
              <div class="imsr-text-button"
                style="padding-bottom: 12px;"
                @click="changeMyPageMenu('recap')">
                RECAP
              </div>
              <div class="imsr-text-button"
                style="padding-bottom: 12px;"
                @click="changeMyPageMenu('info')">
                MY INFO
              </div>
              <div class="imsr-text-button"
                @click="clickSignOut('out')">
                SIGN OUT
              </div>
            </div>
          </transition>
        </div>
        <div class="top-icon-list">
          <div>
            <span style="cursor: pointer;" class="mdi mdi-cards-heart mdi-24px top-menu" :class="{'active': selectedMenu.type === 'wish'}"
              @click="clickMenu('/wish/my')"></span>
          </div>
          <div>
            <span style="cursor: pointer;" class="mdi mdi-lightbulb-on mdi-24px top-menu" :class="{'active': selectedMenu.type === 'inspiration'}"
              @click="clickMenu('/inspiration')"></span>
          </div>
          <div v-if="badgeInfo.numOfCart > 0">
            <el-badge :value="badgeInfo.numOfCart" class="item">
              <span style="cursor: pointer;" class="mdi mdi-cart mdi-24px top-menu" :class="{'active': selectedMenu.type === 'cart'}"
                @click="clickMenu('/cart')"></span>
            </el-badge>
          </div>
          <div v-else>
            <span style="cursor: pointer;" class="mdi mdi-cart mdi-24px top-menu" :class="{'active': selectedMenu.type === 'cart'}"
              @click="clickMenu('/cart')"></span>
          </div>
        </div>
        <div style="border-right: 1px solid #eeeeee; margin: 12px;"></div>
        <div class="top-icon-list">
          <div>
            <el-badge :is-dot="numOfRecapComment > 0 ? true : false" class="item" v-if="numOfRecapComment > 0">
              <el-popover
                placement="bottom"
                trigger="click"
                v-model="notiPopover">
                <p>New Comments in RECAP list</p>
                <div style="font-weight: bold; color: #98C8CC;">
                  <span style="border-bottom: 1px solid #98C8CC; cursor: pointer;"
                    @click="clickMyPage('recap')">
                    CLICK to VIEW
                  </span> 
                </div>
                <span slot="reference"
                  class="mdi mdi-bell mdi-24px top-menu" 
                  style="cursor: pointer;">
                </span>
              </el-popover>
            </el-badge>
            <span v-else class="mdi mdi-bell mdi-24px top-menu" style="color: #ddd; cursor: pointer;"></span>
          </div>
          <span class="mdi mdi-magnify mdi-24px top-menu" :class="{'active': viewSearchForm}" style="cursor: pointer;" @click="setViewSearchForm(!viewSearchForm)"></span>
        </div>
      </div>
      <div class="right-top-menu" v-else>
        <div class="imsr-text-button" style="padding-right: 48px;" @click="setSignInDialog(true)">Sign In</div>
        <div class="imsr-text-button" :class="{active: selectedMenu.menu === 'requestToAccess'}" @click="clickRequestToAccess">Request to Access</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'ImsrTop',
  computed: {
    ...mapGetters('auth', [
      'isLoggedIn',
      'userInfo',
      'badgeInfo'
    ]),
    ...mapGetters('menu', [
      'selectedMenu'
    ]),
    ...mapGetters('common', [
      'menuList',
      'viewSearchForm',
      'numOfRecapComment',
      'setStudioGuideDialogVisible'
    ])
  },
  watch: {
    numOfRecapComment () {
      console.log('notiiii', this.numOfRecapComment)
    }
  },
  methods: {
    ...mapMutations('auth', [
      'setSignInDialog',
      'setGuestInDialog'
    ]),
    ...mapMutations('common', [
      'setViewSearchForm'
    ]),
    clickRequestToAccess () {
      if (this.selectedMenu.menu !== 'requestToAccess') {
        this.$router.push({ name: 'requestToAccess' });
      }
    },
    clickMyPage (path) {
      if (this.viewSearchForm) {
        this.setViewSearchForm(false);
      }

      this.notiPopover = false;
      this.$router.push(`/mypage/${path}`);
    },
    clickMenu (path) {
      if (this.userInfo.companyInfo === null && !this.userInfo.admin) {
        this.setGuestInDialog(true)
      } else {
          if (this.viewSearchForm) {
            this.setViewSearchForm(false);
          } 
   
          this.$router.push(path);
      }
    },
    clickLogo () {
      if (this.viewSearchForm) {
        this.setViewSearchForm(false);
      }
      if (this.$route.path !== "/style/6/15") {
        if (this.isLoggedIn) {
          const menuId = this.menuList[0].id
          const subMenuId = this.menuList[0].subMenu[0].id
          if (this.menuList) {
            this.$router.push(`/style/${menuId}/${subMenuId}`);
          }
        } else {
          if (this.$router.currentRoute.path !== '/main')
          this.$router.push('/main')
        }
      }
    },
    changeMyPageMenu (menuName) {
      this.myInfoList = false
      if (this.menu !== menuName) {
        this.myInfoList = false
        this.$router.replace(`/mypage/${menuName}`);
      }
    },
    clickSignOut (menuName) {
      this.myInfoList = false
      if (this.menu !== menuName) {
        this.$confirm('Do you want to sign out?', '', {
          confirmButtonText: 'SIGN OUT',
          cancelButtonText: 'CANCEL',
          confirmButtonClass: 'imsr-text-button',
          cancelButtonClass: 'imsr-text-button',
          showClose: false
        }).then(() => {
          this.$store.commit('auth/logOut');
        })
        .catch((err) => {
          if (err !== 'cancel') {
            this.loading = false;
            this.$message({
              message: 'Save for later 로그아웃 실패.',
              type: 'error'
            });
          }
        });
      }
    }
  },
  data () {
    return {
      notiPopover: false,
      myInfoList: false,
    }
  }
}
</script>

<style scoped>
.imsr-top {
  display: flex;
  justify-content: center;
}

.logo {
  margin: 0;
  font-weight: 700;
  font-size: 26px;
  letter-spacing: 5px;
  line-height: 54px;
  text-decoration: none;
  color: #232323;
}

.right-top-menu {
  position: absolute;
  right: 0;
  top: 0;
  line-height: 24px;
  font-size: 14px;
  z-index: 9999;
}

.right-top-menu > div {
  float: left;
  padding: 15px 0;
}

.top-icon-list > span,
.top-icon-list > div {
  padding: 0 12px;
  display: inline-block;
}

.top-icon-list .top-menu {
  color: #233223;
}

.top-icon-list .top-menu.active {
  color: #98c8cc;
}

.tool-wrap {
  width: 92px;
  background-color: #fff;
  box-sizing: border-box;
  border: 1px solid #232323;
  padding: 12px;
  margin-top: 5px;
  margin-right: 10px;
  text-align: left;
}
</style>