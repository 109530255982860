<template>
  <div>
    <div class="imsr-content-top-container">
      <div class="imsr-container" >
        <div class="imsr-page-title">
          <div class="left-container">
            <div v-if="menu === 'recap'">
              <div v-for="(size, index) in pageSizeArr" :key="size" style="display: inline-block;">
                <span v-if="index > 0" style="margin: 0 10px;">|</span>
                <span class="imsr-text-button" 
                  :class="{active: listParam.size === size}"
                  @click="clickChangePageSize(size)">
                  {{size}}
                </span>
              </div>
            </div>
          </div>
          <span>MY PAGE</span>
          <div class="right-container">
            <span class="imsr-text-button" :class="{active: menu === 'recap'}" 
              style="margin-right: 20px;"
              @click="changeMyPageMenu('recap')">
              RECAP HISTORY
            </span>
            <span class="imsr-text-button" :class="{active: menu === 'info'}"
              style="margin-right: 20px;"
              @click="changeMyPageMenu('info')">
              MY INFO
            </span>
            <span class="imsr-text-button" :class="{active: menu === 'out'}"
              @click="clickSignOut('out')">
              SIGN OUT
            </span>
          </div>
        </div>
        <hr class="imsr-divider"/>
      </div>
    </div>
    <div class="imsr-content-container">
      <div class="imsr-container">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
  name: 'MyPage',
  computed: {
    ...mapGetters('recap', [
      'listParam'
    ])
  },
  methods: {
    ...mapMutations('menu', [
      'setExtendFixedHeight'
    ]),
    changeMyPageMenu (menuName) {
      if (this.menu !== menuName) {
        this.$router.replace(`/mypage/${menuName}`);
      }
    },
    clickSignOut (menuName) {
      if (this.menu !== menuName) {
        this.$confirm('Do you want to sign out?', '', {
          confirmButtonText: 'SIGN OUT',
          cancelButtonText: 'CANCEL',
          confirmButtonClass: 'imsr-text-button',
          cancelButtonClass: 'imsr-text-button',
          showClose: false
        }).then(() => {
          this.$store.commit('auth/logOut');
        })
        .catch((err) => {
          if (err !== 'cancel') {
            this.loading = false;
            this.$message({
              message: 'Save for later 로그아웃 실패.',
              type: 'error'
            });
          }
        });
      }
    }
  },
  data () {
    return {
      pageSizeArr: [10, 30, 100],
      menu: ''
    }
  },
  mounted() {
    this.setExtendFixedHeight(70);
    const idx = this.$route.path.lastIndexOf('/');
    this.menu = this.$route.path.substring(idx + 1);
  }
}
</script>

<style scoped>
.my-page-top {
  display: flex;
}

.my-page-top > div {
  flex: 1;
}

</style>