import Vue from 'vue';
import itemService from '@/services/itemService'
import cartService from '@/services/cartService'
import { assignIn, chain, concat, filter, findIndex, map, reduce, take } from 'lodash' 

const state = {
  itemInfo: {},
  detail: {},
  genderList: {
    '0': 'GIRLS',
    '1': 'BOYS',
    '2': 'JUNIORS',
    '3': 'WOMEN',
    '4': 'MEN'
  },
  seasonCodes: {
    '1': 'SPRING',
    '2': 'SUMMER',
    '3': 'FALL',
    '4': 'WINTER'
  },
  currentType: '',
  itemList: [],
  listParam: {
    page: 0,
    size: 40,
    total: 0,
    sort: 'createDate,desc'
  },
  searchParam: {}
};

const getters = {
  itemInfo (state) {
    return state.itemInfo;
  },
  itemDetail (state) {
    return state.detail;
  },
  viewImages (state) {
    return chain(state.itemInfo.itemFiles)
        .map(item => {
          return {
            src: item.path,
            thumb92Src: `${Vue.prototype.$resizeServer}92-${item.fileKey}`,
            thumb792Src: `${Vue.prototype.$resizeServer}792-${item.fileKey}`,
            id: 'id' + item.no,
            name: 'name' + item.no,
            priority: item.priority,
            type: item.type
          }
        })
        .sortBy('priority')
        .value();
  },
  listParam (state) {
    return state.listParam;
  },
  searchParam (state) {
    return state.searchParam;
  },
  itemList (state) {
    return map(state.itemList, item => {
      if (item.category) {
        item.category_name = item.category.split('_').join(' ');
      }
      if (state.searchParam.sustainability) {
        item.tags = chain(item.itemTagNames)
          .filter(tag => tag.name.toLowerCase().indexOf('sustainability') === -1)
          .take(2)
          .value();
      } else {
        item.tags = take(item.itemTagNames, 2);
      }
      
      return item;
    });
  },
  genderList (state) {
    return state.genderList;
  }
};

const actions = {
  getItemInfo ({ commit }, { type, itemId }) {
    return new Promise((resolve, reject) => {
      itemService.getItemInfo(type.toUpperCase(), itemId)
        .then(res => {
          commit('setItemInfo', {
            info: res.data,
            type
          });
          resolve();
        })
        .catch(reject);
    })
  },
  getItemCartRequest ({ commit }, id) {
    return new Promise((resolve, reject) => {
      cartService.getItemCartRequest(id)
        .then(res => {
          commit('setRequest', res.data);
          resolve(res.data);
        })
        .catch(reject);
    });
  },
  getSearchItems ({ commit, state }, page) {
    return new Promise((resolve, reject) => {
      const param = assignIn({
        page: page - 1,
        size: state.listParam.size,
        sort: state.listParam.sort,
      }, state.searchParam);
      itemService.getItemList(param)
        .then(res => {
          commit('setItemList', res.data.list);
          commit('setListParam', {
            total: res.data.total,
            page
          })
          resolve();
        })
        .catch(reject)
    });
  }
};

const mutations = {
  clearItemInfo (state) {
    state.item = {
      itemFiles: []
    };
    state.detail = {};
  },
  setItemInfo (state, { info, type }) {
    state.itemInfo = info;
    type = type.toLowerCase();
    if (type === 'style') {
      state.detail = info.itemStyle;
    } else if (type === 'fabric') {
      state.detail = info.itemFabric;
    } else if (type === 'graphic') {
      const detail = info.itemGraphic || {fabricType: ''};
      
      if (detail && detail.gender !== '') {
        detail.gender = chain(detail.gender)
          .split(',')
          .map(item =>  state.genderList[item])
          .value();
      } else {
        detail.gender = [];
      }
      state.detail = detail;
    } else if (type === 'graphic_studio') {
      state.detail = reduce(info.itemRelatedGraphics, (prev, graphic) => {
        prev[graphic.itemGraphic.graphicType.toLowerCase()] = graphic;
        return prev;
      }, {});
    } else if (type === 'inspiration') {
      const detail = info.itemInspiration;
      if (detail) {
        if (detail.gender !== '') {
          detail.gender = chain(detail.gender)
            .split(',')
            .map(item =>  state.genderList[item])
            .value();
        } else {
          detail.gender = [];
        }
        
        if (detail.season !== '') {
          detail.season = chain(detail.season)
            .split(',')
            .map(item =>  state.seasonCodes[item])
            .value();
        } else {
          detail.season = [];
        }
        
      }
      state.detail = detail;
    }
  },
  setItemAddedCart (state, addedCart) {
    state.itemInfo.addedCart = addedCart;
  },
  initItemList () {
    state.itemList = [];
    state.listParam = {
      page: 0,
      size: 40,
      total: 0,
      sort: 'createDate,desc'
    };
    state.searchParam = {};
  },
  setItemList (state, list) {
    state.itemList = concat(state.itemList, list);
  },
  setListParam (state, info) {
    state.listParam = assignIn(state.listParam, info);
  },
  setSearchParam (state, param) {
    state.searchParam = param;
  },
  setItemWish (state, { id, wish }) {
    const idx = findIndex(state.itemList, item => item.itemId === id);
    const item = state.itemList[idx];
    state.itemList[idx] = assignIn(item, {
      addedWish: wish,
      wishTotal: wish ? item.wishTotal + 1 : item.wishTotal - 1
    });
  },
  setRequest (state, request) {
    state.itemInfo.request = request;
  },
  setItemInfoWish (state, { addedWish, userInfo }) {
    let wishUserList = state.itemInfo.wishUserList;
    
    if (addedWish) {
      wishUserList.push({
        firstName: userInfo.firstName,
        usersId: userInfo.id
      });
    } else {
      wishUserList = filter(wishUserList, user => user.usersId !== userInfo.id);
    }
    state.itemInfo = assignIn(state.itemInfo, { 
      addedWish, wishUserList 
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}