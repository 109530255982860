<template>
  <div>
    <div class="imsr-content-top-container">
      <div class="imsr-container" >
        <div class="imsr-page-title">
          <span>LIBRARY</span>
        </div>
        <hr class="imsr-divider"/>
      </div>
    </div>
    <div class="imsr-content-container">
      <div class="imsr-container" style="padding-top: 50px;">
        <el-row :gutter="24">
          <!-- <el-col :span="8" class="menu-el-col">
            <div>
              <img @click="clickLibraryMenu()" src="@/assets/library/fabric_library_all.png"/>
            </div>
            <p class="menu-title">ALL</p>
            <hr class="imsr-divider"/>
          </el-col> -->
          <el-col :span="12" class="menu-el-col">
            <div>
              <img @click="clickLibraryMenu('ASIA')" src="@/assets/library/asia.jpeg"/>
            </div>
            <p class="menu-title">ASIA</p>
            <hr class="imsr-divider"/>
          </el-col>
          <el-col :span="12" class="menu-el-col">
            <div>
              <img @click="clickLibraryMenu('CAFTA')" src="@/assets/library/cafta.jpeg" style="width: 463px; object-fit: cover; height: 336px;"/>
            </div>
            <p class="menu-title">CAFTA</p>
            <hr class="imsr-divider"/>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  computed: {
    ...mapGetters('common', [
      'pageBack'
    ])
  },
  methods: {
    ...mapMutations('common', [
      'setPageBack'
    ]),
    ...mapMutations('menu', [
      'setExtendFixedHeight'
    ]),
    clickLibraryMenu (type) {
      this.$router.push({
        path: `${this.$route.path}/items`,
        query: {
          type
        }
      });
    }
  },
  data () {
    return {
    }
  },
  mounted () {
    this.setExtendFixedHeight(60);
  }
}
</script>

<style scoped>
.menu-el-col {
  text-align: center;
}

.menu-el-col > div > img {
  cursor: pointer;
}

.menu-title {
  font-weight: bold;
  font-size: 16px;
  margin: 50px 0;
}
</style>