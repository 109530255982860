<template>
  <div v-if="!loading" v-loading="loading">
    <div class="fade-container" :style="{height: height + 'px'}">
      <button type="button" class="el-carousel__arrow el-carousel__arrow--left" 
        @click="prev">
        <i class="el-icon-arrow-left"></i> 
      </button>
      <transition-group name="fade">
        <div class="slide-item" 
          v-show="index === currentIndex"
          v-for="(image, index) in pages" :key="image"
          :style="{'background-image': `url(${image})`}">
        </div>
      </transition-group>
      <button type="button" class="el-carousel__arrow el-carousel__arrow--right" 
        @click="next">
        <i class="el-icon-arrow-right"></i> 
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pages: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  methods: {
    prev () {
      if (this.currentIndex - 1 > -1) {
        --this.currentIndex;
      } else {
        this.currentIndex = this.pages.length - 1;
      }
    },
    next () {
      if (this.currentIndex + 1 < this.pages.length) {
        ++this.currentIndex;
      } else {
        this.currentIndex = 0;
      }
    }
  },
  created () {
    const img = new Image();
    img.onload = () => {
      this.height =  (1220 * img.height) / img.width;
      this.loading = false;
    };
    img.src = this.pages[0];
  },
  data () {
    return {
      height: 0,
      loading: true,
      currentIndex: 0
    }
  }
}
</script>

<style scoped>
.fade-container {
  position: relative;
}

.slide-item {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1.5s
}

.fade-enter,
.fade-leave-to
/* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}

</style>