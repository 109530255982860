<template>
  <div v-loading="loading" :class="{'read-only': isReadOnly}">
    <ckeditor :editor="editor" v-model="editorData" :config="editorConfig" :disabled="isReadOnly" @ready="editorReady" />
  </div>
</template>

<script>
import ClassicEditor from '@xccjh/xccjh-ckeditor5-video-file-upload'
import fileUpload from '../../../services/fileUpload'
import '@xccjh/xccjh-ckeditor5-video-file-upload/build/translations/ko';

export default {
  props: {
    isReadOnly: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      default: ''
    }
  },
  watch: {
    content () {
      this.editorData = this.content
    }
  },
  methods: {
    validate () {
      if (this.editorData === '') {
        this.isError = true;
      }
      return {
        error: this.isError,
        content: this.editorData
      };
    },
    setContents () {
    },
    editorReady () {
      this.editorData = '' + this.content;
    }
  },
  data () {
    return {
      loading: false,
      editor: ClassicEditor,
      
      editorData: '',
      editorConfig: {
        language: 'ko',
        removePlugins: ['ImageCaption'],
        toolbar: {
          items: [
            'heading', '|',
            'fontfamily', 'fontsize', '|',
            'alignment', '|',
            'fontColor', 'fontBackgroundColor', '|',
            'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
            'link', '|',
            'imageUpload', 'mediaEmbed', 'blockQuote', '|',
            'outdent', 'indent', '|',
            'bulletedList', 'numberedList', 'todoList', '|',
            'code', 'codeBlock', '|',
            'insertTable', '|',
            'undo', 'redo'
          ]
        },
        alignment: {
          options: ['left', 'center', 'right', 'justify'],
        },
        image: {
          // Configure the available styles.
          styles: [
            'alignLeft', 'alignCenter', 'alignRight', 'alignBlockLeft', 'alignBlockRight',
          ],

          // Configure the available image resize options.
          // resizeOptions: [
          //   {
          //     name: 'imageResize:original',
          //     label: 'Original',
          //     value: null
          //   },
          //   {
          //     name: 'imageResize:50',
          //     label: '50%',
          //     value: '50'
          //   },
          //   {
          //     name: 'imageResize:75',
          //     label: '75%',
          //     value: '75'
          //   }
          // ],

          // You need to configure the image toolbar, too, so it shows the new style
          // buttons as well as the resize buttons.
          toolbar: [
            'imageStyle:alignBlockLeft', 'imageStyle:alignCenter', 'imageStyle:alignBlockRight',
            '|',
            'imageStyle:alignLeft', 'imageStyle:alignRight',
            // '|',
            // 'imageTextAlternative'
          ]
        },
        table: {
            toolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
        },
        fontFamily: {
          supportAllValues: true
        },
        fontSize: {
          options: [
            // 'tiny',
            // 'default',
            // 'big'
            '10pt',
            '11pt',
            '12pt',
            '14pt',
            '18pt',
            '24pt',
            '36pt'
          ]
        },
        imageUpload: (file) => {
          this.loading = true;
          return new Promise((resolve, reject) => {
            const data = new FormData()
            data.append('file', file);
            data.append('edit', true);
            data.append('type', 'IMG');
            fileUpload.upload(data)
              .then(res => {
                this.loading = false;
                resolve({
                  url: res.data.filePath
                })
              })
              .catch((e) => {
                this.loading = false;
                reject(e) 
              });
          });
        },
        videoUpload: (file) => {
          this.loading = true;
          return new Promise((resolve, reject) => {
            const data = new FormData()
            data.append('file', file);
            data.append('edit', true);
            data.append('type', 'MOV');
            fileUpload.upload(data)
              .then(res => {
                this.loading = false;
                resolve({
                  url: res.data.filePath
                })
              })
              .catch((e) => {
                this.loading = false;
                reject(e) 
              });
          });
        },
        mediaEmbed: {
        extraProviders: [
            {
                name: 'zdy',
                url: [
                    /(.*?)/,
                ],
                html: match => {
                    const src = match.input;
                    return (
      '<div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%; pointer-events: auto;">' +
      '<video controls style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" src="' + src + '">' +
      '</video>'+
      '</div>'
                    );
                }
            },
        ]
      }
      },
      
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style>
.ck-editor__editable_inline {
  min-height: 400px;
}

.read-only .ck-editor__top {
  display: none !important;
}

.read-only .ck-content {
  border: 0;
}

.imsr-style-contents-container .ck.ck-editor__main>.ck-editor__editable {
  border: 0 !important;
}
</style>

