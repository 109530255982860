<template>
  <div :class="contentPadding && 'imsr-contents-padding'" style="flex: 1;" @wheel.stop="$emit('handleWheel')">
    <div class="imsr-style-contents-container">
      <div class="imsr-style-contents-top">
        <div style="color: #999;" v-if="!publishDate">-</div>
        <div style="color: #999;" v-else>{{content.publishDate | moment('MM.DD.YYYY')}}</div>
        <div class="imsr-page-title" style="padding: 14px;">
          <span>{{content.title}}</span>
        </div>
        <div style="font-size: 20px;">
          {{content.subTitle}}
        </div>
      </div>
      <div class="imsr-style-contents" v-if="content.contentType === 'IMG' || content.contentType === 'PDFIMG'">
        <div v-for="file in content.contents" :key="file.id">
          <img :src="file.src" :alt="file.name" style="max-width: 100%;">
        </div>
      </div>
      <!--
      <div class="ck-content" v-if="content.contentType === 'EDIT'" v-html="content.contents || content.content" style="text-align: left;">
      </div>
      -->
      <editor-form ref="editorForm" :isReadOnly="true" 
        v-if="content.contentType === 'EDIT'" 
        :content="content.contents || content.content">
      </editor-form>
      <animation-form
        v-if="content.contentType === 'ANI'"
        :type="content.animationType"
        :content="content.contents || content.content">
      </animation-form>
    </div>
  </div>
</template>

<script>
import EditorForm from '../../contentsForm/EditorForm';
import AnimationForm from '../../contentsForm/AnimationForm'

export default {
  props: {
    content: {
      type: Object,
      default: () => {
        return {
          contentType: ''
        }
      }
    }
  },
  //['content'],
  components: {
    EditorForm,
    AnimationForm
  },
  computed: {
    publishDate () {
      if (this.content.publishDate) {
        return this.content.publishDate + ' 00:00:00';
      } 
      return false;
    },
  },
  methods: {
  },
  data () {
    return {
      contentPadding: false
    }
  },
  mounted () {
    if (this.$router.history.current.fullPath.indexOf('/preview/content') > -1) {
      this.contentPadding = true
    }
  }
}
</script>

<style scoped>
.imsr-style-contents-container {
  padding-top: 20px;
  padding-bottom: 170px;
}

.imsr-style-contents-top {
  font-size: 16px;
  margin-bottom: 48px;
}

.imsr-style-contents {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.imsr-contents-padding {
  padding-right: 400px;
}

</style>