import http from './config/httpConfig';

const prefix = '/api/v1/items';

export default {
  getItemInfo (type, id) {
    return http.get(`${prefix}/${type}/${id}`);
  },
  getItemList (params) {
    return http.get(`${prefix}`, {
      params
    });
  }
}