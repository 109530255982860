<template>
  <div class="item-info-container">
    <div class="item-info-row">
      <div class="item-label" style="font-size: 20px;">{{item.itemNo}}</div>
    </div>
    <div class="item-info-row">
      <div class="item-label">TREND INSIGHT</div>
      <div v-if="trendList.length > 0">
        <div class="item-value" 
          style="color: #98C8CC; text-decoration: underline; height: 30px; cursor: pointer;" 
          v-for="list in trendList" :key="list.id"
          @click="handleCick(list.id, list.showMenuId)">
          {{list.title}}
        </div>
      </div>
      <div v-else><span>-</span></div>
    </div>
    <div class="item-info-row">
      <div class="item-label">FULL DESCRIPTION</div>
      <div class="item-value">{{item.info}}</div>
    </div>
    <div class="item-info-row">
      <div class="item-label">GENDER</div>
      <div class="item-value">
        <span v-for="gender in detail.gender" :key="gender" class="gender-tag">
          {{gender}}
        </span>
      </div>
    </div>
    <div class="item-info-row">
      <div class="item-label">GRAPHIC TYPE</div>
      <div class="item-value" v-if="detail.graphicType === 'PLACEMENT'">PLACEMENT</div>
      <div class="item-value" v-if="detail.graphicType === 'ALL_OVER_PRINT'">ALL-OVER PRINT</div>
    </div>
    <div class="item-info-row">
      <div class="item-label">COLOR</div>
      <div class="item-value">
        <div style="padding: 5px 0; line-height: 35px;">
          <div v-for="color in item.itemGraphicColors" :key="color.no" style="">
            <span :style="{'background-color': color.hexCode}" style="float: left; 18px;margin-top: 11px; display: inline-block; width: 18px; height: 18px; margin-right: 10px;"></span>
            <span>{{color.name}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="item-info-row">
      <div class="item-label">ADDITIONAL NOTE</div>
      <div class="item-value">{{detail.additionalNote || '-'}}</div>
    </div>
    <div class="item-info-row">
      <div class="item-label">HASHTAG</div>
      <div class="item-value">
        <span class="item-tag" v-for="(tag, index) in item.itemTagNames" :key="index">{{tag.name}}</span>
      </div>
    </div>
    <div class="item-info-row">
      <div class="item-label">RELATED STYLE ITEM NO.</div>
      <div class="item-value">
        <span v-if="related.STYLE.itemNo" class="related-item"
          @click="$emit('showRelatedItem', { type: 'style', itemId: related.STYLE.relatedItemId})">
          {{related.STYLE.itemNo}}
        </span>
        <span v-else>-</span>
      </div>
    </div>
    <div class="item-info-row">
      <div class="item-label">RELATED FABRIC ITEM NO.</div>
      <div class="item-value">
        <span v-if="related.FABRIC.itemNo" class="related-item"
          @click="$emit('showRelatedItem', { type: 'fabric', itemId: related.FABRIC.relatedItemId})">
          {{related.FABRIC.itemNo}}
        </span>
        <span v-else>-</span>
      </div>
    </div>
  </div>
</template>

<script>
import { assignIn, chain, reduce } from 'lodash'

export default {
  props: ['item'],
  computed: {
    detail () {
      const detail = this.item.itemGraphic;
      const genderList = {
        '0': 'GIRLS',
        '1': 'BOYS',
        '2': 'JUNIORS',
        '3': 'WOMEN',
        '4': 'MEN'
      };
      detail.gender = chain(detail.gender)
        .split(',')
        .map(item =>  genderList[item])
        .value();

      return detail;
    },
    related () {
      const related = {
        STYLE: {},
        FABRIC: {}
      };
      const itemRelated = reduce(this.item.itemRelated, (prev, curr) => {
        prev[curr.relatedType] = curr;
        return prev;
      }, {});
      return assignIn(related, itemRelated);
    } 
  },
  methods: {
    handleCick (id, showMenuId) {
      this.$router.push(`/graphic/12/${showMenuId}/trend/${id}`)
    }
  },
  data () {
    return {
      trendList: []
    }
  },
  mounted () {
    this.trendList = this.trendList.concat(this.item.itemGraphic.trendList)
  }
}
</script>

<style scoped>

.item-info-container .item-info-row {
  display: flex;
  line-height: 40px;
  margin: 2px 0;
}

.item-info-container .item-info-row .item-label {
  width: 215px;
  padding-right: 12px;
  font-weight: bold;
}

.item-info-container .item-info-row .item-value {
  flex: 1;
}

.item-tag {
  background-color: #eee;
  font-size: 12px;
  line-height: 20px;
  padding: 0 10px;
  border-radius: 10px;
  display: inline-block;
  margin-right: 10px;
}

.related-item {
  color: #98c8cc;
  cursor: pointer;
  border-bottom: 1px solid #98c8cc;
}

.gender-tag {
  display: inline-block;
  border: 1px solid #232323;
  border-radius: 10px;
  margin-right: 10px;
  line-height: 25px;
  padding: 0 10px;
}
</style>