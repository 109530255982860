import http from './config/httpConfig';

const prefix = '/api/v1/files';

export default {
  base64Upload (form) {
    return http.post(`${prefix}/base64`, form, {
      headers: { 
        'Content-Type': 'application/x-www-form-urlencoded' 
      }
    });
  }
};