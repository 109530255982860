<template>
  <div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
    <div class="imsr-container" style="width: 100%;">
      <hr class="imsr-divider"/>
    </div>
    <div class="imsr-container" style="display: flex;">
      
      <div style="flex: 1; text-align: left;">
        <img src="../../assets/logo.png" style="margin-top: 34px;"/>
      </div>
      <div style="flex: 3">
        <el-row>
          <el-col :span="6">
            <div class="footer-menu-wrap">
              <div>
                <div class="footer-title">
                  INMYSHOWROOM
                </div>
                <ul class="footer-menu">
                  <li @click="clickButton('/about')">About</li>
                  <li @click="clickButton('/contact')">Contact Us</li>
                </ul>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="footer-menu-wrap">
              <div>
                <div class="footer-title">
                  Help
                </div>
                <ul class="footer-menu">
                  <li @click="viewGuide">How to Use</li>
                  <li @click="clickButton('/faq')">FAQs</li>
                </ul>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="footer-menu-wrap">
              <div>
                <div class="footer-title">
                  Legal Info
                </div>
                <ul class="footer-menu">
                  <li>
                    <router-link to="/privacy">Privacy Policy</router-link>
                  </li>
                  <li>
                    <router-link to="/termspage">Terms & Conditions</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="footer-menu-wrap">
              <div>
                <div class="footer-title">
                  Our App
                </div>
                <ul class="footer-menu">
                  <li @click="clickButton('/downloadapp')">Download Our App</li>
                </ul>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div style="flex: 1;">
        <div class="footer-menu-wrap">
          <span style="color: #999; line-height: 14px;">©2020 Shinwon corp. All Rights Reserved</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex'
export default {
  computed: {
    ...mapGetters('auth', [
      'isLoggedIn'
    ])
  },
  methods: {
    ...mapMutations('auth', [
      'setSignInDialog'
    ]),
    ...mapMutations('common', [
      'setGuideDialogVisible',
      'setIsFooter'
    ]),
    viewGuide () {
       this.setGuideDialogVisible(true);
    },
    clickButton (path) {
      if (!this.isLoggedIn) {
        this.setIsFooter(true)
      }
      this.$router.push(path);
    }
  }
}
</script>

<style>
.footer-menu-wrap {
  text-align: center;
  font-size: 12px;
  padding-top: 34px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-menu-wrap .footer-title {
  font-weight: bold;
  text-align: left;
  margin-bottom: 4px;
}

.footer-menu-wrap ul {
  margin: 0;
  padding: 0;
}

.footer-menu-wrap ul li {
  list-style: none;
  text-align: left;
  padding: 4px 0;
  cursor: pointer;
}

.footer-menu-wrap ul li a,
.footer-menu-wrap ul li a:link,
.footer-menu-wrap ul li a:visited {
  text-decoration: none;
  color: #232323;
}
</style>