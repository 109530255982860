import http from './config/httpConfig';

const prefix = '/api/v1/files';

export default {
  upload (form) {
    return http.post(`${prefix}/temp`, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
};