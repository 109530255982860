<template>
  <div>
    <div class="imsr-content-top-container">
      <div class="imsr-content-container" style="min-width: 0;">
        <div class="imsr-container">
          <div class="imsr-page-title">
            <div class="imsr-text-button left-container" @click="clickBack">
              <span class="mdi mdi-arrow-left mdi-12px"></span>
              <span> BACK</span>
            </div>
            <span>FAQS</span>
            <div class="right-container"></div>
          </div>
          <hr class="imsr-divider"/>
        </div>
      </div>
    </div>
    <div class="imsr-content-container" style="height: 100%;" v-loading="loading">
      <div class="imsr-container" style="padding-top: 30px;" >

        <el-form ref="faqForm" :model="faq" label-position="left" label-width="130px">
          <el-form-item label="TITLE" prop="title">
            <span class="imsr-form-text">{{faq.title}}</span>
          </el-form-item>
          <el-form-item label="CONTENT" prop="content">
            <editor-form
            :isReadOnly="true"
            :content="faq.content">              
            </editor-form>
          </el-form-item>
        </el-form>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import faqService from '@/services/faqService'
import EditorForm from '../../../../admin/src/components/trend/contentsForm/EditorForm'

export default {
  components: {
    EditorForm
  },
  computed: {
    ...mapGetters('common', [
      'pageBack'
    ])
  },
  methods: {
    ...mapMutations('menu', [
      'setExtendFixedHeight'
    ]),
    ...mapMutations('common', [
      'setPageBack'
    ]),
    clickBack () {
      if (this.pageBack.to) {
        this.setPageBack({
          isBack: true,
        });
        this.$router.go(-1);
      } else {
        this.$router.push('/faq');
      }
    }
  },
  data () {
    return {
      loading: true,
      faq: {}
    }
  },
  mounted () {
    this.setExtendFixedHeight(70);
  },
  created () {
    const faqNo = this.$route.params.no;
    faqService.getFaqInfo(faqNo)
      .then(res => {
        this.faq = res.data;
        this.loading = false;
      });
  }
}
</script>

<style scope>
.image>img {
  max-width: 100%;
  margin: 0;
}

.content-wrap {
  white-space: pre; 
  text-align: left; 
  line-height: 23px; 
  padding-top: 10px;
}

.content-wrap .table table {
  border-collapse: collapse;
}

.content-wrap .table table td {
  min-width: 2em;
  padding: .4em;
  border: 1px solid gray;
}

.ck.ck-editor__main>.ck-editor__editable {
  width: 1200px;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  border-color: var(--ck-color-base-background);
}
</style>
