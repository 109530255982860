import Vue from 'vue'
import recapService from '@/services/recapService'
import { assignIn, cloneDeep, findIndex, intersectionWith, map } from 'lodash'

const state = {
  recapList: [],
  listParam: {
    total: 0,
    page: 0,
    size: 10
  },
  recapDetail: {
    users: {},
    company: {
      name: undefined
    },
    itemList: []
  },
  recapComment: []
};

const getters = {
  recapList (state) {
    return map(state.recapList, recap => {
      if (recap.dueDate) {
        const today = new Date().getTime();
        const moment = Vue.moment(recap.dueDate);
        recap.dueDateOver = (today - parseFloat(moment.format('x'))) > 0
      }
      return recap;
    });
  },
  listParam (state) {
    return state.listParam;
  },
  recapDetail (state, getters, rootState) {
    const detail = cloneDeep(state.recapDetail);
    detail.itemList = map(detail.itemList, recap => {
      const itemType = recap.item.itemType;
      const codes = rootState.cart.requestCodes[itemType];
      recap.requestList = recap.request.split(',');
      
      const tempCode = map(recap.requestList, (request) => {
        return {
          code: request
        }
      });

      recap.requestCodes = intersectionWith(codes, tempCode, (code, temp) => {
        return code.code.toLowerCase() === temp.code.toLowerCase();
      });
      
      if (recap.item.category) {
        recap.category_name = recap.item.category.split('_').join(' ');
      }
      
      return recap;
    })
    if (detail.company && detail.company.name) {
      detail.companyName = detail.company.name;
    } else if (detail.users.company) {
      detail.companyName = detail.users.company;
    }
    return detail;
  },
  recapComment (state) {
    return state.recapComment;
  }
};

const actions = {
  getRecapList ({ commit }, page) {
    return new Promise((resolve, reject) => {
      recapService.getRecapList({
          page: page - 1,
          size: state.listParam.size,
          sort: 'createDate,desc'
        })
        .then(res => {
          commit('setRecapList', res.data.list);
          commit('setListParam', {
            total: res.data.total,
            page
          });
          resolve();
        })
        .catch(reject);
    });
  },
  getRecapInfo ({ commit }, recapId) {
    return new Promise((resolve, reject) => {
      recapService.getRecapInfo(recapId)
        .then(res => {
          commit('setRecapInfo', res.data);
          resolve();
        })
        .catch(reject);
    });
  },
  getRecapComment ({ commit }, recapId) {
    return new Promise((resolve, reject) => {
      recapService.getRecapComment(recapId)
        .then(res => {
          commit('setRecapComment', res.data);
          resolve();
        })
        .catch(reject);
    });
  },
  addRecapComment ({ dispatch }, { recapId, comment }) {
    return new Promise((resolve, reject) => {
      recapService.addRecapComment(recapId, comment)
        .then(() => {
          return dispatch('getRecapComment', recapId);
        })
        .then(resolve)
        .catch(reject);
    });
  },
  clearOtherComment ({ commit, dispatch }, recapId) {
    dispatch('auth/getBadgeInfo', {}, { root: true })
      .then(() => {
        commit('setClearOtherComment', recapId);
      })
  }
};

const mutations = {
  setRecapList (state, list) {
    state.recapList = list;
  },
  setListParam (state, param) {
    state.listParam = assignIn(state.listParam, param);
  },
  setRecapInfo (state, data) {
    state.recapDetail = data;
  },
  setRecapComment (state, data) {
    state.recapComment = data;
  },
  setClearOtherComment (state, recapId) {
    const idx = findIndex(state.recapList, recap => recap.id === Number(recapId));
    if (idx > -1) {
      state.recapList[idx] = assignIn(state.recapList[idx], {
        numOfOthersNewComment: 0
      });
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}