<template>
  <div class="item-thumb-list clearfix">
    <div v-for="img in selectedViewImage" :key="img.id"
      :class="{active: currentImage && currentImage.id === img.id}"
      :style="{'background-image': `url(${img.backgroundImage})`}"
      @click="$emit('clickThumb', img)">
      <span v-if="!(viewMode || img.doNotRemove)" class="close-btn el-icon-close" @click.stop="removeSelectedImage(img)"></span>
    </div>
    <div v-if="!viewMode && !(selectedImage.length === 1 && isSingle)"
      class="image-add-btn" 
      :class="{error: isError}"
      @click="clickAddButton">
      +
      <input type="file" 
        ref="addImgUpload" 
        style="display: none;"
        :multiple="!isSingle"
        accept="image/png,image/jpeg,video/mp4"
        @change="selectImageFile(0)">
    </div>
  </div>
</template>

<script>
import fileService from '../../services/fileUpload'
import { chain, filter, map } from 'lodash'

export default {
  //props: ['currentImage', 'viewMode', 'currentType'],
  props: {
    currentImage: {
      type: Object,
      default: function () {
        return {}
      }
    },
    viewMode: {
      type: Boolean,
      default: false
    },
    currentType: String,
    isSingle: {
      type: Boolean,
      default: false
    },
    extenalAdd: {
      type: Boolean,
      defaul: false
    }
  },
  computed: {
    selectedViewImage () {
      return map(this.selectedImage, item => {
        if (item.type === 'MOV') {
          item.backgroundImage = require('@/assets/movie_thumb.png');
        } else {
          item.backgroundImage = item.thumb92Src || item.src;
        }
        
        return item;
      });
    }
  },
  methods: {
    addSelectedImage (file, type, src) {
      this.selectedImage.push({
        file,
        orgName: file.name,
        src,
        id: 'id' + new Date().getTime(),
        name: 'name' + new Date().getTime(),
        isNew: true,
        type
      });
    },
    checkSelectedImage (idx) {
      if (this.$refs.addImgUpload.files.length > ++idx) {
        this.selectImageFile(idx);
      } else {
        this.$refs.addImgUpload.value = '';
        this.$emit('imageLoaded', this.selectedImage.length - idx);
      }
    },
    isMov (file) {
      return file.type === 'video/mp4';
    },
    selectImageFile (idx) {
      const file = this.$refs.addImgUpload.files[idx];

      if (this.isMov(file)) {
        this.isError = false;
        this.addSelectedImage(file, 'MOV');
        this.$emit('changeList', this.selectedImage);
        this.checkSelectedImage(idx);
      } else {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.isError = false;
          this.addSelectedImage(file, 'IMG', e.target.result);
          this.$emit('changeList', this.selectedImage);
          this.checkSelectedImage(idx);
        }
        reader.readAsDataURL(this.$refs.addImgUpload.files[idx]);
      }
    },
    removeSelectedImage (img) {
      this.selectedImage = filter(this.selectedImage, select => select.id !== img.id);
      this.$emit('changeList', this.selectedImage);
    },
    validate () {
      this.isError = !this.selectedImage.length > 0;
      return !this.isError;
    },
    uploadFiles () {
      const ajaxArr = chain(this.selectedImage)
        .filter(item => item.isNew)
        .map(item => {
          const form = new FormData();
          form.append('file', item.file);
          form.append('type', item.type);
          return fileService.upload(form);
        })
        .value();
      if (ajaxArr.length > 0) {
        return Promise.all(ajaxArr);
      }
      return Promise.resolve();
    },
    setImages (list) {
      this.selectedImage = list;
    },
    clickAddButton () {
      if (!this.extenalAdd) {
        this.$refs.addImgUpload.click();
      } else {
        this.$emit('clickAddButton');
      }
    }
  },
  data () {
    return {
      tempFiles: [],
      selectedImage: [],
      isError: false
    }
  }
}
</script>

<style scoped>
.item-thumb-list > div {
  border: 2px solid transparent;
  width: 78px;
  height: 78px;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: right;
  padding: 10px;
  cursor: pointer;
}

.item-thumb-list > div.active {
  border: 2px solid #232323;
}

.close-btn {
  font-size: 15px;
  display: inline-block;
  padding: 5px;
  background-color: #fff;
  border: 1px solid #232323;
  color: #232323;
}

.close-btn:hover {
  border: 1px solid #FD1515;
  color: #FD1515;
  font-weight: bold;
  cursor: pointer;
}

.item-thumb-list .image-add-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  cursor: pointer;
  border: 1px solid #232323;
  width: 79px;
  height: 79px;
}

.item-thumb-list .image-add-btn.error {
  border: 1px solid #FD1515;
}

.item-thumb-list .image-add-btn:hover {
  font-weight: bold;
}

</style>