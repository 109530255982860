<template>
  <div>
    <div class="imsr-content-top-container">
      <div class="imsr-container">
        <div class="imsr-page-title">
          <div class="imsr-text-button left-container" @click="clickBack()">
            <span class="mdi mdi-arrow-left mdi-12px"></span>
            <span style="font-size: 12px;"> BACK</span>
          </div>
          <span>ADD TO REQUEST</span>
          <div class="right-container"></div>
        </div>
        <hr class="imsr-divider"/>
      </div>
    </div>

    <div class="imsr-content-container">
      <div class="imsr-container imsr-item-list" v-loading="loading">
        <el-row :gutter="24" v-for="(row, index) in itemRows" :key="index" style="margin-top: 20px;">
          <el-col :span="6" class="imsr-style-wrap" style="padding: 20px 0;" v-for="item in row" :key="item.id">
            <div class="imsr-item-card">
              <el-card :body-style="{ padding: '0px' }" shadow="never" style="border: 0;">
                <div class="item-image-thumb"
                  :style="{'background-image': `url(${item.path})`}"
                  @click="clickThumb(item)">
                </div>
                <el-row style="padding: 10px 0;">
                  <el-col :span="14" style="line-height: 41px; text-align: left; font-size: 16px; font-weight: bold;">
                    {{ item.dateTitle }}
                  </el-col>
                  <el-col :span="10" style="text-align: right;">
                    <el-button class="imsr-white-button" @click="clickAddToRequest(item)">ADD</el-button>
                  </el-col>
                </el-row>
              </el-card>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="24" style="margin-bottom: 48px;">
          <el-col :span="6" class="imsr-style-wrap" v-for="idx in emptyCount" :key="idx">
            <empty-item-card></empty-item-card>
          </el-col>
        </el-row>
        <imsr-no-content v-if="!loading && searchList.length === 0"></imsr-no-content>
      </div>
    </div>
  </div>
</template>

<script>
import EmptyItemCard from '@/components/common/EmptyItemCard'
import ImsrNoContent from '@/components/common/ImsrNoContent';
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { chunk } from 'lodash'

export default {
  components: {
    EmptyItemCard,
    ImsrNoContent
  },
  computed: {
    ...mapGetters('common', [
      'pageBack'
    ]),
    ...mapGetters('inspiration', [
      'searchList',
      'searchParam',
      'addedItems'
    ]),
    itemRows () {
      return chunk(this.searchList, this.countPerRow);
    },
    emptyCount () {
      if (this.searchParam.page === 0) {
        return this.countPerRow;
      } else if (this.searchParam.total > this.searchList.length) {
        return (this.searchParam.total - this.searchList.length) % this.countPerRow;
      } 
      return 0;
    }
  },
  methods: {
    ...mapMutations('menu', [
      'setExtendFixedHeight'
    ]),
    ...mapMutations('common', [
      'setPageBack',
      'setFullImage'
    ]),
    ...mapMutations('inspiration', [
      'addRequestItem',
      'refreshExcludeIds'
    ]),
    ...mapActions('inspiration', [
      'searchInspirationList'
    ]),
    clickBack () {
      if (this.pageBack.to) {
        this.setPageBack({
          isBack: true,
          fromType: 'inspiration'
        });
        this.$router.go(-1);
      } else {
        this.clearPageBack();
        this.$router.push('/inspiration/form');
      }
    },
    clickThumb (item) {
      this.setFullImage({
        src: item.path
      });
    },
    clickAddToRequest (item) {
      this.addRequestItem(item);
      this.clickBack();
    },
    
  },
  data () {
    return {
      countPerRow: 4,
      loading: true
    }
  },
  mounted () {
    this.setExtendFixedHeight(70);
    if (this.addedItems.length > 0) {
      this.searchInspirationList(1)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    } else {
      this.$router.replace('/inspiration');
    }    
  }
}
</script>
<style scoped>
.imsr-item-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.imsr-item-list .imsr-style-wrap {
  display: flex;
  justify-content: center;
}

.imsr-style-wrap .item-image-thumb {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 370px;
  height: 370px;
  cursor: pointer;
}
</style>