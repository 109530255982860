<template>
  <div>
    <div class="imsr-content-top-container">
      <div class="imsr-container">
        <div class="imsr-page-title">
          <div class="imsr-text-button left-container" @click="clickBack">
            <span class="mdi mdi-arrow-left mdi-12px"></span>
            <span style="font-size: 12px;"> BACK</span>
          </div>
          <span>ITEM DETAIL</span>
          <div class="right-container"></div>
        </div>
        <hr class="imsr-divider"/>
      </div>
    </div>

    <div class="imsr-content-container" style="height: 100%;">
      <div class="imsr-container" v-loading="loading">
        <div style="display: flex; padding-top: 20px;">
          <div style="flex: 1; padding-right: 20px;">
            <div class="visual-studio-image"
              :style="{'background-image': `url(${item.src})`}">
            </div>
          </div>
          <div style="flex: 1;" class="item-detail-container">
            <div class="row">
              <span class="item-category">GRAPHIC</span>
            </div>
            <div v-if="placement" class="row item-title">{{this.item['graphic'].itemNo}}</div>
            <div v-if="all_over_print" class="row item-title">{{this.item['pattern'].itemNo}}</div>
            <div class="row" style="font-size: 16px;">
              Made at {{ item.date | moment('MM.DD.YYYY HH:mm:A')}}
            </div>
            <div v-if="placement">
              <hr class="imsr-divider" style="margin: 20px 0;"/>
              <visual-studio-graphic-form
                :item="placement" >
              </visual-studio-graphic-form>
            </div>
            <div v-if="all_over_print">
              <hr class="imsr-divider" style="margin: 20px 0;"/>
              <visual-studio-graphic-form
                :item="all_over_print" >
              </visual-studio-graphic-form>
            </div>
            <hr class="imsr-divider" style="margin: 20px 0;"/>
            <div style="font-weight: 900; text-align: left; line-height: 40px;">REQUEST</div>
            <el-form :model="requestForm" label-position="left" label-width="0px">
              <el-form-item>
                <el-checkbox-group v-model="requestForm.request"
                  @change="changeRequestList">
                  <el-row>
                    <el-col :span="12" style="text-align: left;" v-for="request in requestCodeList" :key="request.code">
                      <el-checkbox :label="request.code" >{{request.desc}}</el-checkbox>
                    </el-col>
                  </el-row>
                </el-checkbox-group>
                <el-input
                  resize="none"
                  type="textarea"
                  :rows="3"
                  :disabled="commentDisabled"
                  placeholder="Additional request (2D CAD, 3D FORM, ETC...)"
                  v-model="requestForm.comment">
                </el-input>
              </el-form-item>
            </el-form>
            <el-button class="imsr-button" style="width: 100%;" 
              :disabled="requestForm.request.length === 0"
              @click="clickAddToCart">
              ADD TO CART
            </el-button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import studioService from '@/services/studioService'
import fileService from '@/services/fileService'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { assignIn, reduce } from 'lodash'

import VisualStudioGraphicForm from '@/components/commStyle/VisualStudioGraphicForm'

export default {
  components: {
    VisualStudioGraphicForm
  },
  computed: {
    ...mapGetters('auth', [
      'userInfo'
    ]),
    ...mapGetters('cart', [
      'requestAllCodes'
    ]),
    ...mapGetters('visualStudio', [
      'selectedMockUp',
      'visualStudioItems'
    ]),
    requestCodeList () {
      return this.requestAllCodes.GRAPHIC;
    },
    title () {
      const title = reduce(['graphic', 'pattern'], (prev, type) => {
        if (this.item[type]) {
          prev.push(this.item[type].itemNo);
        }
        return prev;
      }, []);
      return title.join(' ');
    }
  },
  methods: {
    ...mapMutations('auth', [
      'setGuestInDialog'
    ]),
    ...mapMutations('menu', [
      'setExtendFixedHeight'
    ]),
    ...mapActions('visualStudio', [
      'addToCart'
    ]),
    clickBack () {
      this.$router.go(-1);
    },
    getGraphicInfo (item) {
      const ids = reduce(['graphic', 'pattern'], (prev, type) => {
        if (item[type]) {
          prev.push(item[type].itemId);
        }
        return prev;
      }, []);

      studioService.getGraphicInfo(ids.join())
        .then(res => {
          res.data.list.forEach(item => {
            this[item.itemGraphic.graphicType.toLowerCase()] = item;
          });
        })
        .finally(() => {
          this.loading = false;
        });

      this.requestForm = assignIn(this.requestForm, {
        mockupId: this.selectedMockUp.id,
        color: this.item.data.color,
        renderData: JSON.stringify(this.item.data),
        graphicItemIds: ids
      });
    },
    changeRequestList () {
      if (this.requestForm.request.indexOf('Additional_Request') > -1) {
        this.commentDisabled = false;
      } else {
        this.commentDisabled = true;
        this.requestForm.comment = ''
      }
    },
    clickAddToCart () {
      if (this.requestForm.request.indexOf('Additional_Request') > -1 && this.requestForm.comment === "") {
        this.$alert('Please fill in Additional Request', {
          confirmButtonText: 'OK',
          showClose: false,
          confirmButtonClass: 'imsr-button'
        });
      } else {
        if (this.userInfo.companyInfo === null && !this.userInfo.admin) {
          this.setGuestInDialog(true)
        } else {
          this.requestForm.title = this.title;
          this.loading = true;
          const form = new FormData();
          form.append('file', this.item.src);
          form.append('fileName', this.title.split(' ').join('_') + '.jpeg');
          form.append('type', 'IMG');
          fileService.base64Upload(form)
            .then(res => {
              this.requestForm = assignIn(this.requestForm, {
                tempFiles: [res.data]
              });
              return this.addToCart(this.requestForm);
            })
            .then(() => {
              this.loading = false;
              this.$alert('ADDED TO CART SUCCESSFULLY.', '', {
                confirmButtonText: 'OK',
                callback: this.clickBack,
                showClose: false
              });
            })
            .catch(() => {
              this.loading = false;
              this.$message({
                type: 'error',
                message: `Failed`
              });
            })
        }
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    if (to.path.indexOf('/3D/export') === -1) {
      this.$confirm('Leaving this page will discard all your exported items. <br/>Please select items and add them to cart.<br/>Are you sure to leave this page?', 'CAUTION', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: 'LEAVE',
          cancelButtonText: 'CANCEL',
          confirmButtonClass: 'imsr-text-button',
          cancelButtonClass: 'imsr-text-button',
          showClose: false
        }).then(() => {
          next();
        })
        .catch(() => {
          // next(false);
        });
    } else {
      next();
    }
  },
  data () {
    return {
      placement: undefined,
      all_over_print: undefined,
      loading: true,
      item: {},
      requestForm: {
        request: [],
        comment: ''
      },
      commentDisabled: true,
      cartData: {

      }
    }
  },
  created () {
    this.item = this.visualStudioItems[this.$route.params.idx];
    if (!this.item) {
      //
    } else {
      this.getGraphicInfo(this.item);
    }
  },
  mounted () {
    this.setExtendFixedHeight(70);
  }
}
</script>

<style scoped>
.item-detail-container {
  padding-bottom: 10px;
  text-align: left;
}

.item-detail-container  div.row {
  padding-bottom: 10px;
}

.item-title {
  font-size: 24px;
  font-weight: bold;
}

.item-category {
  display: inline-block;
  background-color: #ccc; 
  line-height: 25px; 
  font-size: 12px;
  padding: 0px;
  width: 180px;
  text-align: center;
}

.visual-studio-image {
  height: 790px;
  border: 1px solid #efefef;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
</style>