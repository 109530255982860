import http from './config/httpConfig';

const prefix = '/api/v1/trend';

export default {
  list (menuId, param) {
    return http.get(`${prefix}/menu/${menuId}`, {
      params: param
    });
  },
  getTrendInfo (trendId) {
    return http.get(`${prefix}/${trendId}`);
  },
  getTrendItems (trendId, param) {
    return http.get(`${prefix}/${trendId}/items`, {
      params: param
    })
  },
  getTrendRelatedItems (trendId, itemType, param) {
    return http.get(`${prefix}/${trendId}/items/related/${itemType}`, {
      params: param
    });
  }
}