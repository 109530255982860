<template>
  <div>
    <div class="imsr-content-top-container">
      <div class="imsr-content-container">
        <div class="imsr-container">
          <div class="imsr-page-title">
            <span>SW’S BEST SELLING</span>
          </div>
          <hr class="imsr-divider"/>
          <div style="padding: 20px 0; text-align: left; font-size: 12px;">{{ selectedMenu.menu.name }}</div> 
        </div>
      </div>
    </div>
    <div class="imsr-content-container">
      <div>
        <item-card-list
          :loading="loading"
          :itemList="bestItems"
          :listParam="listParam"
          @setItemWish="setItemWish"
          @clickThumb="clickThumb"
          @infiniteHandler="infiniteHandler">
        </item-card-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ItemCardList from '@/components/commStyle/ItemCardList'

export default {
  name: 'BestSellingItems',
  components: {
    ItemCardList
  },
  computed: {
    ...mapGetters('common', [
      'pageBack'
    ]),
    ...mapGetters('menu', [
      'selectedMenu'
    ]),
    ...mapGetters('best', [
      'bestItems',
      'listParam'
    ]),
    
  },
  methods: {
    ...mapMutations('menu', [
      'setExtendFixedHeight'
    ]),
    ...mapMutations('common', [
      'clearPageBack',
      'setPageBack'
    ]),
    ...mapMutations('best', [
      'clearItems',
      'setItemWish'
    ]),
    ...mapActions('best', [
      'getBestItems'
    ]),
    clickThumb (item) {
      this.setPageBack({
        to: this.selectedMenu.type
      });
      this.$router.push(`${this.$route.path}/${item.itemType}/${item.itemId}`);
    },
    infiniteHandler ($state) {
      this.getBestItems(this.listParam.page + 1)
        .then(() => {
          this.loading = false;
          $state.loaded();
          if (this.bestItems.length === this.listParam.total) {
            $state.complete();
          }
        })
        .catch(() => {
          this.loading = false;
          $state.error();
        });
    }
  },
  data () {
    return {
      loading: true
    }
  },
  mounted () {
    this.setExtendFixedHeight(120);
    if (this.pageBack.isBack && this.pageBack.to === this.selectedMenu.type) {
      this.loading = false;
    }
  },
  created () {
    if (!(this.pageBack.isBack && this.pageBack.to === this.selectedMenu.type)) {
      this.clearItems();
    }
  }
}
</script>

<style>

</style>