<template>
  <div class="imsr-container imsr-item-list" style="margin-top: 10px; height: 100%;" v-loading="loading" ref="scrollParentConteiner">
    <el-row :gutter="24" v-for="(row, index) in itemRows" :key="index">
      <el-col :span="6" class="imsr-style-wrap" style="padding: 20px 0;" v-for="item in row" :key="item.itemId">
        <div class="imsr-item-card">
          <el-card class="imsr-style-item" 
            :class="{ 'active': selectedItemId === item.itemId }"
            :body-style="{ padding: '0px' }" shadow="never" style="border: 0;">
            <div class="item-image-thumb" :style="{'background-image': `url(${$resizeServer}792-${item.itemRepImg.fileKey})`}"
              @click="$emit('clickThumb', item)">
            </div>
            <div class="item-style-item-desc">
              <div class="item-sample">
                <span>{{ item.category_name || '-' }}</span>
              </div>
              <span class="item-title">{{ item.title }}</span>
              <div class="primary-color" >{{ item.itemNo }}</div>
              <div class="item-style-tag-group">
                <span class="item-style-tag" v-for="tag in item.tags" :key="tag.name">{{ tag.name }}</span>
                <span class="item-style-tag" v-if="item.tags.length === 0" style="background-color: transparent;">-</span>
              </div>
              <div v-loading="wishLoading && wishLoading === item.itemId">
                <div class="item-style-favorite" v-if="item.wishTotal === 0">
                  <span class="mdi mdi-heart-outline mdi-24px" 
                    @click="clickWish(item)">
                  </span>
                  <span class="item-style-favorite-member">
                    -
                  </span>
                </div>
                <div class="item-style-favorite" v-else-if="item.addedWish">
                  <span class="mdi mdi-heart mdi-24px error-color"
                    @click="clickWish(item)">
                  </span>
                  <span class="item-style-favorite-member">
                    Added by {{userInfo.firstName}} 
                    <span v-if="item.wishTotal - 1 > 0"> and {{item.wishTotal - 1}} more</span>
                  </span>
                </div>
                <div class="item-style-favorite" v-else>
                  <span class="mdi mdi-heart mdi-24px error-color"
                    @click="clickWish(item)">
                  </span>
                  <span class="item-style-favorite-member">
                    Added by {{item.wishTotal}} more
                  </span>
                </div>
              </div>
            </div>
            <div class="imsr-divider-group" style="margin-top: 10px; height: 10px;">
              <hr class="imsr-divider imsr-style-item-divider"/>
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>
    <imsr-no-content v-if="!loading && listParam.total === 0"></imsr-no-content>
    <infinite-loading ref="infiniteLoading" spinner='default' @infinite="$emit('infiniteHandler', $event)">
      <div slot="spinner">
        <el-row :gutter="24" style="margin-bottom: 48px;">
          <el-col :span="6" class="imsr-style-wrap" v-for="idx in emptyCount" :key="idx">
            <empty-item-card></empty-item-card>
          </el-col>
        </el-row>
      </div>
      <div slot="no-more"></div>
      <div slot="no-result"></div>
      
    </infinite-loading>
    
  </div>
</template>

<script>
import ImsrNoContent from '../common/ImsrNoContent'
import EmptyItemCard from '../common/EmptyItemCard'
import wishService from '@/services/wishService';
import { mapGetters, mapMutations } from 'vuex';
import { chunk } from 'lodash';

export default {
  name: 'ItemCardList',
  components: {
    ImsrNoContent,
    EmptyItemCard
  },
  props: {
    itemList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    listParam: {
      type: Object,
      default: () => {
        return {
          total: 0,
          page: 0
        }
      }
    },
    selectedItemId: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters('auth', [
      'userInfo'
    ]),
    itemRows () {
      return chunk(this.itemList, this.countPerRow);
    },
    emptyCount () {
      if (this.listParam.page === 0) {
        return 0;
      } else if (this.listParam.total > this.itemList.length) {
        const count = this.listParam.total - this.itemList.length;
        return Math.min(count, this.countPerRow);
      } 
      return 0;
    },
  },
  methods: {
    ...mapMutations('auth', [
      'setGuestInDialog'
    ]),
    clickWish (item) {
      if (this.userInfo.companyInfo === null && !this.userInfo.admin) {
        this.setGuestInDialog(true)
      } else {
        this.wishLoading = item.itemId;
        let ajax;
        if (item.addedWish) {
          ajax = wishService.removeWishItems ([item.itemId]);
        } else {
          ajax = wishService.addWishItem(item.itemId);
        }
        ajax
          .then(() => {
            this.wishLoading = undefined;
            this.$emit('setItemWish', {
              id: item.itemId, wish: !item.addedWish
            });
          })
          .catch(() => {
            this.wishLoading = undefined;
          });
      }
    },
    reset () {
      this.$refs.infiniteLoading.stateChanger.reset();
    }
  },
  data () {
    return {
      countPerRow: 4,
      wishLoading: false
    }
  }
}
</script>

<style scoped>
.imsr-item-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.imsr-item-list .imsr-style-wrap {
  display: flex;
  justify-content: center;
}

.imsr-style-wrap .item-image-thumb {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 370px;
  height: 370px;
  cursor: pointer;
}

.imsr-item-list .item-style-item-desc {
  display: flex;
  justify-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8px 10px;
}

.imsr-item-list .item-title {
  font-size: 16px;
  font-weight: bold;
  padding: 8px;
}

.item-style-favorite > span {
  display: block;
}

.item-style-favorite > span.mdi {
  cursor: pointer;
}

.item-style-favorite .item-style-favorite-member {
  font-size: 12px;
  display: block;
  margin-top: 4px;
  line-height: 17px;
}

.imsr-divider-group {
  margin-top: 20px;
}

.imsr-style-item .imsr-style-item-divider {
  border-radius: 0;
  padding-top: 4px;
}

/* .imsr-style-item.active .imsr-style-item-divider {
  border-color: #98c8cc;
  border-bottom-width: 10px;
  padding: 0;
} */

.item-style-tag-group {
  display: flex;
  justify-items: center;
  justify-content: center;
  padding: 8px 0;
}

.item-style-tag {
  font-size: 12px;
  line-height: 18px;
  padding: 0 7px;
  margin: 3px;
  background-color: #eee;
  border-radius: 7px;
}

.imsr-style-item {
  width: 100%;
  max-width: 372px;
  
}

.imsr-item-list .item-sample > span {
  width: 200px;
  background-color: #ccc;
  text-align: center;
  display: inline-block;
  line-height: 24px;
}

</style>