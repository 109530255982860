<template>
  <div>
    <div class="imsr-content-top-container">
      <div class="imsr-container" v-loading="loading">
        <div class="imsr-page-title">
          <div class="imsr-text-button left-container" @click="clickBack">
            <span class="mdi mdi-arrow-left mdi-12px"></span>
            <span style="font-size: 12px;"> BACK</span>
          </div>
          <span>ADD INSPIRATION</span>
          <div class="right-container"></div>
        </div>
        <hr class="imsr-divider"/>
        <div style="height: 96px; display: flex; align-items: center; justify-content: space-between; text-align: left;">
          <div style="display: flex;">
            <span class="imsr-text" style="font-size: 14px; font-weight: bold; padding-right: 68px;">
              IMAGE
            </span>
            <span class="imsr-text" style="font-size: 14px; font-weight: 400; padding: 0;" v-if="this.imgFileName">
              {{ imgFileName }}
            </span>
          </div>
          <div style="display: flex;">
            <el-button class="imsr-white-button" style="width: 372px; color: #232323" @click.prevent="showFileChooser">
              UPLOAD
            </el-button>
            <el-button class="imsr-black-button" style="width: 372px;" @click="clickDone">DONE</el-button>
          </div>
        </div>
        <div class="cropped-image">
          <img :src="cropImg">
        </div>
        <transition name="fade" appear="" v-if="this.imgSrc">
          <images-crop-pop v-bind:imageSrc="this.imgSrc" @callCropImage="cropImageData" />
        </transition>
      </div>
    </div>
    <div>
    <input
      ref="input"
      type="file"
      name="image"
      accept=".jpg, .png"
      @change="setImage"
    />
  </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import inspirationService from '@/services/inspirationService'
import 'cropperjs/dist/cropper.css';
import ImagesCropPop from './ImagesCropPop.vue';

export default {
  components: {
    ImagesCropPop,
  },
  methods: {
    ...mapActions('inspiration', [
      'getInspirationList',
    ]),
    clickUpload () {
      this.$router.push('/inspiration/imagecropper')
    },
    clickBack () {
      this.$router.push('/inspiration');
    },
    clickDone() {
      this.loading = true;
      const form = new FormData();
      form.append('file', new File([this.cropBlob], this.imgName));
      form.append('type', 'IMAGE');
      inspirationService.inspirationUpload(form)
        .then(() => {
          this.loading = false;
          this.$router.push('/inspiration');
          this.getInspirationList()
        }).catch(() => {
          this.loading = false;
          this.$message({
            message: '이미지 등록 실패.',
            type: 'error'
          });
        })
    },
    setImage(e) {
      const file = e.target.files[0];
      if (file) { this.imgName = file.name}
      if (typeof FileReader === 'function') {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    cropImageData(val) {
      if (val) {
        this.imgFileName = this.imgName
        this.cropImg = val.getCroppedCanvas().toDataURL()
        val.getCroppedCanvas().toBlob((blob) => { this.cropBlob = blob })
      }
      this.imgSrc = ''
      this.$refs.input.value=''
    },    
  },
  data () {
    return {
      imgSrc: '',
      imgSrcOrigin: '',
      cropImg: '',
      cropBlob: undefined,
      loading: false,
      imgName: '',
      imgFileName: '',
    };
  },
}
</script>
<style scoped>
body {
  font-family: Arial, Helvetica, sans-serif;
  width: 1024px;
  margin: 0 auto;
}

input[type="file"] {
  display: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 5px 0;
}

.header h2 {
  margin: 0;
}

.header a {
  text-decoration: none;
  color: black;
}

.content {
  display: flex;
  justify-content: space-between;
}

.cropper-area {
  width: 614px;
}

.actions {
  margin-top: 1rem;
}

.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062CC;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}

.cropped-image img {
  width: 372px;
  position: absolute;
  left: 0;
}
</style>