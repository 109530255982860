<template>
  <div>
    <div class="imsr-content-top-container">
      <div class="imsr-content-container" style="min-width: 0;">
        <div class="imsr-container">
          <div class="imsr-page-title">
            <span>DOWNLOAD OUR APP</span>
          </div>
        </div>
      </div>
    </div>
    <div class="imsr-content-container" style="height: 100%;">
      <div class="imsr-container download-app">
        <img src="@/assets/footer/footer_ourapp.png" style="width: 100%;" >
        <div style="position: absolute; top: 580px; left: 70px;">
          <span>
            <img src="@/assets/AppStore.png">
          </span>
          <span style="margin-left: 40px;">
            <img src="@/assets/GooglePlay.png">
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  methods: {
    ...mapMutations('menu', [
      'setExtendFixedHeight'
    ]),
  },
  mounted () {
    this.setExtendFixedHeight(70);
  }
}
</script>

<style scoped>
.download-app {
  position: relative;
  margin-bottom: 50px;
}
</style>