<template>
  <el-dialog
    title=""
    :visible="studioGuideDialogVisible"
    :show-close="false"
    width="1200px">
    <div style="position: relative;">
      <div style="position: absolute; right: 32px; top: 32px; z-index: 999; cursor: pointer; display: flex; justify-content: flex-end; align-items: center;">
        <el-checkbox 
          style="margin-right: 16px; font-size: 16px;" label="Don’t show again"
          @change="changePopupFlag"
         />
        <span class="mdi mdi-close mdi-36px" style="font-size: 16px; font-weight: bold; z-index: 999; cursor: pointer;" @click="clickDone" />
      </div>
      <div>
        <swipe class="guide-swipe" ref="swipe" @change="change" style="height: 700px;" :auto="-1" :defaultIndex="0" :continuous="false">
          <swipe-item><img src="@/assets/3dguide/3d_guide_1.png" draggable="false"></swipe-item>
          <swipe-item><img src="@/assets/3dguide/3d_guide_2.png"></swipe-item>
          <swipe-item><img src="@/assets/3dguide/3d_guide_3.png"></swipe-item>
          <swipe-item><img src="@/assets/3dguide/3d_guide_4.png"></swipe-item>
          <swipe-item><img src="@/assets/3dguide/3d_guide_5.png"></swipe-item>
        </swipe>
      </div>
      <div style="position: absolute; bottom: 40px; z-index: 10; width: 100%; text-align: center;">
        <div class="indicator-container" style="position: relative;">
          <div style="position: absolute; left: 50px; top: 0px; font-size: 16px; font-weight: bold; cursor: pointer;" @click="clickPrev" v-if="currentIdx > 0">PREV</div>
          <div style="position: absolute; right: 50px; top: 0px; font-size: 16px; font-weight: bold; cursor: pointer;" @click="clickNext" v-if="this.isDone === false">NEXT</div>
          <div style="position: absolute; right: 50px; top: 0px; font-size: 16px; font-weight: bold; cursor: pointer; color: #98C8CC;" @click="clickDone" v-if="this.isDone === true">DONE</div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
require('vue-swipe/dist/vue-swipe.css');
import { Swipe, SwipeItem } from 'vue-swipe';
import { mapGetters, mapMutations } from 'vuex'
export default {
  components: {
    Swipe, SwipeItem
  },
  computed: {
    ...mapGetters('common', [
      'studioGuideDialogVisible'
    ])
  },
  watch: {
    currentIdx () {
      if (this.currentIdx === 4) {
        this.isDone = true
      } else {
        this.isDone = false
      }
    },
    popupFlag () {
      console.log('popupFlag', this.popupFlag)
    }
  },
  methods: {
    ...mapMutations('common', [
      'setStudioGuideDialogVisible'
    ]),
    change ($event) {
      this.currentIdx = $event;
    },
    clickNext () {
      this.$refs.swipe.goto(this.currentIdx + 1);
    },
    clickPrev () {
      this.$refs.swipe.goto(this.currentIdx - 1);
    },
    clickDone () {
      this.setStudioGuideDialogVisible(false);
      if (this.popupFlag) {
        this.$cookies.set("popupFlag", "Y", new Date(2099, 12, 31));
      }
      this.currentIdx = 0;
      this.$refs.swipe.goto(0);
    },
    changePopupFlag () {
      this.popupFlag = !this.popupFlag;
    }
  },
  data () {
    return {
      currentIdx: 0,
      isDone: false,
      popupFlag: false  
    }
  },
}
</script>

<style scoped>
.indicator-container {
  margin-top: 20px;
}

.indicator-container > span {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #c4c4c4;
  border-radius: 12px;
  margin: 0 5px;
}

.indicator-container > span.active {
  background-color: #232323;
}

.guide-swipe img {
  user-drag: none; 
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
</style>
<style>
.el-checkbox__label {
  font-size: 16px;
}
.el-checkbox__inner {
  width: 18px;
  height: 18px;
}
.el-checkbox__inner::after {
  height: 9px;
  width: 6px;
}
</style>