<template>
  <div>
    <div class="imsr-content-top-container">
      <div class="imsr-container">
        <div class="imsr-page-title">
          <div class="imsr-text-button left-container" @click="clickBack">
            <span class="mdi mdi-arrow-left mdi-12px"></span>
            <span style="font-size: 12px;"> BACK</span>
          </div>
          <span v-if="view3dType === 'palette'">{{ selectedTrend.title }}</span>
          <span v-else>VIRTUAL 3D STUDIO</span>
          <div class="right-container">
          </div>
        </div>
        <hr class="imsr-divider"/>
        <div class="clearfix">
          <div class="imsr-breadcrumb" style="float: left;">
            {{selectedMenu.menu.name}} &gt; {{selectedMenu.subMenu.name}}
          </div>
          <div class="item-top-button-group" style="float: right" v-if="view3dType === 'palette'">
            VIEW BY 
            <span class="imsr-text-button" 
              :class="{active: !relatedParam.type || relatedParam.type === ''}"
              @click="changeViewType()">ALL</span> 
            <span>|</span> 
            <span class="imsr-text-button" 
              :class="{active: relatedParam.type === 'PLACEMENT'}"
              @click="changeViewType('PLACEMENT')">PLACEMENT</span>
            <span>|</span> 
            <span class="imsr-text-button" 
              :class="{active: relatedParam.type === 'ALL_OVER_PRINT'}"
              @click="changeViewType('ALL_OVER_PRINT')">ALL-OVER PRINT</span>
          </div>
        </div>

        <div class="clearfix" style="padding: 10px 0;">
          <div style="float: left; line-height: 40px;">
            <span style="font-size: 24px; font-weight: bold; color: #98C8CC;">
              PALETTE ( {{paletteList.length}} )
            </span>
          </div>
          <div style="float: right;">
            <el-button type="primary" 
              style="width: 370px; background-color: #34E8DF; color: #000;" 
              v-if="view3dType === 'palette'"
              :disabled="paletteList.length === 0"
              @click="clickStartStudio">START VIRTUAL 3D STUDIO</el-button>
              <el-button type="primary" style="width: 370px;" :disabled="visualStudioItems.length === 0"
              v-if="view3dType === 'studio'"
              @click="clickStudioFinish">FINISH</el-button>
          </div>
        </div>

        <div style="display: flex; flex-direction: column">
          <el-button type="primary" style="width: 120px; margin-bottom: 10px; padding: 12px 0;" @click="clickAllDelete">ALL DELETE</el-button>
          <div class="palette-list-container">
            <div class="palette-item"
              :class="{'active': itemIdx === index && view3dType === 'studio', 'pointer': view3dType === 'studio'}"
              v-for="(item, index) in paletteList"
              :key="item.itemId"
              :style="{'background-image': `url(${$resizeServer}92-${item.itemRepImg.fileKey})`}"
              @click="clickPaletteItem(item, index)">
              <div style="display: flex;" :style="{'justify-content': item.graphicType === 'PLACEMENT' ? 'space-between' : 'flex-end' }">
                <div v-if="item.graphicType === 'PLACEMENT'" class="palette-placement-item"><span>P</span></div>
                <span class="close-btn el-icon-close" @click="clickDelete(item)"></span>
              </div>
            </div>  
            <div class="palette-no-item" v-if="paletteList.length === 0">
              <span>No Item</span>
            </div> 
          </div> 
        </div>

      </div>
    </div>

    <div class="imsr-content-container">
      <router-view ref="graphic3DPage" v-if="!loading" :prefix="prefix" ></router-view>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { map } from 'lodash'
export default {
  name: 'Style3DView',
  computed: {
    ...mapGetters('common', [
      'pageBack'
    ]),
    ...mapGetters('trend', [
      'selectedTrend',
      'trendItems',
      'itemPage',
      'selectedItemType',
      'relatedParam'
    ]),
    ...mapGetters('menu', [
      'selectedMenu'
    ]),
    ...mapGetters('auth', [
      'userInfo'
    ]),
    ...mapGetters('visualStudio', [
      'paletteList',
      'selectedGraphicItem',
      'selectedPatternItem',
      'visualStudioItems'
    ]),
    prefix () {
      if (this.selectedMenu && this.selectedMenu.menu) {
        const menu = this.selectedMenu.menu;
        return `/${menu.type.toLowerCase()}/${menu.id}/${this.selectedMenu.subMenu.id}/trend/${this.trendId}`
      }
      return '';
    },
    selectedItemIds () {
      return map(this.paletteList, item => item.itemId);
    }
  },
  methods: {
    ...mapMutations('menu', [
      'setExtendFixedHeight'
    ]),
    ...mapMutations('trend', [
      'clearItems'
    ]),
    ...mapActions('trend', [
      'getTrendInfo',
      'getTrendRelatedItems'
    ]),
    ...mapMutations('common', [
      'clearPageBack',
      'setPageBack',
      'setItemDel'
    ]),
    ...mapMutations('visualStudio', [
      'removePaletteList',
      'removeAllPaletteList'
    ]),
    getSelectedTrendInfo () {
      return new Promise((resolve, reject) => {
        if (!this.selectedTrend.title) {
          this.getTrendInfo(this.trendId)
            .then(resolve)
            .catch(reject);
        } else {
          resolve();
        }
      });
    },
    clickBack () {
      if (this.paletteList.length === 0) {
        if (this.$route.path.indexOf('palette') !== -1) {
          this.clearPageBack();
          this.$router.push(`${this.prefix}`);
        } else if (this.$route.path.indexOf('mockup') !== -1) {
          this.$router.push(this.$route.path.replace('mockup', 'palette'));
        } else {
          this.$router.push(this.$route.path.replace('studio', 'palette'));
        }
      } else {
        if (this.pageBack.to || this.paletteList.length === 0) {
          this.setPageBack({
            isBack: true,
            fromType: this.selectedMenu.type
          });
          this.$router.go(-1);
        } else {
          this.clearPageBack();
          this.$router.push(`${this.prefix}`);
        }
      }
    },
    clickStartStudio () {
      if (this.paletteList.length > 0) {
        this.setPageBack({
          to: 'palette'
        });
        this.$router.push(this.$route.path.replace('palette', 'mockup'));
      }
    },
    changeViewType (type) {
      this.$refs.graphic3DPage.changeViewType(type);
    },
    clickPaletteItem (item, index) {
      this.itemIdx = index
      if (this.view3dType === 'studio') {
        this.$refs.graphic3DPage.clickPaletteItem(item);
      }
    },
    clickStudioFinish () {
      this.setPageBack({
        to: 'exportList'
      });
      this.$router.push(this.$route.path.replace('studio', 'export'));
    },
    // TODO for문이.... 확인 필요
    clickAllDelete () {
      this.removeAllPaletteList()
      // for (let i = 0; i < this.paletteList.length; i++) {
      //   this.removePaletteList(this.paletteList[i])
      // }
      // this.removePaletteList(this.paletteList[0])
    },
    clickDelete (item) {
      this.setItemDel(true)
      this.removePaletteList(item)
    }
  },
  data () {
    return {
      trendId: '',
      loading: true,
      countPerRow: 4,
      view3dType: '',
      graphicPath: this.$root._route.path,
      itemActive: '',
      itemIdx: '',
    }
  },
  mounted () {
    this.setExtendFixedHeight(330);
    this.trendId = this.$route.params.trendId;
    const idx = this.$route.path.lastIndexOf('/');
    this.view3dType = this.$route.path.substring(idx + 1);
    if (!this.pageBack.isBack) {
      this.clearItems();
      this.getSelectedTrendInfo()
        .then(() => {
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
    
  }
}
</script>

<style scoped>
.item-top-button-group {
  float: right; 
  padding: 20px 0;
  font-size: 12px;
}

.imsr-item-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.item-top-button-group > span {
  padding-left: 13px;
}

.item-top-button-group .imsr-text-button.active {
  font-weight: 900;
  color: #232323 !important;
}

.imsr-item-list .imsr-style-wrap {
  display: flex;
  justify-content: center;
}

.imsr-style-wrap .item-image-thumb {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 370px;
  height: 370px;
  cursor: pointer;
}

.imsr-item-list .item-style-item-desc {
  display: flex;
  justify-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8px 10px;
}

.imsr-item-list .item-title {
  font-size: 16px;
  font-weight: bold;
  padding: 8px;
}

.item-style-favorite > span {
  display: block;
}

.item-style-favorite .item-style-favorite-member {
  font-size: 12px;
  display: block;
  margin-top: 4px;
}

.imsr-divider-group {
  margin-top: 20px;
}

.imsr-style-item .imsr-style-item-divider {
  border-radius: 0;
  padding-top: 4px;
}

.imsr-style-item.active .imsr-style-item-divider {
  border-color: #98c8cc;
  border-bottom-width: 10px;
  padding: 0;
}

.item-style-tag-group {
  display: flex;
  justify-items: center;
  justify-content: center;
  padding: 8px 0;
}

.item-style-tag {
  font-size: 12px;
  line-height: 18px;
  padding: 0 7px;
  margin: 3px;
  background-color: #eee;
  border-radius: 7px;
}

.imsr-style-item {
  width: 100%;
  max-width: 372px;
  
}

.imsr-item-list .item-sample > span {
  width: 200px;
  background-color: #ccc;
  text-align: center;
  display: inline-block;
  line-height: 24px;
}

.palette-list-container {
  display: flex;
  align-items: center;
  min-height: 110px;
  border: 1px solid #98C8CC;
  overflow-y: auto;
  padding: 0 5px;
}

.palette-list-container .palette-item {
  width: 90px;
  height: 90px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 5px;
  /* border: 1px solid transparent; */
  border: 1px solid #eee;
}

.palette-list-container .palette-item.pointer {
  cursor: pointer;
}

.palette-list-container .palette-item.pointer.active {
  border: 4px solid #98C8CC;
  cursor: default;
}

.palette-no-item {
  width: 100%;
  color: #999;
}

.palette-placement-item {
  width: 24px;
  height: 24px;
  background-color: #232323;
  display: flex;
  justify-content: center;
  align-items: center;
}

.palette-placement-item > span {
  font-size: 16px; 
  font-weight: 700; 
  color: #fff;
}

.close-btn {
  font-size: 15px;
  display: inline-block;
  padding: 3px;
  background-color: #fff;
  border: 1px solid #232323;
  color: #232323;
}

.close-btn:hover {
  border: 1px solid #FD1515;
  color: #FD1515;
  font-weight: bold;
  cursor: pointer;
}
</style>