<template>
  <div class="indicator-container">
    <div style="display: flex; justify-content: center;">
      <div style="width: 160px; height: 144px;">
        <img src="@/assets/3d_toast_placement.png">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StudioInfoPop',
}
</script>

<style scoped>
.indicator-container {
  width: 751px;
  height: 751px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
}
</style>