<template>
  <div class="download-app">
    <img src="@/assets/mobileweb/downapp_bg_m.png" style="width: 100%;" >
    <div style="position: absolute; top: 269px;">
      <div style="display: flex; flex-direction: column;">
        <span class="download-app-text">Gather your own inspirations<br>And request us<br>whatever you’re looking for!</span>
        <span>
          <img src="@/assets/mobileweb/downapp_appicon.png">
        </span>
        <span style="text-align: center; margin-top: 33px;">
          <a :href="link.ios"><img src="@/assets/mobileweb/badge_appstore.png"></a>
        </span>
        <span style="text-align: center; margin-top: 33px;">
          <a :href="link.aos"><img src="@/assets/mobileweb/badge_googleplay.png"></a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MobilewebDownloadApp',
  data () {
    return {
      link: {
        aos: "https://play.google.com/store/apps/details?id=com.shinwon.inmyshowroom",
        ios: "https://apps.apple.com/app/id1552160946"
      }
    }
  }
}
</script>

<style scoped>
.download-app {
  position: relative;
  display: flex; 
  align-items: center;
}
.download-app-text {
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  margin-bottom: 31px;
}
</style>