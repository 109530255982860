<template>
  <div>
    <div class="imsr-content-top-container">
      <div class="imsr-container">
        <div class="imsr-page-title">
          <div class="imsr-text-button left-container" @click="clickBack">
            <span class="mdi mdi-arrow-left mdi-12px"></span>
            <span> BACK</span>
          </div>
          <span>{{ selectedTrend.title }}</span>
          <div class="right-container"></div>
        </div>
        <hr class="imsr-divider"/>
        <div class="clearfix">
          <div class="imsr-breadcrumb" style="float: left;">
            {{selectedMenu.menu.name}} &gt; {{selectedMenu.subMenu.name}}
          </div>

          <div class="item-top-button-group" style="float: right" v-if="selectedMenu.menu.type === 'STYLE'">
            VIEW BY 
            <span class="imsr-text-button" 
              :class="{active: !relatedParam.type}"
              @click="changeViewType()">STYLE</span> 
            <span>|</span> 
            <span class="imsr-text-button"
              :class="{active: relatedParam.type === 'FABRIC'}"
              @click="changeViewType('relatedType', 'FABRIC')">FABRIC</span>
          </div>
          <div class="item-top-button-group" style="float: right" v-if="selectedMenu.menu.type === 'FABRIC'">
            <span class="imsr-text-button"
              :class="{active: !relatedParam.type}"
              @click="changeViewType()">ALL</span> 
            <span>|</span> 
            <span class="imsr-text-button" 
              :class="{active: relatedParam.type === 'ASIA'}"
              @click="changeViewType('materialType', 'ASIA')">ASIA</span>
              <span>|</span> 
            <span class="imsr-text-button" 
              :class="{active: relatedParam.type === 'CAFTA'}"
              @click="changeViewType('materialType', 'CAFTA')">CAFTA</span>
          </div>
          <div class="item-top-button-group" style="float: right" v-if="selectedMenu.menu.type === 'GRAPHIC'">
            <span class="imsr-text-button"
              :class="{active: !relatedParam.type}"
              @click="changeViewType()">ALL</span> 
            <span>|</span> 
            <span class="imsr-text-button" 
              :class="{active: relatedParam.type === 'PLACEMENT'}"
              @click="changeViewType('graphicType', 'PLACEMENT')">PLACEMENT</span>
              <span>|</span> 
            <span class="imsr-text-button" 
              :class="{active: relatedParam.type === 'ALL_OVER_PRINT'}"
              @click="changeViewType('graphicType', 'ALL_OVER_PRINT')">ALL-OVER PRINT</span>
          </div>

        </div>
      </div>
    </div>
    <div class="imsr-content-container">
      <item-card-list
        ref="itemCartList"
        :loading="loading"
        :itemList="trendItems"
        :listParam="itemPage"
        :selectedItemId="selectedItemId"
        @setItemWish="setItemWish"
        @clickThumb="clickThumb"
        @infiniteHandler="infiniteHandler">
      </item-card-list>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ItemCardList from '@/components/commStyle/ItemCardList'

export default {
  name: 'StyleItemList',
  components: {
    ItemCardList
  },
  computed: {
    ...mapGetters('common', [
      'pageBack'
    ]),
    ...mapGetters('trend', [
      'selectedTrend',
      'trendItems',
      'itemPage',
      'selectedItemType',
      'relatedParam',
      'selectedItemId'
    ]),
    ...mapGetters('menu', [
      'selectedMenu'
    ]),
    prefix () {
      
      if (this.selectedMenu && this.selectedMenu.menu) {
        const idx = this.$route.path.indexOf('items');
        
        return this.$route.path.substring(0, idx - 1 );
      }
      return '';
    }
  },
  methods: {
    ...mapMutations('menu', [
      'setExtendFixedHeight'
    ]),
    ...mapMutations('trend', [
      'clearItems',
      'setSelectedItemType',
      'setItemWish',
      'setRelatedParam',
      'setSelectedTrendId',
      'setSelectedItemId'
    ]),
    ...mapActions('trend', [
      'getTrendInfo',
      'getTrendItems',
      'getTrendRelatedItems'
    ]),
    ...mapMutations('common', [
      'clearPageBack',
      'setPageBack'
    ]),
    getSelectedTrendInfo () {
      return new Promise((resolve, reject) => {
        this.getTrendInfo(this.trendId)
          .then(resolve)
          .catch(reject);
      });
    },
    clickThumb (item) {
      this.setPageBack({
        to: this.selectedMenu.type
      });

      this.setSelectedItemId(item.itemId);
      this.$router.push(`${this.prefix}/item/${item.itemType}/${item.itemId}`);
    },
    clickBack () {
      if (this.pageBack.to) {
        this.setPageBack({
          isBack: true,
          fromType: this.selectedMenu.type
        });
        this.$router.go(-1);
      } else {
        this.clearPageBack();
        if (this.selectedTrend.contentType === 'LIST') {
          const idx = this.$route.path.indexOf('trend');
          this.$router.push(this.$route.path.substring(0, idx - 1));
        } else {
          this.$router.push(`${this.prefix}`);
        }
        
      }
    },
    changeViewType (name, type) {
      if (this.relatedParam.type === type) {
        return false;
      }

      this.loading = true;

      this.$refs.itemCartList.reset();
      this.clearItems();
      if (type) {
        this.setRelatedParam({
          name, type
        });
      } else {
        this.setRelatedParam({});
      }
      
    },
    infiniteHandler ($state) {
      this.getTrendRelatedItems({
          page: this.itemPage.page + 1, 
          trendId: this.trendId
        })
        .then(() => {
          $state.loaded();
          this.loading = false;
          if (this.trendItems.length === this.itemPage.total) {
            $state.complete();
          }
        })
        .catch(() => {
          $state.error();
          this.loading = false;
        });
    }
  },
  data () {
    return {
      trendId: '',
      loading: false
    }
  },
  mounted () {
    this.setExtendFixedHeight(125);
  },
  created () {
    if (this.selectedItemId === 0) {
      this.setSelectedItemId(this.$route.query.item ? Number(this.$route.query.item) : 0);
    }
    
    this.trendId = this.$route.params.trendId;
    this.setSelectedTrendId(this.trendId);

    if (!(this.pageBack.isBack && this.pageBack.to === this.selectedMenu.type)) {
      this.loading = true;
      this.clearItems();
      this.getSelectedTrendInfo();
    } 
  }
}
</script>

<style scoped>
.item-top-button-group {
  float: right; 
  padding: 20px 0;
  font-size: 12px;
}

.item-top-button-group > span {
  padding-left: 13px;
}

.item-top-button-group .imsr-text-button.active {
  font-weight: 900;
  color: #232323 !important;
}

</style>