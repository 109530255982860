import Vue from 'vue'
import cartService from '@/services/cartService';
import recapService from '@/services/recapService';
import { assignIn, chain, concat, filter, findIndex, intersectionWith, map } from 'lodash'

const state = {
  cartList: [],
  listParam: {
    total: 0,
    page: 0,
    size: 30,
    sort: 'createDate,desc',
    itemType: undefined
  },
  requestCodes: {
    STYLE: [
      { code: 'Garment_Cost', desc: 'Garment Cost' },
      { code: 'Sample_to_Send', desc: 'Sample to Send' },
      { code: 'Tailoring_Sample', desc: 'Tailoring Sample' },
      { code: 'Fabric_Swatch', desc: 'Fabric Swatch' },
      { code: 'ETC', desc: 'ETC' }
    ],
    FABRIC: [
      { code: 'Sample_to_Send', desc: 'Sample to Send' },
      { code: 'Fabric_Swatch', desc: 'Fabric Swatch' },
      { code: 'ETC', desc: 'ETC' }
    ],
    GRAPHIC: [
      { code: 'Graphic_Cost', desc: 'Graphic Cost' },
      { code: 'SO', desc: 'S/O' },
      { code: 'Sample_to_Send', desc: 'Sample to Send' },
      // { code: 'TwoD_Form', desc: '2D Form' },
      // { code: 'ThreeD_Form', desc: '3D Form' },
      { code: 'Additional_Request', desc: 'Additional Request' }
    ],
    GRAPHIC_STUDIO: [
      { code: 'Graphic_Cost', desc: 'Graphic Cost' },
      { code: 'SO', desc: 'S/O' },
      { code: 'Sample_to_Send', desc: 'Sample to Send' },
      // { code: 'TwoD_Form', desc: '2D Form' },
      // { code: 'ThreeD_Form', desc: '3D Form' },
      { code: 'Additional_Request', desc: 'Additional Request' }
    ],
    WASHING: [
      { code: 'Garment_Cost', desc: 'Garment Cost' },
      { code: 'Sample_to_Send', desc: 'Sample to Send' },
      { code: 'Tailoring_Sample', desc: 'Tailoring Sample' },
      { code: 'Fabric_Swatch', desc: 'Fabric Swatch' },
      { code: 'ETC', desc: 'ETC' }
    ],
    INSPIRATION: [
      { code: 'Style', desc: 'Style' },
      { code: 'Fabric', desc: 'Fabric' },
      { code: 'Detail', desc: 'Detail' },
      { code: 'Washing', desc: 'Washing' },
      { code: 'Graphic', desc: 'Graphic' },
      { code: 'ETC', desc: 'ETC' }
    ]
  }
};

const getters = {
  cartList (state) {
    return map(state.cartList, cart => {
      if (cart.cartItem.category) {
        cart.category_name = cart.cartItem.category.split('_').join(' ');
        if (cart.cartItem.category === 'MY INSPIRATION REQUEST' || cart.cartItem.category === 'MY_INSPIRATION_REQUEST') {
          cart.cartItem.dateTitle = Vue.moment(cart.cartItem.title).format('MM.DD.YYYY HH:mm A')
        }
      }
      if (cart.request) {
        cart.requestList = cart.request.split(',');
      } else {
        cart.requestList = [];
      }
      
      return cart;
    });
  },
  listParam (state) {
    return state.listParam;
  },
  requestAllCodes (state) {
    return state.requestCodes;
  },
  requestCodes (state, getters, rootState) {
    return state.requestCodes[rootState.menu.selected.type];
  },
  checkedCartItem (state, getters) {
    return chain(getters.cartList)
      .filter(cart => cart.checked)
      .map(cart => {
        const codes = state.requestCodes[cart.cartItem.itemType];
        cart.requestCodes =  intersectionWith(codes, cart.requestList, (code, request) => code.code === request);
        return cart;
      })
      .value();
  }
};

const actions = {
  getCartList ({ commit, state }, page) {
    return new Promise((resolve, reject) => {
      const param = assignIn(state.listParam, {
        page: page - 1
      });

      cartService.getCartItems(param)
        .then(res => {
          commit('setCartList', res.data.list);
          commit('setListParam', {
            total: res.data.total,
            page
          })
          resolve();
        })
        .catch(reject);
    });
  },
  addToCart ({ commit, dispatch }, form) {
    return new Promise((resolve, reject) => {
      cartService.addItem(form)
        .then(() => {
          commit('item/setItemAddedCart', true, { root: true });
          return dispatch('auth/getBadgeInfo', {}, { root: true });
        })
        .then(resolve)
        .catch(reject);
    });
  },
  removeFromCart ({ commit, dispatch, getters }) {
    const ids = map(getters.checkedCartItem, item => item.id);
    return new Promise((resolve, reject) => {
      cartService.removeItems(ids.join(','))
        .then(() => {
          return dispatch('auth/getBadgeInfo', {}, { root: true });
        })
        .then(() =>{
          commit('removeItems', ids);
          resolve();
        })
        .catch(reject);
    });
  },
  saveForLater ({ commit, dispatch }, param) {
    return new Promise((resolve, reject) => {
      cartService.saveForLater(param)
        .then(() => {
          return dispatch('auth/getBadgeInfo', {}, { root: true });
        })
        .then(() =>{
          commit('removeItems', param.cartIds);
          resolve();
        })
        .catch(reject);
    });
  },
  modifyCartRequest ({ commit }, item) {
    return new Promise((resolve, reject) => {
      const param = {
        requestList: item.requestList,
        request: item.requestList.join(','),
        comment: item.comment
      };
      cartService.modifyCartItem(item.id, param)
        .then(() =>{
          commit('changeRequest', {
            id: item.id, param
          });
          resolve();
        })
        .catch(reject);
    });
  },
  cartToRecap ({ commit, dispatch }, form) {
    return new Promise((resolve, reject) => {
      recapService.addRecap(form)
        .then(() => {
          commit('removeItems', form.cartIds);
          return dispatch('auth/getBadgeInfo');
        })
        .then(resolve)
        .catch(reject);
    });
  }
};

const mutations = {
  setCartList (state, list) {
    state.cartList = concat(state.cartList, list);
  },
  setCartChecked (state, { cartId, checked }) {
    state.cartList = map(state.cartList, cart => {
      if (!cartId || cart.id === cartId) {
        cart.checked = checked;
      }
      return cart;
    });
  },
  initList (state, forLater) {
    state.cartList = [];
    state.listParam = assignIn({
      total: 0,
      page: 0,
      size: 30,
      sort: 'createDate,desc',
      itemType: undefined
    }, forLater);
  },
  setListParam (state, param) {
    state.listParam = assignIn(state.listParam, param);
  },
  removeItems (state, ids) {
    state.cartList = filter(state.cartList, item => ids.indexOf(item.id) === -1);
    state.listParam = assignIn(state.listParam, {
      total: state.listParam.total - ids.length
    })
  },
  changeRequest (state, { id, param }) {
    const idx = findIndex(state.cartList, item => item.id === id);
    const item = state.cartList[idx];
    state.cartList[idx] = assignIn(item, param);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
