<template>
  <div class="no-content-container">
    <div style="margin-top: -50px;">
      <img src="@/assets/logo.png"/>
      <div class="text">No Content</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImsrNoContent'
}
</script>

<style scoped>
.no-content-container {
  display: flex; 
  justify-content: center; 
  align-items: center; 
  opacity: 0.4;
  height: 100%;
}

.no-content-container .text {
  margin-top: 20px;
}
</style>