<template>
  <div class="item-detail-container">
    <div class="row">
      <span class="item-category">MY INSPIRATION REQUEST</span>
    </div>
    <div class="row item-title">{{item.title}}</div>
    <div class="item-info-container">
      <div class="item-info-row">
        <div class="item-label">BUYER</div>
        <div class="item-value">{{detail.buyer || '-'}}</div>
      </div>
      <div class="item-info-row">
        <div class="item-label">BRAND</div>
        <div class="item-value">{{detail.brand || '-'}}</div>
      </div>
      <div class="item-info-row">
        <div class="item-label">GENDER</div>
        <div class="item-value">
          <span v-for="gender in detail.gender" :key="gender" class="gender-tag">
            {{gender}}
          </span>
          <span v-if="!detail.gender || detail.gender.length === 0">-</span>
        </div>
      </div>
      <div class="item-info-row">
        <div class="item-label">SEASON</div>
        <div class="item-value">
          <span v-for="season in detail.season" :key="season" class="gender-tag">
            {{season}}
          </span>
          <span v-if="!detail.season || detail.season.length === 0">-</span>
        </div>
      </div>
      <div class="item-info-row">
        <div class="item-label">HASHTAG</div>
        <div class="item-value">
          <span class="item-tag" v-for="(tag, index) in item.itemTagNames" :key="index">{{tag.name}}</span>
        </div>
      </div>
    </div>
    <hr class="imsr-divider" style="margin: 20px 0;"/>
    <div style="font-weight: 900; text-align: left; line-height: 40px;">REQUEST</div>
    <el-form :model="requestForm" label-position="left" label-width="0px">
      <el-form-item>
        <el-checkbox-group v-model="requestForm.requestList"
          @change="changeRequestList">
          <el-row>
            <el-col :span="12" style="text-align: left;" v-for="request in requestCodeList" :key="request.code">
              <el-checkbox :label="request.code" :disabled="item.addedCart || isRecap">{{request.desc}}</el-checkbox>
            </el-col>
          </el-row>
        </el-checkbox-group>
        <el-input
          resize="none"
          type="textarea"
          :rows="3"
          :disabled="commentDisabled || item.addedCart || isRecap"
          :placeholder="isRecap ? '' : 'Leave your comment if you have'"
          v-model="requestForm.comment">
        </el-input>
      </el-form-item>
    </el-form>
    <div v-if="!isRecap">
      <el-button v-if="item.addedCart" class="imsr-button" style="width: 100%;" 
        @click="clickMoveToCart">
        ALREADY IN CART - CLICK TO MOVE
      </el-button>
      <el-button v-else class="imsr-button" style="width: 100%;" 
        :disabled="requestForm.requestList.length === 0"
        @click="$emit('clickAddToCart', requestForm)">
        ADD TO CART
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { map } from 'lodash';
import recapService from '@/services/recapService';

export default {
  props: ['item', 'detail', 'isRecap', 'recapId'],
  computed: {
    ...mapGetters('cart', [
      'requestAllCodes'
    ]),
    requestCodeList () {
      return this.requestAllCodes.INSPIRATION;
    },
  },
  methods: {
    ...mapActions('item', [
      'getItemCartRequest'
    ]),
    changeRequestList () {
      if (this.requestForm.requestList.indexOf('ETC') > -1) {
        this.commentDisabled = false;
      } else {
        this.commentDisabled = true;
        this.requestForm.comment = ''
      }
    },
    checkedCart () {
      if (this.item.addedCart) {
        this.getItemCartRequest(this.item.id)
          .then((data) => {
            if (this.item.request) {
              this.requestForm.requestList = map(data.request.split(','), req => req.trim());
              this.requestForm.comment = data.comment;
            }
          })
      }
    },
    getRecapRequest () {
      recapService.getRecapItemRequest(this.recapId, this.item.id)
        .then(res => {
          if (res.data.request) {
            this.requestForm.requestList = map(res.data.request.split(','), req => req.trim());
            this.requestForm.comment = res.data.comment;
          }
        })
    },
    clickMoveToCart () {
      this.$router.push('/cart');
    }
  },
  data () {
    return {
      requestForm: {
        itemId: '',
        requestList: [],
        comment: ''
      },
      commentDisabled: true
    }
  },
  mounted () {
    this.requestForm.itemId = this.item.id;
    if (this.isRecap) {
      this.getRecapRequest();
    } else {
      this.checkedCart();
    }
  }
}
</script>

<style scoped>
.item-detail-container {
  padding: 10px 0;
  text-align: left;
}

.item-detail-container  div.row {
  padding: 10px 0;
}

.item-title {
  font-size: 24px;
  font-weight: bold;
}

.item-category {
  display: inline-block;
  background-color: #ccc; 
  line-height: 25px; 
  font-size: 12px;
  padding: 0px;
  width: 180px;
  text-align: center;
}

.wish-container > span {
  float: left;
}

.wish-container .wish-text {
  font-size: 16px; 
  display: inline-block; 
  padding: 2px 0; 
  margin-left: 5px;
}

.wish-container .wish-text .wish-count {
  margin: 0 5px; 
  font-weight: bold;
  cursor: pointer;
  border-bottom: 1px solid #232323;
}

.item-info-container .item-info-row {
  display: flex;
  line-height: 40px;
  margin: 2px 0;
}

.item-info-container .item-info-row .item-label {
  width: 215px;
  padding-right: 12px;
  font-weight: bold;
}

.item-info-container .item-info-row .item-value {
  flex: 1;
}

.item-tag {
  background-color: #eee;
  font-size: 12px;
  line-height: 20px;
  padding: 0 10px;
  border-radius: 10px;
  display: inline-block;
  margin-right: 10px;
}

.related-item {
  color: #98c8cc;
  cursor: pointer;
  border-bottom: 1px solid #98c8cc;
}

.gender-tag {
  display: inline-block;
  border: 1px solid #232323;
  border-radius: 10px;
  margin-right: 10px;
  line-height: 25px;
  padding: 0 10px;
}
</style>