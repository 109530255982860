<template>
  <div>
    <el-table
      v-loading="loading"
      :data="recapList"
      @row-click="handleClick">
      <el-table-column
        label="DATE"
        width="180"
        align="center">
        <template slot-scope="scope">
          <div style="display: flex; justify-content: center;">
            <div style="text-align: left;">
              {{ scope.row.createDate | moment('MM.DD.YYYY') }}<br>
              {{ scope.row.createDate | moment('HH:mm:A') }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="companyName"
        label="COMPANY"
        width="150"
        align="center">
      </el-table-column>
      <el-table-column
        label="MEMBER"
        width="200"
        align="center">
        <template slot-scope="scope">
          {{ scope.row.userFirstName }} {{ scope.row.userLastName }}
        </template>
      </el-table-column>
      <el-table-column
        label=""
        width="100"
        align="center">
        <template slot-scope="scope">
          <div class="recap-item-image"
            :style="{'background-image': `url(${$resizeServer}92-${scope.row.fileKey})`}">
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="REPRESENTED ITEM - TITLE"
        align="center">
        <template slot-scope="scope">
          <el-row>
            <el-col :span="12">
              {{scope.row.title}}
            </el-col>
            <el-col :span="12">
              {{scope.row.info}}
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column
        label="DUE DATE"
        width="180"
        align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.dueDate" :class="{'text-danger': scope.row.dueDateOver}">
            {{ scope.row.dueDate | moment('MM.DD.YYYY') }}
          </div>
          <div v-else> - </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="numOfItem"
        label="NUMBER OF ITEMS"
        width="95"
        align="center">
      </el-table-column>
      <el-table-column
        label="COMMENTS"
        width="100"
        align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.numOfOthersNewComment > 0" style="padding: 5px 0;">
            <el-badge is-dot class="item" >{{ scope.row.numOfComment }}</el-badge>
          </div>
          <span v-else>{{ scope.row.numOfComment }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin: 30px 0;">
      <el-pagination
        layout="prev, pager, next"
        :total="listParam.total"
        :page-size="listParam.size"
        :current-page="listParam.page"
        @current-change="getRecapListByPageNo">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  computed: {
    ...mapGetters('common', [
      'pageBack'
    ]),
    ...mapGetters('recap', [
      'recapList',
      'listParam'
    ])
  },
  methods: {
    ...mapMutations('common', [
      'setPageBack'
    ]),
    ...mapActions('recap', [
      'getRecapList'
    ]),
    getRecapListByPageNo (page) {
      this.loading = true;
      this.getRecapList(page)
        .then(() => {
          this.loading = false;
        })
    },
    handleClick (item) {
      this.setPageBack({
          to: 'recap'
        });
      this.$router.push(`${this.$route.path}/${item.id}`);
    }
  },
  data () {
    return {
      loading: true
    }
  },
  mounted () {
    if (!(this.pageBack.isBack && this.pageBack.to === 'recap')) {
      this.getRecapListByPageNo(1);
    } else {
      this.loading = false;
    }
  }
}
</script>

<style scoped>
.recap-item-image {
  width: 90px;
  height: 90px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
</style>