<template>
  <div class="item-detail-container">
    <div class="row">
      <span class="item-category">{{category_name}}</span>
    </div>
    <div class="row item-title" v-if="item.itemType === 'GRAPHIC'">{{item.itemNo}}</div>
    <div class="row item-title" v-else>{{item.title}}</div>
    <div class="wish-container clearfix" v-if="item.addedWish" v-loading="wishLoading">
      <span class="mdi mdi-heart mdi-24px error-color" @click="clickWish"></span> 
      <span class="wish-text">
        Added by 
        <el-popover
          placement="right-start"
          width="250"
          trigger="click">
          <div style="max-height: 300px; position: relative;">
            <el-table :data="item.wishUserList" :show-header="false" style="width: 100%; height: 100%;">
              <!-- <el-table-column label="name">
                <template slot-scope="scope">
                  {{ scope.row.companyName }}
                </template>
              </el-table-column> -->
              <el-table-column label="name">
                <template slot-scope="scope">
                  {{ scope.row.firstName }} {{ scope.row.lastName }}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <span class="wish-count" slot="reference">
            {{userInfo.firstName}} 
            <span v-if="item.wishUserList.length - 1 > 0"> and {{ item.wishUserList.length - 1 }} more</span>
          </span>
        </el-popover>
      </span>
    </div>
    <div class="wish-container clearfix" v-else-if="item.wishUserList.length > 0" v-loading="wishLoading">
      <span class="mdi mdi-heart mdi-24px error-color" @click="clickWish"></span> 
      <span class="wish-text">
        Added by 
        <el-popover
          placement="right-start"
          width="250"
          trigger="click">
          <div style="max-height: 300px; position: relative;">
            <el-table :data="item.wishUserList" :show-header="false" style="width: 100%; height: 100%;">
              <!-- <el-table-column label="name">
                <template slot-scope="scope">
                  {{ scope.row.companyName }}
                </template>
              </el-table-column> -->
              <el-table-column label="name">
                <template slot-scope="scope">
                  {{ scope.row.firstName }} {{ scope.row.lastName }}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <span class="wish-count" slot="reference">{{item.wishUserList.length}}</span>
        </el-popover>
      </span>
    </div>
    <div class="wish-container clearfix" v-else v-loading="wishLoading">
      <span class="mdi mdi-heart-outline mdi-24px" @click="clickWish"></span> 
    </div>
    <hr class="imsr-divider" style="margin: 20px 0;"/>
    <div class="item-info-container">
      <div class="item-info-row" v-if="item.itemType === 'GRAPHIC'">
        <div class="item-label">TREND INSIGHT</div>
        <div v-if="trendList.length > 0">
          <div class="item-value" 
            style="color: #98C8CC; text-decoration: underline; height: 30px; cursor: pointer;" 
            v-for="list in trendList" :key="list.id"
            @click="handleClick(list.id, list.showMenuId)">
            {{list.title}}
          </div>
        </div>
        <div v-else><span>-</span></div>
      </div>
      <div class="item-info-row" v-else>
        <div class="item-label">{{itemType}} ITEM NO.</div>
        <div class="item-value" style="color: #98C8CC">{{item.itemNo}}</div>
      </div>
      <div class="item-info-row">
        <div class="item-label">FULL DESCRIPTION</div>
        <div class="item-value">{{item.info}}</div>
      </div>
      <div class="item-info-row" v-if="isGraphic">
        <div class="item-label">GENDER</div>
        <div class="item-value">
          <span v-for="gender in detail.gender" :key="gender" class="gender-tag">
            {{gender}}
          </span>
        </div>
      </div>
      <div class="item-info-row" v-if="isFabric">
        <div class="item-label">MATERIAL TYPE</div>
        <div class="item-value">{{detail.materialType}}</div>
      </div>
      <div class="item-info-row" v-if="!isGraphic">
        <div class="item-label">FABRIC TYPE</div>
        <div class="item-value">{{detail.fabricType || '-'}}</div>
      </div>
      <div class="item-info-row" v-if="isStyle">
        <div class="item-label">MAJOR SPEC</div>
        <div class="item-value">{{detail.majorSpec}}</div>
      </div>
      <div class="item-info-row" v-if="isGraphic">
        <div class="item-label">GRAPHIC TYPE</div>
        <div class="item-value" v-if="detail.graphicType === 'PLACEMENT'">PLACEMENT</div>
        <div class="item-value" v-if="detail.graphicType === 'ALL_OVER_PRINT'">ALL-OVER PRINT</div>
      </div>
      <div v-if="!isGraphic">
        <hr class="imsr-divider" style="margin: 20px 0;"/>
        <div style="font-weight: 900; text-align: left; line-height: 40px;">FABRIC DETAIL</div>
        <div class="item-info-row">
          <div class="item-label">COMPOSITION</div>
          <div class="item-value">{{detail.composition || '-'}}</div>
        </div>
        <div class="item-info-row">
          <div class="item-label">SOLID/PATTERN</div>
          <div class="item-value">{{detail.solidPattern || '-'}}</div>
        </div>
        <div class="item-info-row">
          <div class="item-label">FINISH</div>
          <div class="item-value">{{detail.finish || '-'}}</div>
        </div>
        <div class="item-info-row">
          <div class="item-label">WIDTH</div>
          <div class="item-value">{{detail.width || '-'}}</div>
        </div>
        <div class="item-info-row">
          <div class="item-label">WEIGHT</div>
          <div class="item-value">{{detail.weight || '-'}}</div>
        </div>
        <div v-if="isFabric">
          <div class="item-info-row">
            <div class="item-label">ORIGIN</div>
            <div class="item-value">{{detail.origin || '-'}}</div>
          </div>
          <div class="item-info-row">
            <div class="item-label">TECHNICAL CALL OUT ISSUE</div>
            <div class="item-value">{{detail.extraInfo || '-'}}</div>
          </div>
        </div>
      </div>
      <div class="item-info-row" v-else>
        <div class="item-label">COLOR</div>
        <div class="item-value">
          <div style="padding: 5px 0; line-height: 35px;">
            <div v-for="color in item.itemGraphicColors" :key="color.no" style="">
              <span :style="{'background-color': color.hexCode}" style="float: left; 18px;margin-top: 11px; display: inline-block; width: 18px; height: 18px; margin-right: 10px;"></span>
              <span>{{color.name}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="item-info-row">
        <div class="item-label">ADDITIONAL NOTE</div>
        <div class="item-value">{{detail.additionalNote || '-'}}</div>
      </div>
      <hr class="imsr-divider" style="margin: 20px 0;"/>
      <div class="item-info-row">
        <div class="item-label">HASHTAG</div>
        <div class="item-value">
          <span style="cursor: pointer;" class="item-tag" @click="clickHashtag(tag.name)" v-for="(tag, index) in item.itemTagNames" :key="index">{{tag.name}}</span>
        </div>
      </div>
      <div class="item-info-row" v-if="!isStyle">
        <div class="item-label">RELATED STYLE ITEM NO.</div>
        <div class="item-value">
          <span v-if="related.STYLE.itemNo" :class="related.STYLE.relatedItemId ? 'related-item' : 'related-item-none'" 
            @click="$emit('showRelatedItem', { type: 'style', itemId: related.STYLE.relatedItemId})">
            {{related.STYLE.itemNo}}</span>
          <span v-else>-</span>
        </div>
      </div>
      <div class="item-info-row" v-if="!isFabric">
        <div class="item-label">RELATED FABRIC ITEM NO.</div>
        <div class="item-value">
          <span v-if="related.FABRIC.itemNo" :class="related.FABRIC.relatedItemId ? 'related-item' : 'related-item-none'" 
            @click="$emit('showRelatedItem', { type: 'fabric', itemId: related.FABRIC.relatedItemId})">
            {{related.FABRIC.itemNo}}</span>
          <span v-else>-</span>
        </div>
      </div>
      <div class="item-info-row" v-if="!isGraphic">
        <div class="item-label">RELATED GRAPHIC ITEM NO.</div>
        <div class="item-value">
          <div v-if="related.GRAPHIC.itemNo">
            <span :class="related.GRAPHIC.relatedItemId ? 'related-item' : 'related-item-none'" 
              v-if="this.related.GRAPHIC.link === true" 
            @click="$emit('showRelatedItem', { type: 'graphic', itemId: related.GRAPHIC.relatedItemId})">
            {{related.GRAPHIC.itemNo}}</span>
            <span class="related-item-none" v-else>{{related.GRAPHIC.itemNo}}</span>
          </div>
          <!-- <div v-if="related.GRAPHIC.itemNo">
            <span class="related-item" v-if="this.related.GRAPHIC.link === true && this.userInfo.admin" 
            @click="showRelatedItem(related.GRAPHIC.relatedItemId)">{{related.GRAPHIC.itemNo}}</span>
            <span style="color: #98c8cc;" v-else>{{related.GRAPHIC.itemNo}}</span>
          </div> -->
          <span v-else>-</span>
        </div>
      </div>
      <hr class="imsr-divider" style="margin: 20px 0;"/>
      <div style="font-weight: 900; text-align: left; line-height: 40px;">REQUEST</div>
      <el-form :model="requestForm" label-position="left" label-width="0px">
        <el-form-item>
          <el-checkbox-group v-model="requestForm.requestList"
            @change="changeRequestList">
            <el-row>
              <el-col :span="12" style="text-align: left;" v-for="request in requestCodeList" :key="request.code">
                <el-checkbox :label="request.code" :disabled="item.addedCart || isRecap">{{request.desc}}</el-checkbox>
              </el-col>
            </el-row>
          </el-checkbox-group>
          <el-input
            resize="none"
            type="textarea"
            :rows="3"
            :disabled="commentDisabled || item.addedCart || isRecap"
            :placeholder="placeholderTxt()"
            v-model="requestForm.comment">
          </el-input>
        </el-form-item>
      </el-form>
      <div v-if="!isRecap">
        <el-button v-if="item.addedCart" class="imsr-button" style="width: 100%;" 
          @click="clickMoveToCart">
          ALREADY IN CART - CLICK TO MOVE
        </el-button>
        <el-button v-else class="imsr-button" style="width: 100%;" 
          :disabled="requestForm.requestList.length === 0"
          @click="$emit('clickAddToCart', requestForm)">
          ADD TO CART
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { assignIn, map, reduce } from 'lodash'
import { mapActions, mapGetters, mapMutations } from 'vuex';
import wishService from '@/services/wishService';
import recapService from '@/services/recapService';

export default {
  props: ['item', 'itemType', 'detail', 'isRecap', 'recapId'],
  computed: {
    ...mapGetters('auth', [
      'userInfo'
    ]),
    ...mapGetters('cart', [
      'requestAllCodes'
    ]),
    ...mapGetters('menu', [
      'selectedMenu'
    ]),
    ...mapGetters('common', [
      'viewSearchForm',
      'searchHashtag'
    ]),
    requestCodeList () {
      let list = this.requestAllCodes[this.selectedMenu.type];
      if (!list) {
        list = this.requestAllCodes[this.itemType.toUpperCase()];
      }
      return list;
    },
    isStyle () {
      return this.itemType === 'STYLE';
    },
    isFabric () {
      return this.itemType === 'FABRIC';
    },
    isGraphic () {
      return this.itemType === 'GRAPHIC';
    },
    category_name () {
      if (this.item.category) {
        return this.item.category.split('_').join(' ');
      }
      return '';
    },
    related () {
      const related = {
        STYLE: {},
        FABRIC: {},
        GRAPHIC: {}
      };
      const itemRelated = reduce(this.item.itemRelated, (prev, curr) => {
        prev[curr.relatedType] = curr;
        return prev;
      }, {});
      return assignIn(related, itemRelated);
    }
  },
  methods: {
    ...mapActions('item', [
      'getItemCartRequest'
    ]),
    ...mapMutations('item', [
      'setItemInfoWish'
    ]),
    ...mapMutations('auth', [
      'setGuestInDialog'
    ]),
    ...mapMutations('common', [
      'setViewSearchForm',
      'setSearchHashtag'
    ]),
    changeRequestList () {
      if (this.requestForm.requestList.indexOf('Additional_Request') > -1 || this.requestForm.requestList.indexOf('ETC') > -1) {
        this.commentDisabled = false;
      } else {
        this.commentDisabled = true;
        this.requestForm.comment = ''
      }
    },
    checkedCart () {
      if (this.item.addedCart) {
        this.getItemCartRequest(this.item.id)
          .then((data) => {
            if (this.item.request) {
              this.requestForm.requestList = map(data.request.split(','), req => req.trim());
              this.requestForm.comment = data.comment;
            }
          })
      }
    },
    getRecapRequest () {
      recapService.getRecapItemRequest(this.recapId, this.item.id)
        .then(res => {
          if (res.data.request) {
            this.requestForm.requestList = map(res.data.request.split(','), req => req.trim());
            this.requestForm.comment = res.data.comment;
          }
        })
    },
    clickMoveToCart () {
      this.$router.push('/cart');
    },
    clickWish () {
      
      if (this.userInfo.companyInfo === null && !this.userInfo.admin) {
        this.setGuestInDialog(true)
      } else {
        this.wishLoading = true;
        let ajax;
        if (this.item.addedWish) {
          ajax = wishService.removeWishItems ([this.item.id]);
        } else {
          ajax = wishService.addWishItem(this.item.id);
        }
        ajax
          .then(() => {
            this.wishLoading = false;
            this.setItemInfoWish({
              addedWish: !this.item.addedWish,
              userInfo: this.userInfo
            });
          })
          .catch(() => {
            this.wishLoading = false;
          });
      }
    },
    placeholderTxt () {
      if (this.isRecap) {
        return ''
      } else if (this.selectedMenu.subMenu.type === 'GRAPHIC') {
        return 'Additional request (2D CAD, 3D FORM, ETC...)'
      } else {
        return 'Leave your comment if you have'
      }
    },
    clickHashtag (tag) {
      this.setSearchHashtag(tag.replace('#',''))
      this.setViewSearchForm(true)
      // this.$confirm('Do you want to search for items by that #hashtag? If you proceed, you will leave the current screen.', {
      //   confirmButtonText: 'YES',
      //   cancelButtonText: 'NO',
      //   confirmButtonClass: 'imsr-text-button',
      //   cancelButtonClass: 'imsr-text-button',
      //   showClose: false,
      // }).then(() => {
      //   this.setSearchHashtag(tag.replace('#',''))
      //   this.setViewSearchForm(true)
      // }).catch(() => {})
    },
    handleClick (id, showMenuId) {
      this.$router.push(`/graphic/12/${showMenuId}/trend/${id}`)
    },
  },
  data () {
    return {
      requestForm: {
        itemId: '',
        requestList: [],
        comment: ''
      },
      commentDisabled: true,
      wishLoading: false,
      trendList: []
    }
  },
  mounted () {
    this.requestForm.itemId = this.item.id;
    if (this.isRecap) {
      this.getRecapRequest();
    } else {
      this.checkedCart();
    }
    if (this.item.itemType === 'GRAPHIC') {
      this.trendList = this.trendList.concat(this.item.itemGraphic.trendList)
    }
  }
}
</script>

<style scoped>
.item-detail-container {
  padding: 10px 0;
  text-align: left;
}

.item-detail-container  div.row {
  padding: 10px 0;
}

.item-title {
  font-size: 24px;
  font-weight: bold;
}

.item-category {
  display: inline-block;
  background-color: #ccc; 
  line-height: 25px; 
  font-size: 12px;
  padding: 0px;
  width: 180px;
  text-align: center;
}

.wish-container {
  display: inline-block;
}

.wish-container > span {
  float: left;
}

.wish-container span.mdi {
  cursor: pointer;
}

.wish-container .wish-text {
  font-size: 16px; 
  display: inline-block; 
  padding: 2px 0; 
  margin-left: 5px;
}

.wish-container .wish-text .wish-count {
  margin: 0 5px; 
  font-weight: bold;
  cursor: pointer;
  border-bottom: 1px solid #232323;
}

.item-info-container .item-info-row {
  display: flex;
  line-height: 40px;
  margin: 2px 0;
}

.item-info-container .item-info-row .item-label {
  width: 215px;
  padding-right: 12px;
  font-weight: bold;
}

.item-info-container .item-info-row .item-value {
  flex: 1;
}

.item-tag {
  background-color: #eee;
  font-size: 12px;
  line-height: 20px;
  padding: 0 10px;
  border-radius: 10px;
  display: inline-block;
  margin-right: 10px;
}

.related-item {
  color: #98c8cc;
  cursor: pointer;
  border-bottom: 1px solid #98c8cc;
}

.related-item-none {
  color: #232323;
  pointer-events: none;
}

.gender-tag {
  display: inline-block;
  border: 1px solid #232323;
  border-radius: 10px;
  margin-right: 10px;
  line-height: 25px;
  padding: 0 10px;
}
</style>