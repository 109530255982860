<template>
  <div>
    <div class="imsr-content-top-container">
      <div class="imsr-content-container" style="min-width: 0;">
        <div class="imsr-container">
          <div class="imsr-page-title">
            <span>MY INSPIRATION</span>
          </div>
          <hr class="imsr-divider"/>
          <div style="display:flex; justify-content: space-between; padding-top: 21px;">
            <div style="font-size: 12px; padding: 3px 0; text-align: left;">
              <span>
                <el-checkbox :v-model="isAllChecked" @change="clickSelectAll">
                  <span class="imsr-text-button" style="font-size: 12px; padding: 0;">
                    SELECT ALL
                  </span>
                </el-checkbox>
              </span> 
              <span style="margin: 0 10px;">|</span>
              <span class="imsr-text-button" @click="clickRemoveItem">DELETE</span>
            </div>
            <el-button class="imsr-black-button" @click.prevent="showFileChooser">ADD INSPIRATION</el-button>
          </div>
        </div>
        <transition name="fade" appear="" v-if="this.imgSrc">
          <images-crop-pop v-bind:imageSrc="this.imgSrc" v-bind:imgName="this.imgName" @callCropLoading="callCropLoading" @callcropLoadingFalse="callcropLoadingFalse" />
        </transition>
      </div>
    </div>
    <div style="display: none;">
      <input
        ref="input"
        type="file"
        name="image"
        accept=".jpg, .png"
        @change="setImage"
      />
    </div>

    <div class="imsr-content-container">
      <div class="imsr-container imsr-item-list" v-loading="loading">
        <el-row :gutter="24" v-for="(row, index) in itemRows" :key="index">
          <el-col :span="6" class="imsr-style-wrap" style="padding: 20px 0;" v-for="item in row" :key="item.id">
            <div class="imsr-item-card">
              <div style="position: absolute; top: 10px; left: 10px; z-index: 10" class="large-checkbox">
                <el-checkbox v-model="item.checked" @change="changeItemChecked(item, $event)"></el-checkbox>
              </div>
              <el-card :body-style="{ padding: '0px' }" shadow="never" style="border: 0;">
                <div class="item-image-thumb"
                  :style="{'background-image': `url(${item.path})`}"
                  @click="clickThumb(item)">
                </div>
                <el-row style="padding: 10px 0;">
                  <el-col :span="14" style="line-height: 41px; text-align: left; font-size: 16px; font-weight: bold;">
                    {{ item.dateTitle }}
                  </el-col>
                  <el-col :span="10" style="text-align: right;">
                    <el-button class="imsr-white-button" @click="clickRequest(item)">REQUEST</el-button>
                  </el-col>
                </el-row>
              </el-card>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="24" style="margin-bottom: 48px;">
          <el-col :span="6" class="imsr-style-wrap" v-for="idx in emptyCount" :key="idx">
            <empty-item-card></empty-item-card>
          </el-col>
        </el-row>
        <imsr-no-content v-if="!loading && inspirationList.length === 0"></imsr-no-content>
      </div>
    </div>
  </div>
</template>

<script>
import EmptyItemCard from '@/components/common/EmptyItemCard'
import ImsrNoContent from '@/components/common/ImsrNoContent';
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { chunk, chain } from 'lodash'
import 'cropperjs/dist/cropper.css';
import ImagesCropPop from './ImagesCropPop.vue';

export default {
  components: {
    EmptyItemCard,
    ImsrNoContent,
    ImagesCropPop
  },
  computed: {
    ...mapGetters('common', [
      'pageBack'
    ]),
    ...mapGetters('inspiration', [
      'inspirationList',
      'listParam'
    ]),
    itemRows () {
      return chunk(this.inspirationList, this.countPerRow);
    },
    emptyCount () {
      if (this.listParam.page === 0) {
        return this.countPerRow;
      } else if (this.listParam.total > this.inspirationList.length) {
        return (this.listParam.total - this.inspirationList.length) % this.countPerRow;
      } 
      return 0;
    }
  },
  methods: {
    ...mapMutations('menu', [
      'setExtendFixedHeight'
    ]),
    ...mapMutations('common', [
      'setPageBack',
      'setFullImage'
    ]),
    ...mapMutations('inspiration', [
      'initParam',
      'addRequestItem',
      'clearAddedItems',
      'setItemChecked'
    ]),
    ...mapActions('inspiration', [
      'getInspirationList',
      'removeInspiration'
    ]),
    clickSelectAll (checked) {
      this.setItemChecked({ checked });
    },
    clickRemoveItem () {
      const ids = chain(this.inspirationList)
        .filter(item => item.checked)
        .map(item => item.id)
        .value();
      
      if (ids.length === 0) {
        return false;
      }
      this.loading = true;
      this.removeInspiration(ids)
        .then(() => {
          this.getInspirationList()
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$message({
            type: 'error',
            message: `Failed`
          });
        })
    },
    changeItemChecked (item, checked) {
      this.setItemChecked({
        id: item.id,
        checked
      });
    },
    clickThumb (item) {
      this.setFullImage({
        src: item.path
      });
    },
    clickRequest (item) {
      this.addRequestItem(item);
      this.setPageBack({
        to: 'inspiration'
      });
      this.$router.push('/inspiration/form');
    },
    // clickAdd () {
    //   this.$alert('Only JPG or PNG is available.', {
    //     confirmButtonText: 'OK',
    //     showClose: false,
    //     confirmButtonClass: 'imsr-text-button',
    //   }).then(() => {
    //     this.$router.push('/inspiration/images');
    //   })
    //   this.$router.push('/inspiration/images');
    // },
    setImage(e) {
      const file = e.target.files[0];
      if (file) { this.imgName = file.name}
      if (typeof FileReader === 'function') {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          this.imgCropName = file.name
        };

        reader.readAsDataURL(file);
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    callCropLoading(val) {
      if (val) {this.loading = true}
    },
    callcropLoadingFalse(val) {
      if (val) {this.loading = false}
    }
  },
  data () {
    return {
      countPerRow: 4,
      loading: true,
      isAllChecked: false,
      imgSrc: '',
      imgCropName: '',
      imgName: '',
    }
  },
  mounted () {
    this.setExtendFixedHeight(110);
    if (!this.pageBack.isBack && this.pageBack.to !== 'inspiration') {
      this.initParam();
      this.getInspirationList(1)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    } else {
      this.clearAddedItems();
      this.loading = false;
    }
    
  }
}
</script>

<style scoped>
.imsr-item-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.imsr-item-list .imsr-style-wrap {
  display: flex;
  justify-content: center;
}

.imsr-style-wrap .item-image-thumb {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 370px;
  height: 370px;
  cursor: pointer;
}
</style>