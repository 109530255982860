<template>
  <div class="imsr-container imsr-item-list" style="margin-top: 50px;">
		<div style="display: flex; justify-content: center;" v-loading="loading">
			<div style="width: 750px; height: 750px; border: 1px solid #eee; position: relative;" v-loading="!loading && loading3D">
        <iframe ref="renderDiv" name='renderDiv' style="width: 100%; height: 100%; border: 0;" @load="iframeLoad">
        </iframe>
        <transition name="fade">
          <studio-info-pop v-if="isToast"></studio-info-pop>
        </transition>
        <div style="position: absolute; bottom: 0; left: -90px;">
          <div class="color-wrap" v-for="colorItem in this.colorList[this.subMenuId]" :key="colorItem"
            :class="{active: color === colorItem}"
            @click="clickColor(colorItem)" >
            <div :style="{'background-color': colorItem}"></div>
          </div>
				</div>
        <div style="position: absolute; top: 0; left: -90px;">
          <div class="refresh-button" @click="initRefresh">
            <i class="el-icon-refresh-right"></i>
          </div>
        </div>
			</div>
      <div style="position: absolute; left: 1200px;">
        <span class="mdi mdi-information-outline mdi-24px" @click="guidePopView"></span>
      </div>
		</div>
		<div style="margin-top: 20px;">
			<el-button class="imsr-white-button" style="border-width: 1px; width: 370px;" @click="clickExport">EXPORT</el-button>
		</div>
		<hr class="imsr-divider" style="margin: 30px 0;"/>
    <div style="margin: 30px 0 10px 0;">
      <el-row v-for="(row, index) in studioImages" :key="index">
        <el-col :span="4" v-for="item in row" :key="item.id">
          <div class="studio-item">
            <div class="studio-item-image"
              :style="{'background-image': `url(${item.src})`}"></div>
            <div style="font-size: 12px;">Made at {{ item.date | moment('MM.DD.YYYY HH:mm:A')}}</div>
          </div> 
        </el-col>
      </el-row>
    </div>
    <form ref="form" name="form" :action="this.baseUrl + '/api/v1/cmm/studio/render'" method="post" target="renderDiv">
        <input type="hidden" name="mockupPath" :value="selectedMockUp.objectPath"/>
        <input type="hidden" name="mockupHeight" :value="selectedMockUp.height"/>
        <input type="hidden" name="mockupWidth" :value="selectedMockUp.width"/>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { assignIn, chunk } from 'lodash'
import StudioInfoPop from './StudioInfoPop.vue'

export default {
  name: 'VisualStudio',
  components: {
    StudioInfoPop
  },
  watch: {
    isToast() {
      if (this.isToast) {
        setTimeout(() => {
            this.isToast = !this.isToast
          }, 2000)
      }
    },
  },
  computed: {
    ...mapGetters('visualStudio', [
      'paletteList',
      'selectedMockUp',
      'visualStudioItems',
      'selectedGraphicItem',
      'selectedPatternItem'
    ]),
    ...mapGetters('common', [
      'studioGuideDialogVisible',
      'itemDel'
    ]),
    studioImages () {
      return chunk(this.visualStudioItems, 6);
    }
  },
  methods: {
    ...mapMutations('visualStudio', [
      'setSelectedGraphicItem',
      'setSelectedPatternItem',
      'setVisualStudioItems',
    ]),
    ...mapMutations('common', [
      'setStudioGuideDialogVisible',
      'setItemDel'
    ]),
    setPatten (item) {
      this.setSelectedPatternItem(item);
      this.color = '';
      this.$refs.renderDiv.contentWindow.postMessage({
        type: 'setPattern', path: item.studioImagePath
      }, "*");
    },
    setPlacement (item) {
      this.setSelectedGraphicItem(item);
      this.$refs.renderDiv.contentWindow.postMessage({
        type: 'setPlacement', path: item.studioImagePath
      }, "*");
    },
    iframeLoad () {
      this.loading = false;
    },
    clickPaletteItem (item) {
      if (!this.itemDel) {
        if (item.graphicType === 'ALL_OVER_PRINT') {
          this.setPatten(item);
        } else {
          this.setPlacement(item);
          this.isToast = true;
        }
      }
      this.setItemDel(false)
    },
    clickColor (color) {
      if (this.color === color) {
        return false;
      }
      if (this.selectedPatternItem) {
        this.$refs.renderDiv.contentWindow.postMessage({
          type: 'clearPattern'
        }, "*");
        this.setSelectedPatternItem(undefined);
      }

      this.color = color;
      this.$refs.renderDiv.contentWindow.postMessage({
        type: 'setColor', color
      }, "*");
    },
    receiveMessage (e) {
      if (e.data.type === 'getRenderData') {
        const data = e.data.data;
        if (!data.graphic && !data.pattern) {
          this.loading = false;
          this.$alert('Nothing to export. Please select any items in PALETTE.', '', {
            confirmButtonText: 'OK',
            showClose: false,
            confirmButtonClass: 'imsr-text-button',
          })
        } else {
          this.exportData = {
            data
          };
          this.$refs.renderDiv.contentWindow.postMessage({type: 'exportImage'}, "*");
        }
      } else if (e.data.type === 'exportImage') {
        this.exportData = assignIn(this.exportData, {
          id: new Date().getTime(),
          src: e.data.data,
          date: new Date(),
          pattern: this.selectedPatternItem,
          graphic: this.selectedGraphicItem
        });
        this.$toasted.show('Exported Successfully.', {
          position: 'bottom-center',
          duration : 3000
        });
        this.setVisualStudioItems(this.exportData);
        this.loading = false;
      } else if (e.data.type === 'onload') {
        console.log(e.data)
        if (e.data.loading) {
          this.loading3D = true;
        } else {
          this.loading3D = false;
        }
      }
    },
    initRefresh () {
      this.setSelectedGraphicItem();
      this.setSelectedPatternItem();
      this.color = '';
      this.$refs.renderDiv.contentWindow.postMessage({
        type: 'clearPattern'
      }, "*");
      this.$refs.renderDiv.contentWindow.postMessage({
        type: 'clearPlacement'
      }, "*");
      this.$refs.renderDiv.contentWindow.postMessage({
        type: 'setColor'
      }, "*");
    },
    initObj () {
      this.setSelectedGraphicItem();
      this.setSelectedPatternItem();
      this.color = '';
      this.$refs.form.submit();
    },
    clickExport () {
      this.$confirm('Export the image?', '', {
          confirmButtonText: 'OK',
          cancelButtonText: 'CANCEL',
          confirmButtonClass: 'imsr-text-button',
          cancelButtonClass: 'imsr-text-button',
          showClose: false
        }).then(() => {
          this.loading = true;
          this.exportData = {};
          this.$refs.renderDiv.contentWindow.postMessage({type: 'getRenderData'}, "*");
        });
    },
    guidePopView () {
      this.setStudioGuideDialogVisible(true);
    },
  },
  beforeRouteLeave (to, from, next) {
    if (to.path.indexOf('/3D/export') === -1 && !this.isEmpty) {
      this.$confirm('Discard all edit and Leave this page?', '', {
          confirmButtonText: 'OK',
          cancelButtonText: 'CANCEL',
          confirmButtonClass: 'imsr-text-button',
          cancelButtonClass: 'imsr-text-button',
          showClose: false
        }).then(() => {
          next();
        })
        .catch(() => {
          // next(false);
        });
    } else {
      next();
    }
  },
	data () {
		return {
      loading: true,
      baseUrl: require("../../config/user.config.json")[process.env.NODE_ENV].VUE_APP_API_SERVER_HOST,
			colorList: {
        54: ['#FF5C00', '#FFADA3', '#957BC3', '#86986D', '#131B41'],
        55: ['#1C1D1C', '#346B7A', '#E2807D', '#DADBDC', '#4682C9'],
        56: ['#F5F3EA', '#FDBACC', '#D2BFEC', '#78A7D7', '#222F47'],
        57: ['#E9E4D6', '#1C1D1C', '#E4F482', '#E87E72', '#4682C9'],
        58: ['#F5ECDB', '#FFC7CF', '#C2DB8C', '#0033C3', '#282827'],
      },
      color: '',
      exportData: {},
      isEmpty: false,
      loading3D: true,
      subMenuId: this.$router.history.current.params.subMenuId,
      isGuide: false,
      isToast: false
		}
	},
  created () {
    if (!this.selectedMockUp.id) {
      this.isEmpty = true;
      const path = this.$route.path.replace('studio', 'palette');
      this.$router.replace(`${path}`);
    }
  },
  mounted () {
    this.isGuide = true
    if (this.isGuide === true && this.$cookies.get("popupFlag") !== "Y") {
      this.guidePopView()
    }
    this.initObj();
    window.addEventListener('message', this.receiveMessage)
  },
  beforeDestroy () {
    window.removeEventListener('message', this.receiveMessage)
  }
}
</script>

<style scoped>
.color-wrap {
	margin-top: 10px;
	border: 3px solid transparent;
  cursor: pointer;
}

.color-wrap > div {
  width: 45px;
	height: 45px;
  border: 1px solid #666;
}

.color-wrap.active {
  border-color: #98c8cc;
  cursor: default;
}

.refresh-button {
  width: 45px;
  height: 45px;
  border: 1px solid #fd1515;
  color: #fd1515;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  cursor: pointer;
}

.studio-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
}

.studio-item-image {
  width: 230px;
  height: 230px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.toast-custom {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #fd1515;
}

.fade-enter-active {
  transition: opacity 1s ease-in-out;
}
.fade-enter-to {
  opacity: 1;
}
.fade-enter {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>