<template>
  <div>
    <div class="imsr-content-top-container">
      <div class="imsr-content-container" style="min-width: 0;">
        <div class="imsr-container">
          <div class="imsr-page-title">
            <div class="imsr-text-button left-container" @click="clickBack">
              <span class="mdi mdi-arrow-left mdi-12px"></span>
              <span style="font-size: 12px;"> BACK</span>
            </div>
            <span>RECAP</span>
            <div class="right-container"></div>
          </div>
          <hr class="imsr-divider"/>
        </div>
      </div>
    </div>

    <div class="imsr-content-container">
      <div class="imsr-container" style="display: flex" v-loading="loading">
        <div style="flex: 1; margin-right: 30px;">
          <el-table
            ref="reacpTable"
            :data="recapDetail.itemList"
            row-key="id"
            @row-click="handleClick">
            <el-table-column
              label="IMAGE"
              width="110"
              align="center">
              <template slot-scope="scope">
                <div class="item-thumb-wrap" 
                  style="width: 90px; height: 90px; display: flex; align-items: center;">
                  <img :src="`${$resizeServer}92-${scope.row.itemRepImg.fileKey}`" 
                    style="max-width: 90px; min-width: 90px;">
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="ITEM NO."
              prop="item.itemNo"
              align="center"
              width="150">
            </el-table-column>
            <el-table-column
              label="CATEGORY"
              width="180"
              align="center">
              <template slot-scope="scope">
                <div class="style-item-category">{{scope.row.category_name}}</div>
              </template>
            </el-table-column>
            <el-table-column
              label="TITLE"
              prop="item.title"
              align="center">
            </el-table-column>
            <el-table-column
              label="FULL DESCRIPTION"
              prop="item.info"
              width="200"
              align="center">
            </el-table-column>
            <el-table-column
              label="REQUEST"
              width="300"
              align="center">
              <template slot-scope="scope">
                <div style="text-align: center; margin: 8px 0;" v-for="code in scope.row.requestCodes" :key="code.code">
                  {{code.desc}}
                </div>
                <div style="white-space: pre-wrap; text-align: left;">{{ scope.row.comment }}</div>
              </template>
            </el-table-column>
          </el-table>

          <div style="text-align: left; margin-top: 50px;">
            <div style="font-size: 12px; font-weight: bold; margin: 10px 0;">COMMENT</div>
            <contenteditable
              class="additional-info recap-new-comment" 
              style="min-height: 100px; width: 1188px;"
              tag="div"
              placeholder="Leave a comment"
              :contenteditable="true" 
              v-model="newComment"/>
            <div style="text-align: right; margin: 10px 0;">
              <el-button class="imsr-button" style="width: 370px;" :disabled="newComment === ''"
                @click="clickAddComment">ADD</el-button>
            </div>
          </div>

          <div style="text-align: left; margin-top: 50px;">
            <div v-for="comment in recapComment.list" :key="comment.no" style="margin-bottom: 30px; border-bottom: 1px solid #232323">
              <el-row style="padding: 10px 0;">
                <el-col :span="12" style="font-weight: bold;">{{comment.userFirstName}}</el-col>
                <el-col :span="12" style="text-align: right; color: #999;" v-if="comment.createDate">
                  {{ comment.createDate | moment('MM.DD.YYYY HH:mm:A')}}
                </el-col>
              </el-row>
              <div style="white-space: pre; padding: 10px 0 30px 0; line-height: 20px;">{{ comment.comment }}</div>
            </div>
          </div>
        </div>
        
        <div style="width: 370px; ">
          <div style="font-weight: bold; padding: 14px 0; font-size: 12px; line-height: 20px;">RECAP INFO</div>
          <hr class="imsr-divider"/>
          <div style="text-align: left; padding: 15px 0; font-size: 12px;">
            <el-row style="padding: 7px 0;">
              <el-col :span="10" style="font-weight: bold; line-height: 14px;">COMPANY</el-col>
              <el-col :span="14">
                {{recapDetail.companyName || '-' }}
              </el-col>
            </el-row>
            <el-row style="padding: 7px 0;">
              <el-col :span="10" style="font-weight: bold; line-height: 14px;">MEMBER</el-col>
              <el-col :span="14">{{recapDetail.users.firstName}} {{recapDetail.users.lastName}}</el-col>
            </el-row>
            <el-row style="padding: 7px 0;">
              <el-col :span="10" style="font-weight: bold; line-height: 14px;">RECAP DATE</el-col>
              <el-col :span="14">
                {{ recapDetail.createDate | moment('MM.DD.YYYY HH:mm:A') }}
              </el-col>
            </el-row>
            <el-row style="padding: 7px 0;">
              <el-col :span="10" style="font-weight: bold; line-height: 14px;">DUE DATE</el-col>
              <el-col :span="14">{{ recapDetail.dueDate | moment('MM.DD.YYYY') }}</el-col>
            </el-row>
          </div> 
          <div>
            <form ref="downloadForm" method="get" :action="recapDetail.pdfFilePath">
              <el-button class="imsr-white-button" style="width: 100%;" :disabled="!recapDetail.pdfFileKey || recapDetail.pdfFileKey === ''"
                @click="$refs.downloadForm.submit()">DOWNLOAD PDF</el-button>
            </form>
          </div>            
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapGetters('common', [
      'pageBack'
    ]),
    ...mapGetters('recap', [
      'recapDetail',
      'recapComment',
      'listParam'
    ])
  },
  methods: {
    ...mapMutations('menu', [
      'setExtendFixedHeight'
    ]),
    ...mapMutations('common', [
      'setPageBack'
    ]),
    ...mapMutations('recap', [
      'setRecapInfo'
    ]),
    ...mapActions('recap', [
      'getRecapList',
      'getRecapInfo',
      'getRecapComment',
      'addRecapComment',
      'clearOtherComment'
    ]),
    clickBack () {
      if (this.pageBack.to) {
        this.setPageBack({
          isBack: true,
          fromType: 'recap'
        });
        this.$router.go(-1);
      } else {
        this.$router.push('/mypage/recap');
      }
    },
    clickAddComment () {
      this.loading = true;
      this.addRecapComment({
          recapId: this.recapDetail.id, 
          comment: this.newComment
        })
        .then(() => {
          this.getRecapList(this.getRecapList.page);
          this.newComment = '';
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$message({
            message: 'comment 등록 실패.',
            type: 'error'
          });
        });
    },
    handleClick (row) {
      this.setPageBack({
        to: 'recap'
      });
      this.$router.push(`${this.$route.path}/${row.item.itemType.toLowerCase()}/${row.itemId}`);
    }
  },
  data () {
    return {
      userInfo: {},
      recap: {
        users: {},
        itemList: []
      },
      loading: true,
      newComment: ''
    }
  },
  mounted () {
    this.setExtendFixedHeight(75);
    if (!(this.pageBack.isBack && this.pageBack.to === 'recap')) {
      this.setRecapInfo({
        users: {},
        itemList: []
      });
      const recapId = this.$route.params.recapId;
      this.getRecapComment(recapId);
      this.getRecapInfo(recapId)
        .then(() => {
          this.loading = false;
          this.clearOtherComment(recapId);
        });
    } else {
      this.loading = false;
    }
  }
}
</script>

<style>

</style>