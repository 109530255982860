<template>
  <div>
    <div class="imsr-content-top-container">
      <div class="imsr-container" >
        <div class="imsr-page-title" style="display: flex;">
          <div class="" style="line-height: 20px; flex: 1;">
          </div>
          <span style="flex: 1;">WISH LIST</span>
          <div class="" style="line-height: 20px; flex: 1; font-size: 12px; text-align: right;">
            <span class="imsr-text-button" :class="{active: menu === 'my'}" 
              style="margin-right: 20px;"
              @click="changeMyPageMenu('my')">
              MY WISH
            </span>
            <span class="imsr-text-button" :class="{active: menu === 'team'}"
              style="margin-right: 20px;"
              @click="changeMyPageMenu('team')">
              TEAM WISH
            </span>
            <span class="imsr-text-button" :class="{active: menu === 'saved'}"
              @click="changeMyPageMenu('saved')">
              SAVE FOR LATER
            </span>
          </div>
        </div>
        <hr class="imsr-divider"/>
      </div>
    </div>
    <div class="imsr-content-container">
      <div class="imsr-container">
        <router-view :menu="menu"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  computed: {
  },
  methods: {
    ...mapMutations('menu', [
      'setExtendFixedHeight'
    ]),
    changeMyPageMenu (menuName) {
      if (this.menu !== menuName) {
        this.$router.replace(`/wish/${menuName}`);
      }
    }
  },
  data () {
    return {
      menu: ''
    }
  },
  mounted() {
    this.setExtendFixedHeight(130);
  },
  created () {
    const idx = this.$route.path.lastIndexOf('/');
    this.menu = this.$route.path.substring(idx + 1);
  }
}
</script>

<style scoped>

</style>