<template>
  <div style="height: 100%; display: flex; flex-direction: column;">
    <div class="imsr-content-top-container" style="margin-bottom: 30px;">
      <div class="imsr-container">
        <div style="display: flex;">
          <div style="font-size: 12px; padding: 20px 0; text-align: left; flex: 1;">
            <span>
              <el-checkbox v-model="isAllChecked" @change="clickSelectAll">
                <span class="imsr-text-button" style="font-size: 12px; padding: 0;">
                  SELECT ALL
                </span>
              </el-checkbox>
            </span> 
            <span style="margin: 0 10px;">|</span>
            <span class="imsr-text-button" @click="clickRemoveSaved">DELETE</span>
          </div>
          <div style="flex: 1; text-align: right;">
            <div class="item-top-button-group" style="padding: 20px 0;">
              VIEW BY 
              <span class="imsr-text-button" 
                :class="{active: !listParam.itemType}"
                @click="changeViewType()">ALL</span> 
              <span>|</span>
              <span class="imsr-text-button" 
                :class="{active: listParam.itemType === 'STYLE'}"
                @click="changeViewType('STYLE')">STYLE</span> 
              <span>|</span> 
              <span class="imsr-text-button" 
                :class="{active: listParam.itemType === 'FABRIC'}"
                @click="changeViewType('FABRIC')">FABRIC</span>
              <span>|</span> 
              <span class="imsr-text-button" 
                :class="{active: listParam.itemType === 'GRAPHIC'}"
                @click="changeViewType('GRAPHIC')">GRAPHIC</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="imsr-content-container" stlye="flex: 1;">
      <div class="imsr-container" style="display: flex" v-loading="loading">
        <div style="flex: 1; height: 100%;">
          <el-row :gutter="40" style="margin-bottom: 48px;" v-for="(rows, index) in itemRows" :key="index">
            <el-col :span="6" class="imsr-style-wrap" v-for="cart in rows" :key="cart.id" style="margin-top: 30px;">
              <div class="imsr-item-card">
                <div style="position: absolute; top: 10px; left: 10px; z-index: 5" class="large-checkbox">
                  <el-checkbox v-model="cart.checked" @change="changeCartChecked(cart, $event)"></el-checkbox>
                </div>
                <el-card :body-style="{ padding: '0px' }" shadow="never" style="border: 0;">
                  <div class="item-image-thumb"
                    :style="{'background-image': `url(${$resizeServer}792-${cart.itemRepImg.fileKey})`}"
                    @click="clickThumb(cart)">
                  </div>
                  <div class="item-style-item-desc">
                    <div class="item-sample">
                      <span>{{ cart.category_name || '-' }}</span>
                    </div>
                    <span class="item-title">{{ cart.cartItem.title }}</span>
                    <div class="primary-color" style="min-height: 18px;">{{ cart.cartItem.itemNo }}</div>
                  </div>
                  <div>
                    <el-form :model="cart" label-position="left" label-width="0px">
                      <el-form-item>
                        <el-checkbox-group v-model="cart.requestList">
                          <el-row>
                            <el-col :span="12" style="text-align: left;" v-for="request in requestAllCodes[cart.cartItem.itemType]" :key="request.code">
                              <el-checkbox :label="request.code" disabled>{{request.desc}}</el-checkbox>
                            </el-col>
                          </el-row>
                        </el-checkbox-group>
                        <el-input
                          resize="none"
                          type="textarea"
                          :rows="3"
                          disabled
                          v-model="cart.comment">
                        </el-input>
                      </el-form-item>
                    </el-form>
                    <el-row :gutter="12">
                      <el-col :span="24">
                        <el-button class="imsr-button" style="width: 100%;"
                          @click="clickAddToCart(cart)">ADD TO CART</el-button>
                      </el-col>
                    </el-row>
                  </div>
                </el-card>
              </div>
            </el-col>
          </el-row>
          
          <infinite-loading ref="infiniteLoading" spinner='default' @infinite="infiniteHandler">
            <div slot="spinner">
              <el-row :gutter="24" style="margin-bottom: 48px;">
                <el-col :span="6" class="imsr-style-wrap" v-for="idx in emptyCount" :key="idx">
                  <empty-item-card></empty-item-card>
                </el-col>
              </el-row>
            </div>
            <div slot="no-more"></div>
            <div slot="no-result"></div>
            
          </infinite-loading>
        </div>
      </div>
    </div>
    <imsr-no-content v-if="!loading && listParam.total === 0"></imsr-no-content>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import EmptyItemCard from '@/components/common/EmptyItemCard'
import ImsrNoContent from '@/components/common/ImsrNoContent'
import { chunk } from 'lodash'

export default {
  components: {
    ImsrNoContent,
    EmptyItemCard
  },
  computed: {
    ...mapGetters('auth', [
      'userInfo',
    ]),
    ...mapGetters('cart', [
      'cartList',
      'listParam',
      'requestAllCodes',
      'checkedCartItem'
    ]),
    emptyCount () {
      if (this.listParam.page === 0) {
        return this.countPerRow;
      } else if (this.listParam.total > this.cartList.length) {
        return (this.listParam.total - this.cartList.length) % this.countPerRow;
      } 
      return 0;
    },
    itemRows () {
      return chunk(this.cartList, this.countPerRow);
    },
  },
  methods: {
    ...mapMutations('auth', [
      'setGuestInDialog'
    ]),
    ...mapActions('cart', [
      'getCartList',
      'removeFromCart',
      'saveForLater',
      'modifyCartRequest'
    ]),
    ...mapMutations('cart', [
      'setCartChecked',
      'initList',
      'setListParam'
    ]),
    // 아이템 클릭 시 아이템 상세 조회
    clickThumb ({ cartItem }) {
      // this.setCartChecked({ cartId: cart.id, checked: !cart.checked });
      this.$router.push(`${this.$route.path}/${cartItem.itemType.toLowerCase()}/${cartItem.id}`);
    },
    // 전체 선택 클릭 시
    clickSelectAll (checked) {
      this.setCartChecked({ checked });
    },
    // 아이템 선택 시
    changeCartChecked (item, checked) {
      this.setCartChecked({ cartId: item.id, checked });
    },
    getCartListByPage (page) {
      this.getCartList(page)
        .then(() => {
          this.loading = false;
        })
    },
    clickRemoveSaved () {
      this.$confirm('Delete selected items?', '', {
          confirmButtonText: 'OK',
          cancelButtonText: 'CANCEL',
          confirmButtonClass: 'imsr-text-button',
          cancelButtonClass: 'imsr-text-button',
          showClose: false
        }).then(() => {
          this.loading = true;
          return this.removeFromCart()
        }).then(() => {
          this.loading = false;
        })
        .catch((err) => {
          if (err !== 'cancel') {
            this.loading = false;
            this.$message({
              message: 'Save for later 아이템 삭제 실패.',
              type: 'error'
            });
          }
        });
    },
    clickAddToCart (item) {
      if (this.userInfo.companyInfo === null && !this.userInfo.admin) {
        this.setGuestInDialog(true)
      } else {
        this.loading = true;
        this.saveForLater({
            cartIds: [item.id],
            forLater: false
          })
          .then(() => {
            this.loading = false;
            this.$toasted.show('Added to cart Successfully.', {
              position: 'bottom-center',
              duration : 3000
            });
          })
          .catch(() => {
            this.loading = false;
            this.$message({
              message: 'Add to cart 실패.',
              type: 'error'
            });
          });
      }
    },
    changeViewType (type) {
      if (type === this.listParam.itemType) {
        return false;
      }
      this.initList({
        forLater: true
      });
      
      this.loading = true;
      
      this.$refs.infiniteLoading.stateChanger.reset();
      if (type) {
        this.setListParam({
          itemType: type
        })
      } else {
        this.setListParam({});
      }
    },
    infiniteHandler ($state) {
      this.getCartList(this.listParam.page + 1)
        .then(() => {
          $state.loaded();
          if (this.cartList.length === this.listParam.total) {
            $state.complete();
          }
          this.loading = false;
        })
        .catch(() => {
          $state.error();
          this.loading = false;
        });
    }
  },
  data () {
    return {
      checked: false,
      loading: true,
      edit: {},
      itemLoading: false,
      isAllChecked: false,
      countPerRow: 4
    }
  },
  mounted () {
  },
  created () {
    this.initList({
      forLater: true
    });
  }
}
</script>

<style scoped>
.item-top-button-group > span {
  padding-left: 13px;
}

.imsr-item-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.imsr-style-wrap {
  display: flex;
  justify-content: center;
}

.imsr-style-wrap .item-image-thumb {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 370px;
  height: 370px;
  cursor: pointer;
}

.item-style-item-desc {
  display: flex;
  justify-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8px 10px;
}

.item-title {
  font-size: 16px;
  font-weight: bold;
  padding: 8px;
}

.item-sample > span {
  width: 200px;
  background-color: #ccc;
  text-align: center;
  display: inline-block;
  line-height: 24px;
}



</style>