<template>
  <div>
    <div style="height: 792px; width: 792px; border: 1px solid #efefef;">
      <el-carousel 
        ref="itemAddCarousel"
        indicator-position="none" 
        height="792px" 
        :autoplay="false" 
        trigger="click" 
        @change="changeCarousel">
        <el-carousel-item :name="img.name" v-for="img in selectedImage" :key="img.id"  @click.right.native="contextmenu($event)">
          <div class="watermark" v-if="(itemType === 'GRAPHIC' || itemType === 'graphic') && img.type === 'IMG'" @click="viewFullImage(img, true)"></div>
          <div v-if="img.type !== 'MOV'" 
            class="carousel-image"
            style="width: 100%; height: 100%;"
            :style="{'background-image': 'url(' + (img.thumb792Src || img.src) + ')'}" 
            @click="viewFullImage(img)">
          </div>
          <div v-else-if="img.isNew"
            class="carousel-image"
            style="width: 100%; height: 100%; background-size: auto;"
            :style="{'background-image': 'url(' + require('@/assets/movie_thumb.png') + ')'}">
          </div>
          <div v-else>
            <video :ref="`mov_${img.id}`" loop width="100%" height=792 :src="img.src"></video>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div v-if="canFullView" style="text-align: left; padding: 20px 0;">
      Click To Enlarge Image
    </div>
    <div v-else-if="isStudioImage" style="margin: 20px;">
      <el-button class="imsr-white-button" style="width: 370px;" @click="$emit('click3DView')">3D VIEWER</el-button> 
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  props: ['selectedImage', 'viewMode', 'itemType', 'isStudioImage'],
  computed: {
    canFullView () {
      return this.viewMode && !this.playId && !this.isStudioImage;
    }
  },
  methods: {
    ...mapMutations('common', [
      'setFullImage'
    ]),
    setActiveItem (value) {
      this.$refs.itemAddCarousel.setActiveItem(value);
    },
    viewFullImage (img, watermark) {
      if (this.canFullView) {
        this.setFullImage({
          img, watermark 
        });
      }
    },
    clearPlay () {
      if (this.playId) {
        this.$refs[this.playId][0].pause();
        this.playId = undefined;
      }
    },
    play (item) {
      this.playId = `mov_${item.id}`;
      const targetVideo = this.$refs[this.playId][0];
      targetVideo.currentTime = 0;
      targetVideo.play();
    },
    playVideo (idx) {
      const item = this.selectedImage[idx];
      this.clearPlay();
      
      if (item.type === 'MOV' && !item.isNew) {
        this.play(item);
      }
    },
    changeCarousel ($event) {
      this.playVideo($event);
      this.$emit('changeCarousel', $event)
    },
    contextmenu ($event) {
      $event.preventDefault();
      $event.stopPropagation();
      return false;
    }
  },
  data () {
    return {
      playId: undefined
    }
  }
}
</script>

<style scoped>

.carousel-image {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.watermark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('~@/assets/watermark_792.png');
  opacity: 1;
}

</style>