<template>
  <div>
    <div class="imsr-content-top-container">
      <div class="imsr-content-container" style="min-width: 0;">
        <div class="imsr-container">
          <div class="imsr-page-title">
            <span>FAQS</span>
          </div>
          <hr class="imsr-divider"/>
        </div>
      </div>
    </div>
    <div class="imsr-content-container" style="height: 100%;">
      <div class="imsr-container">
        <el-table
          v-loading="loading"
          :data="list.map((x, i)=> ({ ...x, idx: (listParam.page - 1) * 10 + i + 1}))"
          @row-click="handleClick">

          <el-table-column
            label="NO."
            prop="idx"
            width="200"
            align="center">
          </el-table-column>

          <el-table-column
            label="TITLE"
            prop="title"
            align="left">
          </el-table-column>

        </el-table>
        <div style="margin-top: 50px;">
          <el-pagination
            layout="prev, pager, next"
            :total="listParam.total"
            :page-size="listParam.size"
            :current-page="listParam.page"
            @current-change="getListByPage">
          </el-pagination>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapGetters('common', [
      'pageBack'
    ]),
    ...mapGetters('faq', [
      'list',
      'listParam'
    ])
  },
  methods: {
    ...mapMutations('menu', [
      'setExtendFixedHeight'
    ]),
    ...mapMutations('faq', [
      'initParam'
    ]),
    ...mapMutations('common', [
      'setPageBack'
    ]),
    ...mapActions('faq', [
      'getFaqList'
    ]),
    getListByPage (page) {
      this.loading = true;
      this.getFaqList(page)
        .finally(() => {
          this.loading = false;
        });
    },
    handleClick (item) {
      this.setPageBack({
        to: 'faq'
      })
      this.$router.push(`/faq/${item.no}`);
    }
  },
  data () {
    return {
      loading: true
    }
  },
  mounted () {
    this.setExtendFixedHeight(70);
  },
  created () {
    if (!(this.pageBack.isBack && this.pageBack.to === 'faq')) {
      this.initParam();
      this.getListByPage(1);
    } else {
      this.loading = false;
    }
  }
}
</script>

<style>

</style>