<template>
  <div class="imsr-container imsr-item-list" style="margin-top: 10px;" v-loading="loading">
    <el-row :gutter="24" style="margin-bottom: 48px;">
      <el-col :span="6" class="imsr-style-wrap" v-for="item in mockupList" :key="item.id" style="margin-bottom: 20px;">
        <div style="display: flex; justify-content: center;">
          <div class="mockup-item"
            :style="{'background-image': 'url(' + item.thumbPath + ')'}"
            @click="clickMockUp(item)">
          </div>
        </div>
        <div style="padding: 10px;">{{item.name}}</div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import mockUpService from '@/services/mockUpService'
import { mapMutations } from 'vuex';

export default {
  props: ['prefix'],
  methods: {
    ...mapMutations('visualStudio', [
      'setSelectedMockUp'
    ]),
    clickMockUp (item) {
      this.setSelectedMockUp(item);
      this.$router.push(`${this.prefix}/3D/studio`);
    }
  },
  data () {
    return {
      mockupList: [],
      loading: true
    }
  },
  mounted () {
    mockUpService.getMockupList({
        size: 20,
        page: 0,
        sort: 'createDate,desc',
        menuId: this.$route.params.subMenuId
      })
      .then(res => {
        this.mockupList = res.data.list;
        this.loading = false;
      });
  }
}
</script>

<style>
.mockup-item {
  width: 370px;
  height: 370px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}

.mockup-item:hover {
  background-color: #fafafa;
}

</style>