import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import common from './modules/common'
import menu from './modules/menu'
import cart from './modules/cart'
import recap from './modules/recap'
import wish from './modules/wish'
import inspiration from './modules/inspiration'

import trend from './modules/trend'
import item from './modules/item'
import best from './modules/best'

import visualStudio from './modules/visualStudio'

import searchItem from './modules/searchItem'
import faq from './modules/faq'

Vue.use(Vuex)

export default new Vuex.Store({
  actions: {
    globalNotiAction ({dispatch}, cnt) {
      dispatch('common/commonNotiAction', cnt)
    }
  },
  modules: {
    auth,
    menu,
    common,
    cart,
    recap,
    wish,
    inspiration,
    trend,
    item,
    best,
    visualStudio,
    searchItem,
    faq
  }
});