<template>
<div class="el-dialog__wrapper;">
  <el-dialog
    title=""
    :visible="this.imgSrcData.length > 1"
    :show-close="false"
    width="1036px">
    <div style="position: relative; padding: 0;">
      <div class="content">
        <section class="cropper-area">
          <div class="img-cropper">
            <vue-cropper
              ref="cropper"
              v-if="this.imgSrcData.length > 1"
              :view-mode="1"
              :aspect-ratio="1 / 1"
              :src="this.imgSrcData"

              accept="image/jpg, image/jpeg, image/png"
            />
          </div>
        </section>
        <div>
          <el-button class="imsr-white-button" style="width: 372px; color: #232323; margin-left: 10px; margin-bottom: 10px;" @click="sendCropImage">
            SAVE
          </el-button>
          <el-button class="imsr-black-button" style="width: 372px;" @click="clickBack">BACK</el-button>
        </div>
        <img src="" ref="testImgRef" />
      </div>
    </div>
  </el-dialog>
</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import VueCropper from 'vue-cropperjs';
import inspirationService from '@/services/inspirationService'


export default {
  props: ['imageSrc', 'imgName'],
  name: 'ImagesCropper',
  components: {
    VueCropper,
  },
  computed: {
    ...mapGetters('common', [
      'cropDialogVisible'
    ])
  },
  watch: {
    imageSrc (val) {
      this.imgSrcData = '';
      this.imgSrcData = val
      this.setCropDialogVisible(true)
    },
    cropImg () {
      this.$emit('callCropImage', this.cropImg)
    }
  },
  methods: {
    ...mapMutations('common', [
      'setCropDialogVisible'
    ]),
    ...mapActions('inspiration', [
      'getInspirationList',
    ]),
    sendCropImage() {
      this.$emit('callCropLoading', this.cropLoading)
      this.cropImg = this.$refs.cropper;
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        this.loading = true;
        const form = new FormData();
        form.append('file', new File([blob], this.imgName));
        form.append('type', 'IMAGE');

        inspirationService.inspirationUpload(form)
          .then(() => {
            this.getInspirationList()
            this.$emit('callcropLoadingFalse', this.cropLoadingFalse)
          }).catch((err) => {
            console.log(err)
            this.$emit('callcropLoadingFalse', this.cropLoadingFalse)
            this.$message({
              message: '이미지 등록 실패.',
              type: 'error'
            });
          })
      })
      this.imgSrcData = '';
    },
    clickBack() {
      this.imgSrcData = '';
    },
  },
  mounted() {
    if (this.imageSrc) {
      this.imgSrcData = this.imageSrc;
      this.setCropDialogVisible(true);
    }
  },
  data () {
    return {
      // imgSrcData: this.imageSrc,
      imgSrcData: '',
      cropImg: '',
      closeOnEscape: true,
      imageInfo: { width: 0, height: 0 },
      cropLoading: true,
      cropLoadingFalse: true
    }
  },
}
</script>

<style scoped>
body {
  font-family: Arial, Helvetica, sans-serif;
  width: 1024px;
  margin: 0 auto;
}

.imsr-container {
  background-color: #fff;
}

input[type="file"] {
  display: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.header h2 {
  margin: 0;
}

.header a {
  text-decoration: none;
  color: black;
}

.content {
  display: flex;
  justify-content: space-between;
}

.cropper-area {
  width: 100%;
}

.actions {
  margin-top: 1rem;
}

.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062CC;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.el-dialog__header {
  padding: 0;
}
</style>