import http from './config/httpConfig';

const prefix = '/api/v1/best';

export default {
  getBestItems (menuId, params) {
    return http.get(`${prefix}/${menuId}`, {
      params
    });
  }
}