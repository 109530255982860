<template>
  <div>
    <div class="imsr-content-top-container">
      <div class="imsr-container">
        <div class="imsr-page-title">
          <span>REQUEST TO ACCESS</span>
        </div>
        <div style="position: absolute; left: 0; bottom: 20px; line-height: 19px;" class="imsr-text-button" @click="$router.go(-1)">
          <span class="mdi mdi-arrow-left mdi-12px"></span>
          <span style="font-size: 12px;"> BACK</span>
        </div>
        <hr class="imsr-divider"/>
      </div>
    </div>
    <div class="imsr-content-container">
      <div class="imsr-container" style="padding: 40px 0; display: flex;">
        <div style="flex: 1; padding-right: 40px;">
          <el-form :model="requestForm" :rules="rules" ref="requestForm" label-position="left" label-width="130px">
            <el-form-item label="NAME" required>
              <el-col :span="11">
                <el-form-item prop="first_name">
                  <el-input v-model="requestForm.firstName" placeholder="First Name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11" :offset="2">
                <el-form-item prop="last_name">
                  <el-input v-model="requestForm.lastName" placeholder="Last Name"></el-input>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item label="EMAIL" prop="id" style="margin-bottom: 5px;">
              <el-input v-model="requestForm.id" placeholder="email@email.com"></el-input>
            </el-form-item>
            <div style="margin-bottom: 15px; font-size: 14px; color: #999; text-align: left; padding-left: 135px;">For your ID</div>
            <el-row>
              <el-col :span="12">
                <el-form-item label="COMPANY" prop="company">
                  <el-input v-model="requestForm.company" placeholder="e.g. ABCD.Inc."></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="DIVISION" prop="division">
                    <el-input v-model="requestForm.division" placeholder="e.g. Design.Dept."></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10" :offset="2">
                <el-form-item label="POSITION">
                    <el-input v-model="requestForm.position" placeholder="Input Your Position"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="COUNTRY" prop="country">
                  <el-select style="width: 100%;" v-model="requestForm.country" placeholder="Select Yout Country">
                    <el-option v-for="contry in contryList" :key="contry" :label="contry" :value="contry"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="ADDRESS" prop="address">
                    <el-input v-model="requestForm.address" placeholder="e.g. Stevens Creek Blvd, Suite 100, Cupertino, CA"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10" :offset="2">
                <el-form-item label="ZIP CODE" prop="zipCode">
                    <el-input v-model="requestForm.zipCode" placeholder="00000"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="SUB EMAIL" prop="subEmail" style="margin-bottom: 5px;">
                  <el-input v-model="requestForm.subEmail" placeholder="email@email.com"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <div style="margin-bottom: 15px; color: #999; padding-left: 135px; text-align: left;">
              In case you lost your ID
            </div>
            <el-form-item label="INTEREST">
              <el-checkbox-group v-model="requestForm.interestCodeList">
                <el-row>
                  <el-col :span="6" style="text-align: left;" 
                    v-for="interest in interestList"
                    :key="interest">
                    <el-checkbox :label="interest">{{interest}}</el-checkbox>
                  </el-col>
                </el-row>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="ADDITIONAL INFO">
              <div contenteditable="true" class="additional-info" style="width: 1080px;">{{requestForm.extraInfo}}</div>
            </el-form-item>
          </el-form>
        </div>
        <div style="width: 370px;">
          <el-button style="width: 100%" class="imsr-button" @click="clickRequestToAccess">DONE</el-button>
          <div class="privacy-terms-text">
            By pressing [DONE] button below you agree with our 
            <span @click="privacypolicyClick">Privacy Policy</span> 
            and our 
            <span @click="termsClick">Terms and Conditions.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  computed: {
    ...mapGetters('common', [
      'interestList',
      'contryList'
    ])
  },
  methods: {
    ...mapMutations('menu', [
      'setExtendFixedHeight'
    ]),
    ...mapActions('common', [
      'requestToAccess'
    ]),
    clickRequestToAccess () {
      this.$refs.requestForm.validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          this.requestToAccess(this.requestForm)
            .then(() => {
              loading.close();
              this.$alert('Please wait for the request accepted. We will contact you by email asap. Thank you', 'REQUEST SUCCESS!', {
                confirmButtonText: 'OK',
                showClose: false,
                confirmButtonClass: 'imsr-button',
                callback: () => {
                  this.$router.push({ name: 'main'});
                }
              });
            })
            .catch((err) => {
              loading.close();
              if (err.response.status === 409) {
                this.$alert(`${err.response.data}`, {
                  confirmButtonText: 'OK',
                  showClose: false,
                  confirmButtonClass: 'imsr-button'
                });
              } else {
                this.$alert(`STATUS : ${err.response.status}`, 'REQUEST FAILED!', {
                  confirmButtonText: 'OK',
                  showClose: false,
                  confirmButtonClass: 'imsr-button'
                });
              }
            })
        }
      });
    },
    privacypolicyClick () {
      this.$router.push('/privacy');
    },
    termsClick () {
      this.$router.push('/termspage');
    }
  },
  data () {
    const validateEmail = (rule, value, callback) => {
      const re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
      if (re.test(value)) {
        callback();
      } else {
        callback(new Error('invalid email address'));
      }
    };

    return {
      requestForm: {
        firstName: '',
        interestCodeList: []
      },
      rules: {
        firstName: [
          { required: true, message: ' ', trigger: 'blur' },
          { required: true, message: ' ', trigger: 'change' }
        ],
        lastName: [
          { required: true, message: ' ', trigger: 'blur' },
          { required: true, message: ' ', trigger: 'change' }
        ],
        id : [
          { required: true, message: ' ', trigger: 'blur' },
          { required: true, message: ' ', trigger: 'change' },
          { validator: validateEmail, message: ' ', trigger: 'blur'}
        ],
        company : [
          { required: true, message: ' ', trigger: 'blur' },
          { required: true, message: ' ', trigger: 'change' }
        ],
        division : [
          { required: true, message: ' ', trigger: 'blur' },
          { required: true, message: ' ', trigger: 'change' }
        ],
        country : [
          { required: true, message: ' ', trigger: 'change' }
        ],
        address : [
          { required: true, message: ' ', trigger: 'blur' },
          { required: true, message: ' ', trigger: 'change' }
        ],
        zipCode : [
          { required: true, message: ' ', trigger: 'blur' },
          { required: true, message: ' ', trigger: 'change' }
        ],
        subEmail : [
          { required: true, message: ' ', trigger: 'blur' },
          { required: true, message: ' ', trigger: 'change' },
          { validator: validateEmail, message: ' ', trigger: 'blur'}
        ]
      }
    }
  },
  mounted() {
    this.setExtendFixedHeight(80);
  }
}
</script>

<style scoped>
.additional-info {
  text-align: left;
  padding: 5px 15px;
  line-height: 30px;
  min-height: 180px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}

.additional-info:hover {
  border-color: #c0c4cc;
}

.additional-info:focus {
  border-color: #98c8cc;
  outline: none;
}

.privacy-terms-text {
  font-size: 12px;
  text-align: left;
  margin-top: 10px;
}

.privacy-terms-text > span {
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}
</style>