<template>
  <div class="imsr-container imsr-item-list" style="margin-top: 50px;" v-loading="loading" ref="graphic3DPage">
    <el-row :gutter="24" style="margin-bottom: 48px;" v-for="(row, index) in itemRows" :key="index">
      <el-col :span="6" class="imsr-style-wrap" v-for="item in row" :key="item.itemId">
        <div class="imsr-item-card">
          <el-card class="imsr-style-item" 
            :body-style="{ padding: '0px' }" shadow="never" style="border: 0;">
            <div class="item-image-thumb" :style="{'background-image': `url(${$resizeServer}792-${item.itemRepImg.fileKey})`}"
              @click="clickThumb(item)">
            </div>
            <div class="item-style-item-desc">
              <div class="item-sample">
                <span>{{ item.category_name || '-' }}</span>
              </div>
              <span class="item-title">{{ item.title }}</span>
              <div class="primary-color" >{{ item.itemNo }}</div>
              <div class="item-style-tag-group">
                <span class="item-style-tag" v-for="tag in item.tags" :key="tag.name">{{ tag.name }}</span>
                <span class="item-style-tag" style="background-color: #fff;"  v-if="item.tags.length === 0">-</span>
              </div>
              <div v-loading="wishLoading && wishLoading === item.itemId">
                <div class="item-style-favorite" v-if="item.wishTotal === 0">
                  <span class="mdi mdi-heart-outline mdi-24px" 
                    @click="clickWish(item)">
                  </span>
                  <span class="item-style-favorite-member">
                    -
                  </span>
                </div>
                <div class="item-style-favorite" v-else-if="item.addedWish">
                  <span class="mdi mdi-heart mdi-24px error-color"
                    @click="clickWish(item)">
                  </span>
                  <span class="item-style-favorite-member">
                    Added by {{userInfo.firstName}} 
                    <span v-if="item.wishTotal - 1 > 0"> and {{item.wishTotal - 1}} more</span>
                  </span>
                </div>
                <div class="item-style-favorite" v-else>
                  <span class="mdi mdi-heart mdi-24px error-color"
                    @click="clickWish(item)">
                  </span>
                  <span class="item-style-favorite-member">
                    Added by {{item.wishTotal}} more
                  </span>
                </div>
              </div>
            </div>
            <div style="margin-top: 10px;">
              <el-button v-if="selectedItemIds.indexOf(item.itemId) === -1" 
                class="imsr-border-button" 
                style="width: 100%;"
                @click="addPaletteList(item)">
                ADD TO PALETTE
              </el-button>
              <el-button v-else 
                class="imsr-danger-button" 
                style="width: 100%;"
                @click="removePaletteList(item)">
                REMOVE FROM PALETTE
              </el-button>
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>
    <imsr-no-content v-if="!loading && itemPage.total === 0"></imsr-no-content>
    <infinite-loading ref="infiniteLoading" spinner='default' @infinite="infiniteHandler">
      <div slot="spinner">
        <el-row :gutter="24" style="margin-bottom: 48px;">
          <el-col :span="6" class="imsr-style-wrap" v-for="idx in emptyCount" :key="idx">
            <empty-item-card></empty-item-card>
          </el-col>
        </el-row>
      </div>
      <div slot="no-more"></div>
      <div slot="no-result"></div>
    </infinite-loading>
  </div>
</template>

<script>
import EmptyItemCard from '@/components/common/EmptyItemCard'
import ImsrNoContent from '@/components/common/ImsrNoContent'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { chunk, map } from 'lodash'
import wishService from '@/services/wishService';

export default {
  name: 'GraphicItemList',
  props: ['prefix'],
  components: {
    EmptyItemCard,
    ImsrNoContent
  },
  computed: {
    ...mapGetters('common', [
      'pageBack'
    ]),
    ...mapGetters('trend', [
      'trendItems',
      'itemPage',
      'relatedParam'
    ]),
    ...mapGetters('menu', [
      'selectedMenu'
    ]),
    ...mapGetters('auth', [
      'userInfo'
    ]),
    ...mapGetters('visualStudio', [
      'paletteList'
    ]),
    itemRows () {
      return chunk(this.trendItems, this.countPerRow);
    },
    emptyCount () {
      if (this.itemPage.page === 0) {
        return this.countPerRow;
      } else if (this.itemPage.total > this.trendItems.length) {
        return (this.itemPage.total - this.trendItems.length) % this.countPerRow;
      } 
      return 0;
    },
    selectedItemIds () {
      return map(this.paletteList, item => item.itemId);
    }
  },
  methods: {
    ...mapMutations('common', [
      'setPageBack'
    ]),
    ...mapActions('trend', [
      'getTrendItems',
      'getTrendRelatedItems'
    ]),
    ...mapMutations('trend', [
      'setRelatedParam',
      'clearItems',
      'setItemWish'
    ]),
    ...mapMutations('visualStudio', [
      'addPaletteList',
      'removePaletteList',
      'initList'
    ]),
    ...mapMutations('auth', [
      'setGuestInDialog'
    ]),
    clickThumb (item) {
      this.setPageBack({
        to: 'palette'
      });

      this.$router.push(`${this.prefix}/item/${item.itemType}/${item.itemId}`);
    },
    clickWish (item) {
      if (this.userInfo.companyInfo === null && !this.userInfo.admin) {
        this.setGuestInDialog(true)
      } else {
        this.wishLoading = item.itemId;
        let ajax;
        if (item.addedWish) {
          ajax = wishService.removeWishItems([item.itemId]);
        } else {
          ajax = wishService.addWishItem(item.itemId);
        }
        ajax
          .then(() => {
            this.wishLoading = undefined;
            this.setItemWish({
              id: item.itemId, wish: !item.addedWish
            });
          })
          .catch(() => {
            this.wishLoading = undefined;
          });
      }
    },
    getTrendItemsByPage (page) {
      return this.getTrendRelatedItems({
        page,
        trendId: this.trendId
      });
    },
    infiniteHandler ($state) {
      this.getTrendItemsByPage(this.itemPage.page + 1)
        .then(() => {
          this.loading = false;
          $state.loaded();
          if (this.trendItems.length === this.itemPage.total) {
            $state.complete();
          }
        })
        .catch(() => {
          this.loading = false;
          $state.error();
        });
    },
    changeViewType (type) {
      
      if (this.relatedParam.type === type) {
        return false;
      }
      this.loading = true;
      this.$refs.infiniteLoading.stateChanger.reset();
      this.clearItems();
      if (type) {
        this.setRelatedParam({
          name: 'graphicType', type
        });
      } else {
        this.setRelatedParam({});
      }
    }
  },
  data () {
    return {
      trendId: '',
      loading: false,
      countPerRow: 4,
      wishLoading: undefined
    }
  },
  created () {
    this.trendId = this.$route.params.trendId;
    if (!(this.pageBack.isBack && this.pageBack.to === 'palette')) {
      this.initList();
      this.clearItems();
      this.loading = true;
    }
  }
}
</script>

<style scoped>
.item-top-button-group {
  float: right; 
  padding: 20px 0;
  font-size: 12px;
}

.imsr-item-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.item-top-button-group > span {
  padding-left: 13px;
}

.item-top-button-group .imsr-text-button.active {
  font-weight: 900;
  color: #232323 !important;
}

.imsr-item-list .imsr-style-wrap {
  display: flex;
  justify-content: center;
}

.imsr-style-wrap .item-image-thumb {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 370px;
  height: 370px;
  cursor: pointer;
}

.imsr-item-list .item-style-item-desc {
  display: flex;
  justify-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8px 10px;
}

.imsr-item-list .item-title {
  font-size: 16px;
  font-weight: bold;
  padding: 8px;
}

.item-style-favorite > span {
  display: block;
}

.item-style-favorite > span.mdi {
  cursor: pointer;
}

.item-style-favorite .item-style-favorite-member {
  font-size: 12px;
  display: block;
  margin-top: 4px;
}

.imsr-divider-group {
  margin-top: 20px;
}

.imsr-style-item .imsr-style-item-divider {
  border-radius: 0;
  padding-top: 4px;
}

.imsr-style-item.active .imsr-style-item-divider {
  border-color: #98c8cc;
  border-bottom-width: 10px;
  padding: 0;
}

.item-style-tag-group {
  display: flex;
  justify-items: center;
  justify-content: center;
  padding: 8px 0;
}

.item-style-tag {
  font-size: 12px;
  line-height: 18px;
  padding: 0 7px;
  margin: 3px;
  background-color: #eee;
  border-radius: 7px;
}

.imsr-style-item {
  width: 100%;
  max-width: 372px;
  
}

.imsr-item-list .item-sample > span {
  width: 200px;
  background-color: #ccc;
  text-align: center;
  display: inline-block;
  line-height: 24px;
}

.palette-list-container {
  display: flex;
  align-items: center;
  min-height: 110px;
  border: 1px solid #98C8CC;
  overflow-y: auto;
  padding: 0 5px;
}

.palette-list-container .palette-item {
  width: 90px;
  height: 90px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 5px;
}
</style>