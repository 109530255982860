<template>
  <div>
    <div class="imsr-content-top-container">
      <div class="imsr-content-container" style="min-width: 0;">
        <div class="imsr-container">
          <div class="imsr-page-title">
            <span>PRIVACY POLICY</span>
          </div>
          <hr class="imsr-divider"/>
        </div>
      </div>
    </div>
    <div class="imsr-content-container" style="height: 100%;">
      <div class="imsr-container" style="position: relative; text-align: left;" v-html="text">
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      text: ''
    }
  },
  created () {
    // const url = require("../../config/user.config.json")[process.env.NODE_ENV].VUE_APP_API_SERVER_HOST;
    // const idx = url.indexOf('/ims');
    // console.log(url.substring(0, idx))
    axios.get('/privacypolicy/')
      .then(res => {
        console.log(res)
        this.text = res.data;
        console.log(this.text)
      })
      .catch(err => console.log(err))
  }
}
</script>

<style scoped>
.text-iframe {
  width: 100%;
  height: 100%;
  border: none;
  left: 0;
  top: 0;
  position: relative;
}

.text-iframe iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>