import Vue from 'vue'
import Element from 'element-ui'
import '../element-variables.scss'
import locale from 'element-ui/lib/locale/lang/en'
import Toasted from 'vue-toasted';
import vueMoment from 'vue-moment'
import VueCookies from 'vue-cookies'

Vue.use(Element, { locale })
Vue.use(Toasted)
Vue.use(vueMoment)
Vue.use(VueCookies)