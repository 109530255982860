<template>
  <div v-if="!loading" v-loading="loading">
    <el-carousel ref="carousel" indicator-position="outside" arrow="always" trigger="hover" @change="carouselChange" :data="currentIndex" :height="height + 'px'" :autoplay="false" direction="vertical" class="v-carousel">
      <el-carousel-item v-for="(image, index) in pages" :key="index">
        <div class="slide-item" :style="{'background-image': `url(${image})`, height: height + 'px'}" @click="click">
          <transition name="fade">
            <div class="slide-pop" v-if="isToast">
              <img src="../../../../assets/3d_toast_scroll.png">
            </div>
          </transition>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  props: {
    pages: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  watch: {
    loading() {
      if (!this.loading) {
        this.isToast = true
        if (this.isToast === true) {
          setTimeout(() => {
            this.isToast = !this.isToast
          }, 2000)
        }
      }
    }
  },
  methods: {
    click () {
      if (this.currentIndex + 1 > this.pages.length) {
        this.currentIndex = 0;
      } else {
        this.currentIndex++;
      }
      this.$refs.carousel.setActiveItem(this.currentIndex);
    },
    carouselChange (index) {
      this.currentIndex = index
    }
  },
  created () {
    const img = new Image();
    img.onload = () => {
      this.height =  (1220 * img.height) / img.width;
      this.loading = false;
    };
    img.src = this.pages[0];
  },
  data () {
    return {
      height: 0,
      loading: true,
      currentIndex: 0,
      isToast: false
    }
  }
}
</script>

<style>
.slide-item {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.slide-pop {
  width: 160px; 
  height: 144px; 
  z-index: 999;
  position: absolute;
}

.v-carousel .el-carousel__indicator--vertical .el-carousel__button {
  width: 14px;
  height: 14px;
  border-radius: 20px;
  background-color: #7F879A;
  border: 2px solid #fff;
  padding: 0;
}

.el-carousel__indicators--vertical {
  position: absolute;
  top: 0;
  display: flex;
  flex-flow: column wrap-reverse;
}
</style>