import wishService from '@/services/wishService'
import { assignIn, chain, cloneDeep, concat, filter, find, findIndex, map, take } from 'lodash'

const state = {
  wishList: [],
  listParam: {
    page: 0,
    total: 0,
    size: 40,
    itemType: ''
  },
  wishType: 'USER',
  checkedList: []
};

const getters = {
  wishList (state) {
    return map(state.wishList, item => {
      if (item.category) {
        item.category_name = item.category.split('_').join(' ');
      }
      item.tags = take(item.itemTagNames, 2);
      item.checked = !!find(state.checkedList, checked => checked.itemId === item.itemId);
      return item;
    });
  },
  listParam (state) {
    return state.listParam;
  },
  checkedList (state) {
    return state.checkedList;
  }
};

const actions = {
  getWishList ({ commit, state }, page) {
    return new Promise((resolve, reject) => {
      wishService.getWishList(state.wishType, {
          page: page - 1,
          size: state.listParam.size,
          itemType: state.listParam.itemType,
          sort: 'wishItem.createDate,desc'
        })
        .then(res => {
          commit('setWishList', res.data.list);
          commit('setListParam', {
            total: res.data.total,
            page
          });
          resolve();
        })
        .catch(reject);
    });
  },
  addWish ({ commit }, id) {
    return new Promise((resolve, reject) => {
      wishService.addWishItem(id)
        .then(() => {
          commit('addWishItem', id);
          resolve();
        })
        .catch(reject);
    });
  },
  removeWish ({ commit }, ids) {
    return new Promise((resolve, reject) => {
      wishService.removeWishItems(ids)
        .then(() => {
          commit('removeWishItems', ids);
          resolve();
        })
        .catch(reject);
    });
  }
};

const mutations = {
  initWish (state) {
    state.wishList = [];
    state.listParam = {
      page: 0,
      total: 0,
      sort: 'createDate,desc',
      size: 40,
      itemType: ''
    };
    state.checkedList = [];
  },
  initList (state) {
    state.wishList = [];
    state.checkedList = [];
  },
  setWishList (state, list) {
    state.wishList = concat(state.wishList, list);
  },
  setListParam (state, info) {
    state.listParam = assignIn(state.listParam, info);
  },
  setWishType (state, type) {
    state.wishType = type;
  },
  setCheckedItem (state, { itemId, checked }) {
    if (checked) {
      const item = find(state.wishList, item => item.itemId === itemId);
      state.checkedList.push(item);
    } else {
      state.checkedList = filter(state.checkedList, item => item.itemId !== itemId);
    }
  },
  setAllChecked (state, checked) {
    if (checked) {
      state.checkedList = cloneDeep(state.wishList);
    } else {
      state.checkedList = [];
    }
  },
  addWishItem (state, id) {
    const idx = findIndex(state.wishList, item => item.itemId === id);
    const item = state.wishList[idx];
    state.wishList[idx] = assignIn(item, {
      addedWish: true,
      wishTotal: item.wishTotal + 1
    });
  },
  removeWishItems (state, ids) {
    if (state.wishType === 'USER') {
      state.wishList = filter(state.wishList, item => {
        return ids.indexOf(item.itemId) === -1;
      });
    } else {
      state.wishList = chain(state.wishList)
        .map(item => {
          if (ids.indexOf(item.itemId) > -1) {
            item = assignIn(item, {
              addedWish: false,
              wishTotal: item.wishTotal - 1
            });
          }
          return item;
        })
        .filter(item => {
          return !(ids.indexOf(item.itemId) > -1 && item.wishTotal === 0)
        })
        .value();
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}