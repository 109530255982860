<template>
  <div>
    <div class="imsr-content-top-container">
      <div class="imsr-container">
        <div class="imsr-page-title">
          <span>EXPORT LIST</span>
        </div>
        <hr class="imsr-divider"/>
        <div class="clearfix">
          <div class="imsr-breadcrumb" style="float: left;">
            {{selectedMenu.menu.name}} &gt; {{selectedMenu.subMenu.name}}
          </div>
        </div>
      </div>
    </div>

    <div class="imsr-content-container">
      <div class="imsr-container">
        <el-row :gutter="50" v-for="(row, index) in studioImages" :key="index">
          <el-col :span="6" v-for="item in row" :key="item.id">
            <div class="studio-item">
              <div class="studio-item-image"
                :style="{'background-image': `url(${item.src})`}"
                @click="clickThumb(item)"></div>
              <div style="font-weight: bold; font-size: 18px; margin-bottom: 10px;">
                <span v-if="item.graphic">{{ item.graphic.title }}</span>
                <span v-if="item.pattern"> {{ item.pattern.title }}</span>
              </div>
              <div style="font-size: 12px;">Made at {{ item.date | moment('MM.DD.YYYY HH:mm:A')}}</div>
            </div> 
            <hr class="imsr-divider"/>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { chunk, findIndex } from 'lodash'

export default {
  computed: {
    ...mapGetters('menu', [
      'selectedMenu'
    ]),
    ...mapGetters('visualStudio', [
      'visualStudioItems'
    ]),
    studioImages () {
      return chunk(this.visualStudioItems, 4);
    }
  },
  methods: {
    ...mapMutations('menu', [
      'setExtendFixedHeight'
    ]),
    clickThumb (clickedItem) {
      this.isDetail = true;
      const index = findIndex(this.visualStudioItems, item => item.id === clickedItem.id);
      this.$router.push(`${this.$route.path}/item/${index}`);
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.isDetail) {
      next();
    } else {
      this.$confirm('Leaving this page will discard all your exported items. <br/>Please select items and add them to cart.<br/>Are you sure to leave this page?', 'CAUTION', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: 'LEAVE',
          cancelButtonText: 'CANCEL',
          confirmButtonClass: 'imsr-text-button',
          cancelButtonClass: 'imsr-text-button',
          showClose: false
        }).then(() => {
          next();
        })
        .catch(() => {
          // next(false);
        });
    }
  },
  data () {
    return {
      isDetail: false
    }
  },
  mounted () {
    this.setExtendFixedHeight(125);
    this.isDetail = false;
  }
}
</script>

<style scoped>

.studio-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
}

.studio-item-image {
  height: 370px;
  width: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
</style>