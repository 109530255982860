import authService from '@/services/authService';
import usersService from '@/services/usersService';

import tokenUtil from '@/util/tokenUtil';
import { assignIn } from 'lodash';

const state = {
  isLoggedIn: false,
  signInDialog: false,
  guestInDialog: false,
  logInStatus: '',
  path: undefined,
  userInfo: {
    firstName: '',
    lastName: '',
    interest: ''
  },
  badgeInfo: {
    numOfCart: 0,
    numOfRecapComment: 0
  },
  isTryLogin: false
};

const getters = {
  isLoggedIn (state) {
    return state.isLoggedIn;
  },
  signInDialog (state) {
    return state.signInDialog;
  },
  guestInDialog (state) {
    return state.guestInDialog;
  },
  logInStatus (state) {
    return state.logInStatus;
  },
  userInfo (state) {
    let interestArr = [];
    if (state.userInfo.interest && state.userInfo.interest !== '') {
      interestArr = state.userInfo.interest.split(',');
    }
    return assignIn(state.userInfo, {
      interestArr
    });
  },
  badgeInfo (state) {
    return state.badgeInfo;
  },
  isTryLogin (state) {
    return state.isTryLogin;
  },
  checkDormant (state) {
    return state.checkDormant;
  },
  checkRegion (state) {
    return state.checkRegion;
  },
};

const actions = {
  signIn ({ commit, dispatch }, user) {
    return new Promise((resolve, reject) => {
      authService.logIn(user)
        .then((res) => {
          commit('path', undefined);
          commit('setIsTryLogin', true);
          tokenUtil.saveToken(res.data);
          return dispatch('checkToken');
        })
        .then(resolve)
        .catch(reject);
    });
  },

  checkToken ({ commit, dispatch }) {
    const access_token = tokenUtil.getAccessToken();
    if (!access_token) {
      commit('logInErrorStatus', 'notLoggedIn');
    } else {
      authService.checkToken(access_token)
        .then(() => {
          authService.setAuthorization(access_token);
          return dispatch('getBadgeInfo');
        })
        .then(() => {
          return usersService.myInfo();
        })
        .then((res) => {
          if (res.data.state === 2) {
            commit('logInErrorStatus', 'accessTokenExpired');
          } else {
            commit('setUserInfo', res.data);
            commit('logInSuccess', access_token);
          }
          
        })
        .catch(() => {
          commit('logInErrorStatus', 'accessTokenExpired');
        });
    }
  },
  refreshToken ({ commit, dispatch, state }) {
    const refresh_token = tokenUtil.getRefreshToken();
    if (!refresh_token) {
      commit('logInErrorStatus', 'notLoggedIn');
    } else {
      authService.refreshToken(refresh_token)
        .then(res => {
          tokenUtil.saveToken(res.data);
          if (state.isLoggedIn) {
              commit('logInSuccess', res.data.access_token);
          } else {
              dispatch('checkToken');
          }
        })
        .catch(() => commit('logOut'));
    }
  },
  modifyInfo ({ commit }, form) {
    return new Promise((resolve, reject) => {
      usersService.modifyInfo(form)
        .then(res => {
          commit('setUserInfo', res.data);
          resolve();
        })
        .catch(reject);
    });
  },
  getBadgeInfo ({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      usersService.getBadgeInfo()
        .then(res => {
          dispatch('globalNotiAction', res.data.numOfRecapComment, { root: true })
          commit('setBadgeInfo', res.data);
          resolve();
        })
        .catch(reject);
    });
  },
  getMyInfo ({ commit }) {
    commit('setUserInfo', {});
    return new Promise((resolve, reject) => {
      usersService.myInfo()
        .then(res => {
          commit('setUserInfo', res.data);
          resolve();
        })
        .catch(reject);
    });
  }
  
};

const mutations = {
  setSignInDialog (state, param) {
    state.signInDialog = param;
  },
  setGuestInDialog (state, param) {
    state.guestInDialog = param;
  },
  logInErrorStatus (state, status) {
    state.logInStatus = status;
    state.isLoggedIn = false;
  },
  logInSuccess (state, access_token) {
    authService.setAuthorization(access_token);
    state.isLoggedIn = true;
    state.logInStatus = 'success';
  },
  path (state, path) {
    state.path = path;
  },
  setUserInfo (state, info) {
    state.userInfo = info;
  },
  logOut () {
    tokenUtil.removeToken();
    location.reload();
  },
  setBadgeInfo (state, info) {
    state.badgeInfo = info;
  },
  setIsTryLogin (state, value) {
    state.isTryLogin = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
