import faqService from '@/services/faqService'
import { assignIn } from 'lodash'

const state = {
  list: [],
  listParam: {
    total: 0,
    page: 0,
    size: 10
  }
};

const getters = {
  list (state) {
    return state.list;
  },
  listParam (state) {
    return state.listParam;
  }
};

const actions = {
  getFaqList ({ commit, state }, page) {
    return new Promise((resolve, reject) => {
      const param = {
        page: page - 1,
        size: state.listParam.size,
        sort: 'createDate,desc'
      };
      faqService.getFaqList(param)
        .then(res => {
          commit('setList', res.data.list);
          commit('setListParam', {
            total: res.data.total,
            page
          });
          resolve();
        })
        .catch(reject);
    });
  }
};

const mutations = {
  initParam (state) {
    state.list = [];
    state.listParam = {
      total: 0,
      page: 0,
      size: 10
    };
  },
  setList (state, list) {
    state.list = list;
  },
  setListParam (state, param) {
    state.listParam = assignIn(state.listParam, param)
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
