import itemService from '@/services/itemService'
import { assignIn, chain, concat, findIndex, map, take } from 'lodash';

const state = {
  searchList: [],
  page: {
    total: 0,
    page: 0,
    size: 40
  },
  searchParam: {
    keyword: '',
    itemType: undefined,
    gender: undefined,
  },
  genderList: [{
    id: '3', name: 'WOMEN'
  }, {
    id: '4', name: 'MEN'
  }, {
    id: '0', name: 'GIRLS'
  }, {
    id: '1', name: 'BOYS'
  }, {
    id: '2', name: 'JUNIORS'
  }],
  styleGenderList: [{
    id: '3', name: 'WOMEN'
  }, {
    id: '4', name: 'MEN'
  }, {
    id: '0', name: 'GIRLS'
  }, {
    id: '1', name: 'BOYS'
  }, {
    id: '2', name: 'JUNIORS'
  }, {
    id: '5', name: 'PLUS'
  }],
};

const getters = {
  searchList (state) {
    return map(state.searchList, item => {
      if (item.category) {
        item.category_name = item.category.split('_').join(' ');
      }
      if (state.searchParam.sustainability) {
        item.tags = chain(item.itemTagNames)
          .filter(tag => tag.name.toLowerCase().indexOf('sustainability') === -1)
          .take(2)
          .value();
      } else {
        item.tags = take(item.itemTagNames, 2);
      }
      
      return item;
    });
  },
  itemPage (state) {
    return state.page;
  },
  searchParam (state) {
    return state.searchParam;
  },
  genderList (state) {
    return state.genderList;
  },
  styleGenderList (state) {
    return state.styleGenderList;
  }
};

const actions = {
  getSearchList ({ commit, state }, page) {
    return new Promise((resolve, reject) => {
      const param = assignIn({
        page: page - 1,
        size: state.page.size,
        sort: 'createDate,desc'
      }, state.searchParam);

      itemService.getItemList(param)
        .then(res => {
          commit('setList', res.data.list);
          commit('setPage', {
            total: res.data.total,
            page
          });
          resolve();
        })
        .catch(reject);
    });
  }
};

const mutations = {
  initParam (state) {
    state.searchList = [];
    state.page = {
      total: 0,
      page: 0,
      size: 40,
      sort: undefined
    };
  },
  setList (state, list) {
    state.searchList = concat(state.searchList, list);
  },
  setPage (state, param) {
    state.page = assignIn(state.page, param)
  },
  setSearchParam (state, param) {
    state.searchParam = param;
  },
  setItemWish (state, { id, wish }) {
    const idx = findIndex(state.searchList, item => item.itemId === id);
    const item = state.searchList[idx];
    state.searchList[idx] = assignIn(item, {
      addedWish: wish,
      wishTotal: wish ? item.wishTotal + 1 : item.wishTotal - 1
    });
  },
};

export default {
 namespaced: true,
 state,
 getters,
 actions,
 mutations
}