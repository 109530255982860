import Vue from 'vue'
import inspirationService from '@/services/inspirationService'
import { assignIn, chain, concat, filter, map } from 'lodash'

const state = {
  list: [],
  listParam: {
    total: 0,
    page: 0,
    size: 40,
    sort: 'createDate,desc'
  },
  searchList: [],
  searchParam: {
    total: 0,
    page: 0,
    size: 40,
    sort: 'createDate,desc',
    excludeIds:''
  },
  addedItems: [],
  seasonCodes: [{
    label: 'SPRING', value: '1'
  }, {
    label: 'SUMMER', value: '2'
  }, {
    label: 'FALL', value: '3'
  }, {
    label: 'WINTER', value: '4'
  }]
};

const getters = {
  inspirationList (state) {
    return map(state.list, item => {
      item.dateTitle = Vue.moment(item.createDate).format('MM.DD.YYYY HH:mm A');
      return item;
    });
  },
  listParam (state) {
    return state.listParam;
  },
  searchList (state) {
    return map(state.searchList, item => {
      item.dateTitle = Vue.moment(item.createDate).format('MM.DD.YYYY HH:mm A');
      return item;
    });
  },
  searchParam (state) {
    return state.searchParam;
  },
  addedItems (state) {
    return state.addedItems;
  },
  addedImages (state) {
    return chain(state.addedItems)
      .map((item, index) => {
        return {
          src: item.path,
          thumb92Src: item.path,
          thumb792Src: item.path,
          id: 'id' + item.id,
          itemId: item.id,
          name: 'name' + item.id,
          priority: index + 1,
          doNotRemove: index === 0
        }
      })
      .sortBy('priority')
      .value();
  },
  seasonCodes (state) {
    return state.seasonCodes;
  }
};

const actions = {
  getInspirationList ({ commit, state }, page) {
    return new Promise((resolve, reject) => {
      const param = assignIn({
        page: page - 1,
        size: state.listParam.size,
        sort: state.listParam.sort
      }, state.searchParam);

      inspirationService.inspirationList(param)
        .then(res => {
          commit('setInspirationList', res.data.list);
          commit('setListParam', {
            total: res.data.total,
            page
          });
          resolve();
        })
        .catch(reject);
    });
  },
  searchInspirationList ({ commit, state }, page) {
    return new Promise((resolve, reject) => {
      const param = {
        page: page - 1,
        size: state.searchParam.size,
        sort: state.searchParam.sort,
        excludeIds: state.searchParam.excludeIds
      };

      inspirationService.inspirationList(param)
        .then(res => {
          commit('setInspirationSearchList', res.data.list);
          commit('setSearchParam', {
            total: res.data.total,
            page
          });
          resolve();
        })
        .catch(reject);
    });
  },
  addToCart ({ dispatch }, form) {
    return new Promise((resolve, reject) => {
      inspirationService.addToCart(form)
        .then(() => {
          return dispatch('auth/getBadgeInfo', {}, { root: true });
        })
        .then(resolve)
        .catch(reject);
    });
  },
  removeInspiration ({ commit }, ids) {
    return new Promise((resolve, reject) => {
      inspirationService.removeInspiration(ids.join(','))
        .then(() => {
          commit('removeItems', ids);
          resolve();
        })
        .catch(reject);
    });
  }
};  

const mutations = {
  initParam (state) {
    state.list = [];
    state.listParam = {
      total: 0,
      page: 0,
      size: 40,
      sort: 'createDate,desc'
    };
    state.searchParam = {};
    state.addedItems = [];
    state.searchList = [];
  },
  initSearchParam (state) {
    state.searchList = [];
    state.searchParam = assignIn({
      total: 0,
      page: 0,
      size: 40,
      sort: 'createDate,desc'
    }, {
      excludeIds: chain(state.addedItems)
          .map(item => item.id)
          .join(',')
          .value()
    });
  },
  clearAddedItems (state) {
    state.addedItems = [];
    state.searchList = [];
  },
  setInspirationList (state, list) {
    state.list = list;
  },
  setListParam (state, param) {
    state.listParam = assignIn(state.listParam, param);
  },
  setInspirationSearchList (state, list) {
    state.searchList = list;
  },
  setSearchParam (state, param) {
    state.searchParam = assignIn(state.searchParam, param);
  },
  addRequestItem (state, item) {
    state.addedItems = concat(state.addedItems, [item]);
  },
  removeRequestItem (state, id) {
    state.addedItems = filter(state.addedItems, item => item.id !== id);
  },
  setItemChecked (state, { id, checked }) {
    state.list = map(state.list, item => {
      if (!id || item.id === id) {
        item.checked = checked;
      }
      return item;
    });
  },
  removeItems (state, ids) {
    state.list = filter(state.list, item => ids.indexOf(item.id) === -1);
  }
};  

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
