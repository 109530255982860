<template>
  <div class="search-form">
    <div class="imsr-content-container">
      <div class="imsr-container" style="width: 660px; position: relative;">
        <el-autocomplete
          ref="autocomplete"
          prefix-icon="el-icon-search"
          v-model="searchItem"
          style="width: 100%;"
          value-key="itemNo"
          clearable
          :fetch-suggestions="querySearchItem"
          :trigger-on-focus="this.searchHashtag ? true : false"
          @keydown.native.enter="searchList"
          @select="handleSelect($event)">
          <template slot-scope="{ item }">
            <div class="related-search-result">
              <div class="image-wrap" style="width: 80px; height: 70px;">
                <img :src="`${$resizeServer}92-${item.itemRepImg.fileKey}`"/>
              </div>
              <div style="flex: 2;">{{ item.itemNo || '-' }}</div>
              <div style="flex: 3;">{{ item.title || '-' }}</div>
            </div>
          </template>
        </el-autocomplete>
        <div style="position: absolute; right: -30px; top: 7px;">
          <el-button icon="el-icon-close" circle size="mini" @click="clickClose"></el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import itemService from '@/services/itemService'
import { mapGetters, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapGetters('common', [
      'searchHashtag'
    ])
  },
  watch: {
    searchHashtag () {
      // if (this.searchHashtag !== this.searchItem) {
        this.searchItem = this.searchHashtag
        this.$refs.autocomplete.focus()
      // }
    },
  },
  methods: {
    ...mapMutations('common', [
      'setViewSearchForm',
      'setSearchHashtag'
    ]),
    handleSelect (item) {
      this.$router.push(`/search/${item.itemType}/${item.itemId}`);
    },
    querySearchItem (keyword, callback) {
      itemService.getItemList({
          page: 0,
          size: 100,
          sort: 'createDate,desc',
          keyword: this.searchItem
        })
        .then(res => {
          callback(res.data.list);
        });
    },
    searchList () {
      this.$router.push('/search?keyword=' + this.searchItem);
    },
    clickClose () {
      this.setViewSearchForm(false)
      this.setSearchHashtag('')
    }
  },
  data () {
    return {
      searchItem: ''
    }
  },
  mounted () {
    if (this.searchHashtag.length > 0) {
      this.searchItem = this.searchHashtag
    }
    this.$refs.autocomplete.focus();
  }
}
</script>

<style scoped>
.search-form {
  background-color: #eee;
  padding: 3px 0;
}

.related-search-result {
  display: flex;
  padding: 2px 0;
}

.related-search-result img {
  max-width: 70px;
  max-height: 70px;
}

.related-search-result > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>