import http from './config/httpConfig';

const prefix = '/api/v1/recap';

export default {
  addRecap (form) {
    return http.post(`${prefix}`, form);
  },
  getRecapList (param) {
    return http.get(`${prefix}`, {
      params: param
    });
  },
  getRecapInfo (recapId) {
    return http.get(`${prefix}/${recapId}`);
  },
  getRecapComment (recapId) {
    return http.get(`${prefix}/${recapId}/comment`);
  },
  addRecapComment (recapId, comment) {
    return http.post(`${prefix}/${recapId}/comment`, {
      comment
    });
  },
  getRecapItemRequest (recapId, itemId) {
    return http.get(`${prefix}/${recapId}/item/${itemId}/request`);
  },
} 