<template>
  <div v-if="!loading" v-loading="loading">
    <el-carousel indicator-position="outside" arrow="always" :height="height + 'px'" :autoplay="false">
      <el-carousel-item v-for="(image, index) in pages" :key="index">
        <div class="slide-item" :style="{'background-image': `url(${image})`, height: height + 'px'}">
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  props: {
    pages: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  created () {
    const img = new Image();
    img.onload = () => {
      this.height =  (1220 * img.height) / img.width;
      this.loading = false;
    };
    img.src = this.pages[0];
  },
  data () {
    return {
      height: 0,
      loading: true
    }
  }
}
</script>

<style>
.slide-item {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
}

.el-carousel__indicators--outside button {
  width: 14px;
  height: 14px;
  border-radius: 20px;
  background-color: #7F879A;
  border: 2px solid #fff;
  padding: 0; 
}
</style>