<template>
  <div class="imsr-container" style="margin-top: 40px;">
    <el-row :gutter="50" style="margin-bottom: 48px;" v-for="(row, index) in itemRows" :key="index">
      <el-col :span="8" v-for="content in row" :key="content.id">
        <el-card :body-style="{ padding: '0px' }" shadow="never" style="border: 0;">
          <!-- <div class="image-thumb"
            :style="{'background-image': 'url(' + content.coverFilePath + ')'}"
            @click="$emit('clickThumb', content)">
          </div> -->
          <div class="image-thumb"
            :style="{'background-image': `url(${$resizeServer}792-${content.coverFileKey})`}"
            @click="$emit('clickThumb', content)">
          </div>
          <div style="padding: 15px 30px;">
            <div class="item-date">
              <span>{{content.publishDate | moment('MM.DD.YYYY')}}</span>
            </div>
            <div class="item-title-wrap">
              <span class="item-title">{{content.title}}</span>
            </div>
            <div class="bottom">
              <span class="item-desc">{{content.subTitle}}</span>
            </div>
          </div>
        </el-card>
        <hr class="imsr-divider"/>
      </el-col>
    </el-row>
    <imsr-no-content v-if="!loading && trendList.length === 0"></imsr-no-content>
    <infinite-loading ref="infiniteLoading" spinner='default' @infinite="$emit('infiniteHandler', $event)">
      <div slot="spinner">
        <el-row :gutter="24" style="margin-bottom: 48px;">
          <el-col :span="8" class="imsr-style-wrap" v-for="idx in emptyCount" :key="idx">
            <empty-list-card></empty-list-card>
          </el-col>
        </el-row>
      </div>
      <div slot="no-more"></div>
      <div slot="no-result"></div>
      
    </infinite-loading>
  </div>
</template>

<script>
import ImsrNoContent from '../common/ImsrNoContent'
import EmptyListCard from '../common/EmptyListCard'
import { chunk } from 'lodash';

export default {
  name: 'TrendItemList',
  components: {
    ImsrNoContent,
    EmptyListCard
  },
  props: {
    trendList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    listParam: {
      type: Object,
      default: () => {
        return {
          total: 0,
          page: 0
        }
      }
    }
  },
  computed: {
    itemRows () {
      return chunk(this.trendList, this.countPerRow);
    },
    emptyCount () {
      if (this.listParam.page === 0) {
        return 0;
      } else if (this.listParam.total > this.trendList.length) {
        const count = this.listParam.total - this.trendList.length;
        return Math.min(count, this.countPerRow);
      } 
      return 0;
    },
  },
  methods: {
    reset () {
      this.$refs.infiniteLoading.stateChanger.reset();
    }
  },
  data () {
    return {
      countPerRow: 3,
    }
  }
}
</script>

<style scoped>
.item-title-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3em;
  margin: 5px 0;
}

.item-title {
  font-weight: 700;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 10px 0;
  line-height: 1.2em;
  
}

.item-desc {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  min-height: 17px;
}

.item-date {
  font-size: 14px;
  color: gray;
}

.image-thumb {
  /* width: 512px; */
  height: 387px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}
</style>