<template>
  <div>Loading...</div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'DeafultView',
  methods: mapActions('common', [
      'getLadingData'
  ]),
  mounted () {
    this.getLadingData()  
      .then(() => {
        this.$store.dispatch('auth/checkToken');
      });
  }
}
</script>

<style>

</style>