import http from './config/httpConfig';

const prefix = '/api/v1/studio';

export default {
  getGraphicInfo (itemIds) {
    return http.get(`${prefix}/graphics`, {
      params: {
        itemIds
      }
    });
  },
  addToCart (param) {
    return http.post(`${prefix}/cart`, param);
  }
}