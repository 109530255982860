<template>
  <div>
    <div class="imsr-content-top-container">
      <div class="imsr-content-container" style="min-width: 0;">
        <div class="imsr-container">
          <div class="imsr-page-title">
            <span>CONTACT US</span>
          </div>
        </div>
      </div>
    </div>
    <div class="imsr-content-container">
      <div class="imsr-container">
        <div style="display: flex; justify-content: center; margin: 80px 0;">
          <img src="@/assets/footer/footer_contact.png">
          <div class="info" style="text-align: left;">
            <div style="font-weight: bold;">Get In Touch</div>
            <div>Feel free to contact us anytime if you have further inquiries.</div>
            <div style="display: inline-block; border-bottom: 1px solid #232323; width: 24px;"></div>
            <div>If you need any support, please contact <a href="mailto:ims_admin@shinwon.com">ims_admin@shinwon.com</a>.</div>
            <div>Address.  Shinwon Bldg, #328, Dongmak-ro, Mapo-gu, Seoul, Korea</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  methods: {
    ...mapMutations('menu', [
      'setExtendFixedHeight'
    ])
  },
  mounted () {
    this.setExtendFixedHeight(70);
  },
}
</script>

<style scoped>
.info {
  font-size: 14px;
  text-align: left;
  margin-left: 30px;
}

.info div {
  margin-bottom: 20px;
}
</style>