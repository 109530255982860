<template>
  <div class="empty-list-item">
    <div>
      <span style="width: 100%; width: 372px; height: 372px; margin-bottom: 10px;"></span>
    </div>
    <div>
      <span style="width: 180px; height: 24px; margin-top: 10px;"></span>
    </div>
    <div>
      <span style="width: 100%; height: 19px;"></span>
    </div>
    <div>
      <span style="width: 196px; height: 17px;"></span>
    </div>
    <div>
      <span style="width: 66px; height: 18px; border-radius: 7px;"></span>
    </div>
    <div>
      <span class="mdi mdi-heart-outline mdi-24px" style="background-color: transparent; margin-bottom: 25px;"></span>
    </div>
    <hr class="imsr-divider"/>
  </div>
</template>

<script>
export default {
  name: 'EmptyItemCard'
}
</script>

<style scoped>
.empty-list-item {
  display: flex;
  flex-wrap: wrap;
}

.empty-list-item > div {
  width: 100%;
}

.empty-list-item > div > span {
  background-color: #eee;
  display: inline-block;
  margin-bottom: 5px;
}
</style>