import http from './config/httpConfig';

const prefix = '/api/v1/cmm';

export default {
  landing () {
    return http.get(`${prefix}/landing`);
  },
  requestResetPassword (resetForm) {
    return http.post(`${prefix}/users/forgotten`, resetForm);
  },
  requestToAccess (form) {
    return http.post(`${prefix}/users`, form);
  },
  getWashingSubCategory (id) {
    return http.get(`${prefix}/menu/${id}/category`)
  },
  getTermsText () {
    return http.get('../termsPage/', {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    });
  }
}