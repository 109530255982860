import http from './config/httpConfig';

const prefix = '/api/v1/mockup';

export default {
  getMockupList (param) {
    return http.get(`${prefix}`, {
     params: param
    });
  }
} 