import http from './config/httpConfig';

const prefix = '/api/v1/inspiration';

export default {
  inspirationList (params) {
    return http.get(`${prefix}`, {
      params
    });
  },
  addToCart (param) {
    return http.post(`${prefix}/cart`, param);
  },
  removeInspiration (ids) {
    return http.delete(`${prefix}`, {
      params: {
        ids
      }
    });
  },
  inspirationUpload (form) {
    return http.put(`${prefix}/web/addFile`, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
} 