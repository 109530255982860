import http from './config/httpConfig';
import qs from 'qs';

const basic_auth = require("../config/user.config.json").basic_auth;
const option = {
  auth: basic_auth,
  headers: { 
    'content-type': 'application/x-www-form-urlencoded' 
  }
};

http.interceptors.response.use(undefined, error => {
  if (error.response.status === 401) {
    error.response.status = '401'
  }
  return Promise.reject(error);
})

export default {
  logIn({username, password}) {
    return http.post(
      '/oauth/token',
      qs.stringify({
          username: username,
          password: password,
          grant_type: 'password'
      }),
      option
    );
  },
  refreshToken(refresh_token) {
    return http.post(
      '/oauth/token',
      qs.stringify({
        refresh_token: refresh_token,
        grant_type: 'refresh_token'
      }),
      option
    );
  },
  checkToken(access_token) {
    return http.post(
      '/oauth/check_token',
      qs.stringify({token: access_token}),
      option
    );
  },
  setAuthorization(access_token) {
    let token;
    if (access_token) {
      token = 'Bearer ' + access_token;
    }
    http.defaults.headers.common.Authorization = token;
  },
  checkDormant(param) {
    return http.post('api/v1/cmm/users/dormant', {
      usersId: param
    })
  },
  checkRegion(param) {
    return http.post('api/v1/cmm/users/region', {
      usersId: param
    })
  }
};