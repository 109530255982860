<template>
  <div class="imsr-menu-container">
    <nav class="imsr-container">
      <ul class="imsr-menu-group">
        <li class="imsr-menu"
          v-for="menu in menuList" 
          :key="menu.id"
          @mouseover="menuOver(menu)"
          @mouseleave="showMenu = { active: false, menuId: undefined }"
        >
          <span
            :class="[{'active': selectedMenu.menu.id === menu.id && !showMenu.menuId}, {'active': menu.id === showMenu.menuId}]"
            style="cursor: pointer;"
            @click="menuClick(menu)"
          >
            {{menu.name}}
          </span>
          <div class="imsr-sub-menu-container" >
            <transition-group name="submenu">
              <ul class="imsr-sub-menu-group" :key="'children-' + menu.id" v-if="showMenu.menuId === menu.id && showMenu.active" >
                <li class="imsr-menu"
                  v-for="subMenu in menu.subMenu"
                  :key="subMenu.id"
                  @click="subMenuClick(subMenu)">
                  <span 
                    @mouseover="showSub = { active: true, subId: subMenu.id, subMenu }"
                    @mouseleave="showSub = { active: false, subId: undefined }"
                    :class="[{'active': selectedMenu.subMenu.id === subMenu.id && !showSub.subId}, {'active': subMenu.id === showSub.subId}]" @click="activeSubMenuClick(subMenu)"
                    style="cursor: pointer;">
                    {{subMenu.name}}
                  </span>
                </li>
              </ul>
            </transition-group>
          </div>
        </li>
      </ul>
    </nav>
    <div class="search-form-container" v-if="viewSearchForm">
      <search-form></search-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import SearchForm from '../search/SearchForm'
// 대메뉴 마우스 오버시 서브메뉴가 아래로 내려오고 서브메뉴 클릭시 다시 숨겨지도록 하고, 모든 화면에서 서브메뉴 높이만큼 여백을 확보
export default {
  name: 'ImsrMenu',
  components: {
    SearchForm
  },
  computed: {
    ...mapGetters('menu', [
      'selectedMenu'
    ]),
    ...mapGetters('common', [
      'menuList',
      'viewSearchForm'
    ]),
    ...mapGetters('auth', [
      'isLoggedIn'
    ])
  },
  methods: {
    ...mapMutations('auth', [
      'setSignInDialog',
    ]),
    ...mapMutations('common', [
      'setViewSearchForm'
    ]),
    getExtentPath (menu, subMenu) {
      let extentPath = '';
      if (menu.name === 'WASHING' || subMenu.name === 'LIBRARY') {
        extentPath = `/${subMenu.name.split(' ').join('_').toLowerCase()}`;
      } else if (subMenu.type === 'STYLE' && subMenu.name !== 'TREND') {
        extentPath = '/best/items';
      }
      return extentPath;
    },
    subMenuClick (subMenu) {
      this.showMenu.active = false
      this.showSub = false
      if (this.selectedMenu.subMenu.id != subMenu.id) {
        const newMenu = this.selectedMenu.menu || this.showMenu.menu
        const extentPath = this.getExtentPath(newMenu, subMenu);
        this.$router.push(`/${newMenu.type.toLowerCase()}/${newMenu.id}/${subMenu.id}${extentPath}`);
      }
    },
    activeSubMenuClick (subMenu) {
      this.showSub = false
      const newMenu = this.showMenu.menu
      const extentPath = this.getExtentPath(newMenu, subMenu);
      const currentPath = this.$router.history.current.path
      const activePath = `/${newMenu.type.toLowerCase()}/${newMenu.id}/${subMenu.id}${extentPath}`
      if (this.$router.history.current.fullPath.indexOf('3D/studio') === -1 && this.$router.history.current.fullPath.indexOf('3D/export') === -1) {
        if ( currentPath !== activePath) {
          this.$router.push(`/${newMenu.type.toLowerCase()}/${newMenu.id}/${subMenu.id}${extentPath}`);
        }
      }
      //  else if (this.$router.history.current.fullPath.indexOf(activePath) !== -1) {
      //   console.log('imsr menu vue')
      //   this.$confirm('Discard all edit and Leave this page?', '', {
      //     confirmButtonText: 'OK',
      //     cancelButtonText: 'CANCEL',
      //     confirmButtonClass: 'imsr-text-button',
      //     cancelButtonClass: 'imsr-text-button',
      //     showClose: false
      //   }).then(this.$router.push(`/${newMenu.type.toLowerCase()}/${newMenu.id}/${subMenu.id}${extentPath}`))
      //   .catch(() => {
      //     // next(false);
      //   });
      // }
    },
    menuClick (menu) {
      if (this.viewSearchForm) {
        this.setViewSearchForm(false);
      }
      if (this.isLoggedIn && this.selectedMenu.menu.id != menu.id) {
        const extentPath = this.getExtentPath(menu, menu.subMenu[0]);
        this.$router.push(`/${menu.type.toLowerCase()}/${menu.id}/${menu.subMenu[0].id}${extentPath}`);
      } else if (!this.isLoggedIn) {
        this.setSignInDialog(true);
      }
    },
    menuOver (menu) {
      if (this.isLoggedIn) {
        this.showMenu = { active: true, menuId: menu.id, menu }
      }
    }
  },
  data () {
    return {
      menuActive: false,
      showMenu: {
        active: false,
        menuId: undefined
      },
      showSub: {
        active: false,
        subId: undefined
      }
    }
  }
}
</script>

<style scoped>
.imsr-menu-container {
  display: flex;
  justify-content: center;
  margin: 5px 0 0 0;
}

.imsr-menu-container ul {
  padding: 0;
}

.imsr-menu-container ul,
.imsr-menu-container ul li {
  margin: 0;
}

.imsr-menu-container ul li {
  list-style: none;
}

.imsr-menu-group {
  display: flex;
  justify-content: center;
  background-color: #fff;
  z-index: 1;
}

.imsr-sub-menu-group {
  display: flex;
  justify-content: center;
  background-color: transparent;
  z-index: 999;
}

.imsr-menu {
  white-space: nowrap;
  position: relative;
}

.imsr-menu > span {
  height: 43px;
  display: flex;
  align-items: center;
  padding-left: 13px;
  padding-right: 13px;
  font-size: 12px;
}

.imsr-sub-menu-container {
  position: absolute;
  left: 0;
  top: 40px;
  transition: all .3s ease;
}

.imsr-sub-menu-container.v-enter, .imsr-sub-menu-container.v-leave {
    height: 0;
    opacity: 0;
}

.imsr-menu-group > .imsr-menu > span.active {
  color: #98C8CC;
  text-shadow: 0 0 .85px #98c8cc, 0 0 .85px #98c8cc;
}

.imsr-sub-menu-group span.active {
  font-weight: 900;
  color: #232323;
}

.search-form-container {
  position: absolute;
  left: 0;
  top: 98px;
  width: 100%;
  z-index: 10;
}

.imsr-sub-menu-group {
  position: absolute;
  top: 0;
}

.submenu-enter-active, .submenu-leave-active {
  transition: all .5s;
}

.submenu-leave-active {
  transition: all 0s;
}

.submenu-enter, .submenu-leave-to {
  top: -20px;
  opacity: 0;
}
</style>